import React, { useEffect, useState } from "react";

import { Center, Loader } from "@mantine/core";

import { getGMVForecast } from "components/metrics/Api";

import SpendForecast, {
  CurrentMonthForecastTimeSeriesItem,
  NextMonthForecastTimeSeriesItem,
} from "components/metrics/SpendForecast";

export default function GMVForecast({ campaignId }: { campaignId: string }) {
  const [loading, setLoading] = useState(false);
  const [currentMonthGmvForecastTimeSeries, setCurrentMonthGmvForecastTimeSeries] = useState<
    CurrentMonthForecastTimeSeriesItem[]
  >([]);
  const [nextMonthGmvForecastTimeSeries, setNextMonthGmvForecastTimeSeries] = useState<
    NextMonthForecastTimeSeriesItem[]
  >([]);
  const [adjustedBudgetState, setAdjustedBudgetState] = useState<number | null>(null);
  const [isOneTimeBudget, setIsOneTimeBudget] = useState<boolean>(null);

  useEffect(() => {
    setLoading(true);
    getGMVForecast({ campaignId })
      .then((response) => {
        const { success, currentMonthForecast, nextMonthForecast, adjustedBudget, oneTimeBudget } =
          response;

        if (success) {
          setCurrentMonthGmvForecastTimeSeries(JSON.parse(currentMonthForecast));
          setNextMonthGmvForecastTimeSeries(JSON.parse(nextMonthForecast));
          setAdjustedBudgetState(adjustedBudget);
          setIsOneTimeBudget(oneTimeBudget);
        }
      })
      .finally(() => setLoading(false));
  }, [campaignId]);

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <SpendForecast
      title="GMV Forecast"
      currentMonthForecast={currentMonthGmvForecastTimeSeries}
      nextMonthForecast={nextMonthGmvForecastTimeSeries}
      adjustedBudget={adjustedBudgetState}
      isOneTimeBudget={isOneTimeBudget}
    />
  );
}
