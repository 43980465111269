import { API_URL } from "configs/Configs";
import { createRequestWithFirebaseToken, handleResult } from "utils/ApiUtils";

const UPDATE_EMAIL_URL = `${API_URL}/api/campaigns/brand_update_creator_email/`;

export const brandUpdateEmail = async (
  opportunityId: string,
  email: string,
  abortController: AbortController,
): Promise<any> => {
  const request = await createRequestWithFirebaseToken({
    url: UPDATE_EMAIL_URL,
    method: "PUT",
    body: JSON.stringify({
      opportunityId,
      email,
    }),
  });

  const response = await handleResult(request, abortController);

  if (response) {
    return response;
  }
  return null;
};

export default null;
