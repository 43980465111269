import React from "react";

import { Stack, Spoiler, Text, Title } from "@mantine/core";

import { simpleHash } from "components/contracts/common/Common";

function NewlineText({ text }: { text: string }) {
  const textArray = text.split("\n");

  return (
    <>
      {textArray.map((line: string) => (
        <Text component="span" key={simpleHash(line)}>
          {line}
          <br />
        </Text>
      ))}
    </>
  );
}

export default function TitleAndCaption({
  title,
  caption,
  width,
}: {
  title: string;
  caption: string;
  width: number;
}) {
  if (!title && !caption) {
    return null;
  }

  return (
    <Stack gap="0.2rem" p="md" w={width}>
      {title && (
        <Title order={4} fw="500">
          {title}
        </Title>
      )}
      {caption && (
        <Spoiler
          showLabel={<Text size={title ? "sm" : "md"}>...more</Text>}
          hideLabel={<Text size={title ? "sm" : "md"}>Hide</Text>}>
          <Text size={title ? "sm" : "md"} c={title ? "dimmed" : ""}>
            <NewlineText text={caption} />
          </Text>
        </Spoiler>
      )}
    </Stack>
  );
}
