import React, { useEffect, useState } from "react";

import { Checkbox, Group, NumberInput, Radio, Stack, Text, Title } from "@mantine/core";

import {
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_SUB_TITLE_SIZE,
} from "components/contracts/common/Common";

import { SupportedPlatform } from "models/Common";

export default function UsageRightsSelector({
  deliverableId,
  platform,
  usageRightsDays,
  setUsageRightsDays,
}: {
  deliverableId: number;
  platform: SupportedPlatform;
  usageRightsDays: number | string;
  // Must accept both a numeric value and "perpetuity"
  setUsageRightsDays: (value: number | string) => void;
}) {
  // Remember if we have selected the custom radio button
  const [customUsageRightsSelected, setCustomUsageRightsSelected] = useState<boolean>(false);
  // The value under the custom radio button.
  const [customUsageRightsValue, setCustomUsageRightsValue] = useState<string | number>("");

  const SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS: {
    [value in SupportedPlatform]: Array<number | string>;
  } = {
    [SupportedPlatform.INSTAGRAM]: [7, 30, 60, 90, 180, 365, "perpetuity"],
    [SupportedPlatform.TIKTOK]: [7, 30, 60, 90, 365, "perpetuity"],
    [SupportedPlatform.YOUTUBE]: [30, 60, 90, 180, 365, "perpetuity"],
    // Not really used but added for completeness
    [SupportedPlatform.NONE]: [30, 60, 90, 180, 365, "perpetuity"],
  };

  const usageRightsDaysForPlatform = SUPPORTED_PLATFORMS_TO_USAGE_RIGHTS_DAYS[platform];

  const usageRightsButtons = usageRightsDaysForPlatform.map(
    (days) =>
      // Filter out perpetuity because it's added manually below
      days !== "perpetuity" && (
        <Radio
          key={`usageRights-${days}-${deliverableId}`}
          id={`${days.toString()}-${deliverableId}`}
          label={`${days} days`}
          value={days.toString()}
        />
      ),
  );

  useEffect(() => {
    // If we have selected custom, the updated value goes there even if it's one of the radio options.
    // If we have not selected custom and the updated value is not one of the radio options, we force custom.
    if (usageRightsDays === "perpetuity") {
      setCustomUsageRightsSelected(false);
    } else if (
      customUsageRightsSelected ||
      (usageRightsDays !== 0 && usageRightsDaysForPlatform.indexOf(usageRightsDays) < 0)
    ) {
      setCustomUsageRightsSelected(true);
      setCustomUsageRightsValue(usageRightsDays);
    } else {
      // Otherwise this will select a radio buttion.
      setCustomUsageRightsSelected(false);
    }
  }, [usageRightsDays]);

  return (
    <Stack>
      <Title td="underline" order={DEFAULT_SUB_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
        Usage Rights
      </Title>
      <Checkbox
        checked={usageRightsDays === "perpetuity" || Number(usageRightsDays) > 0}
        id={`usageRightsCheckbox-${deliverableId}`}
        label="Usage Rights"
        onChange={(event) => {
          setUsageRightsDays(event.currentTarget.checked ? usageRightsDaysForPlatform[0] : 0);
        }}
      />
      {(usageRightsDays === "perpetuity" || Number(usageRightsDays) > 0) && (
        <Radio.Group
          label="Length of Usage Rights"
          id="usageRightsLength"
          value={customUsageRightsSelected ? "custom" : usageRightsDays.toString()}
          onChange={(value) => {
            if (value === "custom") {
              setCustomUsageRightsSelected(true);
              const intVal = parseInt(customUsageRightsValue.toString(), 10);
              // It's possible to select custom, set a value, then go select a different radio,
              // and finally select the custom button again.
              if (Number.isInteger(intVal)) {
                setUsageRightsDays(intVal);
              }
            } else if (value === "perpetuity") {
              setCustomUsageRightsSelected(false);
              setUsageRightsDays(value);
            } else {
              setCustomUsageRightsSelected(false);
              const intVal = parseInt(value, 10);
              setUsageRightsDays(intVal);
            }
          }}>
          <Stack gap="xs" mt="xs">
            {usageRightsButtons}
            <Radio label="In perpetuity" value="perpetuity" />
            <Radio.Card value="custom" withBorder={false}>
              <Group wrap="nowrap" align="flex-start" gap="sm">
                <Radio.Indicator />
                <Stack gap={0}>
                  <Text inherit fz={14}>
                    Custom
                  </Text>
                  <NumberInput
                    placeholder="Number of days"
                    max={365 * 2} // Two years at maximum
                    min={1} // If you want zero, use the checkbox
                    value={customUsageRightsValue}
                    disabled={!customUsageRightsSelected}
                    onChange={(value) => {
                      setCustomUsageRightsValue(value);
                      const intVal = parseInt(value.toString(), 10);
                      if (Number.isInteger(intVal)) {
                        setUsageRightsDays(intVal);
                      }
                    }}
                  />
                </Stack>
              </Group>
            </Radio.Card>
          </Stack>
        </Radio.Group>
      )}
    </Stack>
  );
}
