import { RootState } from "reduxStore/store";
import { TaskCountType } from "components/contracts/tasks/models/Common";

export const selectPendingTaskCount = (state: RootState) => {
  return state.tasks?.counts?.[TaskCountType.PENDING] ?? 0;
};
export const selectCompletedTaskCount = (state: RootState) => {
  return state.tasks?.counts?.[TaskCountType.COMPLETED] ?? 0;
};

export default null;
