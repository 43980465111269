import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Paper, Tabs } from "@mantine/core";
import { IconTimeline, IconReceiptDollar, IconWallet } from "@tabler/icons-react";

import BudgetUtilizationDashboard from "components/metrics/BudgetUtilizationDashboard";
import GMVSummary from "components/metrics/GMVSummary";
import TotalGMVAndRevenueForecast from "components/metrics/TotalGMVAndRevenueForecast";

enum NavItem {
  FORECAST = "forecast",
  SPEND = "spend", 
  BUDGETS = "budgets"
}

export default function GMVDashboard() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || NavItem.FORECAST);

  useEffect(() => {
    setSearchParams({ tab: selectedTab });
  }, [selectedTab]);

  return (
    <Paper radius="md" p="xl">
      <Tabs defaultValue={NavItem.FORECAST} value={selectedTab} onChange={setSelectedTab}>
        <Tabs.List>
          <Tabs.Tab value={NavItem.FORECAST} leftSection={<IconTimeline size="1rem" />} size="xl">
            Forecast
          </Tabs.Tab>
          <Tabs.Tab value={NavItem.SPEND} leftSection={<IconReceiptDollar size="1rem" />} size="xl">
            Spend
          </Tabs.Tab>
          <Tabs.Tab value={NavItem.BUDGETS} leftSection={<IconWallet size="1rem" />} size="xl">
            Budgets
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={NavItem.FORECAST} p="md">
          <TotalGMVAndRevenueForecast />
        </Tabs.Panel>

        <Tabs.Panel value={NavItem.SPEND} p="md">
          <GMVSummary />
        </Tabs.Panel>

        <Tabs.Panel value={NavItem.BUDGETS} p="md">
          <BudgetUtilizationDashboard />
        </Tabs.Panel>
      </Tabs>
    </Paper>
  );
}
