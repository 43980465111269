import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  FileInput,
  Group,
  Flex,
  Modal,
  RingProgress,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";

import { useDisclosure, useWindowEvent } from "@mantine/hooks";

import { IconCheck, IconEdit, IconPlayerPlay, IconVideo } from "@tabler/icons-react";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import ReactPlayer from "react-player";
import VideoUploader from "components/contracts/deliverables/VideoUploader";
import { ContractType } from "components/contracts/common/Common";

function UploadProgress({ progress }: { progress: number }) {
  if (progress === 100) {
    return null;
  }

  return (
    <Tooltip disabled={progress === 0} label={`Uploading ${progress}%...`}>
      <RingProgress
        size={28}
        thickness={4}
        roundCaps
        sections={[{ value: progress, color: "blue" }]}
      />
    </Tooltip>
  );
}

function UploadIcon({
  initializingUpload,
  progress,
}: {
  initializingUpload: boolean;
  progress: number;
}) {
  if (progress === 100) {
    return <IconCheck size="1rem" />;
  } else if (progress === 0 && !initializingUpload) {
    return <IconVideo size="1.2rem" />;
  }

  return <UploadProgress progress={progress} />;
}

function UploadButton({
  videoUrl,
  uploading,
  disabled,
  handleUpload,
  handleCancelUpload,
}: {
  videoUrl: string;
  uploading: boolean;
  disabled: boolean;
  handleUpload: () => void;
  handleCancelUpload: () => void;
}) {
  const [opened, { open, close }] = useDisclosure();

  return (
    <Box>
      <Modal padding={0} opened={opened} onClose={close} size="auto" withCloseButton={false}>
        <ReactPlayer url={videoUrl} playing muted controls />
        <Divider />
        <Group justify="right" p="sm">
          <Button variant="default" onClick={close}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleUpload();
              close();
            }}>
            Submit
          </Button>
        </Group>
      </Modal>
      <Button
        size="xs"
        variant="light"
        w={80}
        disabled={disabled}
        onClick={() => {
          if (uploading) {
            handleCancelUpload();
          } else {
            open();
          }
        }}
        color={uploading ? "red" : "blue"}>
        {uploading ? "Cancel" : "Upload"}
      </Button>
    </Box>
  );
}

function PlayButton({ videoUrl }: { videoUrl: string }) {
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Modal padding={0} opened={opened} onClose={close} size="auto" withCloseButton={false}>
        <ReactPlayer url={videoUrl} playing muted controls />
      </Modal>
      <Tooltip label="Play Video">
        <ActionIcon variant="light" onClick={open}>
          <IconPlayerPlay size="1rem" />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default function AssetSubmission({
  contractType,
  contractId,
  deliverableId,
  requiredVideoReview,
  requiresInput,
  isEditable,
  reuseFinalDraftAsAsset,
  setReuseFinalDraftAsAsset,
  submittedAssetUrl,
  submittedAssetFileName,
  assetSubmitted,
  setAssetSubmitted,
  setSubmissionSucceeded,
  disabled,
}: {
  contractType: ContractType;
  contractId: string;
  deliverableId: string;
  requiredVideoReview: boolean;
  requiresInput: boolean;
  isEditable: boolean;
  reuseFinalDraftAsAsset: boolean;
  setReuseFinalDraftAsAsset: (reuse: boolean) => void;
  submittedAssetUrl: string;
  submittedAssetFileName: string;
  assetSubmitted: boolean;
  setAssetSubmitted: (submitted: boolean) => void;
  setSubmissionSucceeded: (successful: boolean) => void;
  disabled?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);

  const [file, setFile] = useState<File>(null);
  const [fileName, setFileName] = useState<string>(submittedAssetFileName);
  const [localVideoUrl, setLocalVideoUrl] = useState<string>(submittedAssetUrl);

  const [uploadSuccessful, setUploadSuccessful] = useState<boolean>(false);
  const [initializingUpload, setInitializingUpload] = useState<boolean>(false);
  const [failedUpload, setFailedUpload] = useState<boolean>(false);

  // Progress Bar State
  const [progress, setProgress] = useState<number>(0);

  // Warn user before leaving page if video is still uploading.
  const warnUser = (event: BeforeUnloadEvent) => {
    if (initializingUpload || (progress > 0 && progress < 100)) {
      const message = "Your file is still uploading. Are you sure you want to leave?";

      // eslint-disable-next-line no-param-reassign
      event.returnValue = message;

      return message;
    }
    return undefined;
  };
  useWindowEvent("beforeunload", warnUser);

  const resetProgress = () => {
    setProgress(0);
  };

  const setInitializeUploadState = () => {
    resetProgress();
    setInitializingUpload(true);
    setFailedUpload(false);
  };

  const handleUploadSuccess = (location: string) => {
    setInitializingUpload(false);
    setUploadSuccessful(true);
    setLocalVideoUrl(location);
    setAssetSubmitted(true);
    setSubmissionSucceeded(true);
    showSuccessNotification({
      message: "Your video asset has been uploaded successfully.",
      stayOpen: true,
    });
  };

  const handleUploadFailure = () => {
    resetProgress();

    setFile(null);
    setLocalVideoUrl(null);

    setInitializingUpload(false);
    setFailedUpload(true);

    showFailureNotification({
      message: "There was an error uploading your video asset. Please try again.",
      stayOpen: true,
    });
  };

  const [uploaderInstance] = useState(() =>
    VideoUploader.build({
      contractType,
      contractId,
      deliverableId,
      setProgress,
      handleUploadSuccess,
      handleUploadFailure,
    }),
  );

  const handleUpload = () => {
    setInitializeUploadState();
    uploaderInstance.addFile({
      name: file.name,
      deliverableId,
      type: file.type,
      data: file,
    });
    uploaderInstance.upload();
  };

  const handleCancelUpload = () => {
    uploaderInstance.cancel();
  };

  useEffect(
    () => () => {
      if (uploaderInstance) {
        uploaderInstance.close();
      }
    },
    [],
  );

  return (
    <Stack gap="xs">
      <Text size="sm" fw="500" mb={-4}>
        Submit Final Video
      </Text>
      {requiredVideoReview && (
        <Checkbox
          key={`reuse-final-draft-${deliverableId}`}
          checked={reuseFinalDraftAsAsset}
          onChange={(event) => {
            setReuseFinalDraftAsAsset(event.currentTarget.checked);
            setFileName(null);
            setLocalVideoUrl(null);
            setFile(null);
          }}
          label="I have not made any changes to my approved video before going live."
          disabled={
            disabled ||
            (assetSubmitted && !isEditing) ||
            uploadSuccessful ||
            !requiresInput ||
            initializingUpload ||
            progress > 0
          }
        />
      )}
      <Flex align="flex-start" gap="xs" w="100%">
        {(assetSubmitted && !isEditing) || uploadSuccessful ? (
          <TextInput leftSection={<IconCheck size="1rem" />} value={fileName} w="70%" disabled />
        ) : (
          <FileInput
            key={`video-file-input-${deliverableId}`}
            placeholder="Select a video..."
            accept="video/*"
            leftSection={<UploadIcon initializingUpload={initializingUpload} progress={progress} />}
            value={file}
            onChange={(payload) => {
              setLocalVideoUrl(payload ? URL.createObjectURL(payload) : null);
              setFile(payload);
              setFileName(payload.name);
            }}
            clearable={progress === 0 && !initializingUpload}
            disabled={disabled || reuseFinalDraftAsAsset}
            required
            w="70%"
          />
        )}
        <Flex align="center" gap="xs" mt={3}>
          {((assetSubmitted && !isEditing) || uploadSuccessful || progress > 0) && (
            <PlayButton key={`play-button-${deliverableId}`} videoUrl={localVideoUrl} />
          )}
          {(!assetSubmitted || isEditing) && !uploadSuccessful && (
            <UploadButton
              key={`upload-button-${deliverableId}`}
              videoUrl={localVideoUrl}
              uploading={initializingUpload || (!failedUpload && progress > 0 && progress < 100)}
              disabled={disabled || !file || uploadSuccessful || reuseFinalDraftAsAsset}
              handleUpload={handleUpload}
              handleCancelUpload={handleCancelUpload}
            />
          )}
          {isEditable && !isEditing && (
            <Button
              size="xs"
              variant="light"
              leftSection={<IconEdit size="1rem" />}
              onClick={() => {
                setIsEditing(true);
              }}>
              Edit
            </Button>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
}
