import React from "react";

import { Badge } from "@mantine/core";

import { TaskStage, TaskStatus } from "components/contracts/tasks/models/Common";

export default function ActionBadge({
  taskType,
  waitingForCreator,
  referralLinkStatus,
  promoCodeStatus,
}: {
  taskType: TaskStage;
  referralLinkStatus?: TaskStatus;
  promoCodeStatus?: TaskStatus;
  waitingForCreator?: boolean;
}) {
  if (waitingForCreator) {
    return (
      <Badge variant="light" color="yellow">
        Waiting for Creator
      </Badge>
    );
  }

  if (taskType === TaskStage.LIVE_VERIFICATION) {
    return (
      <Badge variant="light" color="red">
        Verification Required
      </Badge>
    );
  } else if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <Badge variant="light" color="red">
        Action Required
      </Badge>
    );
  } else if (
    taskType === TaskStage.CONTENT_PRODUCTION ||
    taskType === TaskStage.MESSAGES
  ) {
    return (
      <Badge variant="light" color="red">
        Review Required
      </Badge>
    );
  } else if (taskType === TaskStage.CODES_LINKS) {
    if (referralLinkStatus !== TaskStatus.NONE && promoCodeStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Link and Promo Code Required
        </Badge>
      );
    } else if (referralLinkStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Link Required
        </Badge>
      );
    } else if (promoCodeStatus !== TaskStatus.NONE) {
      return (
        <Badge variant="light" color="red">
          Promo Code Required
        </Badge>
      );
    }
  }
  return null;
}
