import React, { useState } from "react";

import { Button, Group, Stack } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";

import { completeLiveContentSubmission } from "components/contracts/common/Api";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import {
  ContractDeliverableStatus,
  ContractDeliverableStatusMapping,
} from "components/contracts/models/Deliverable";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import AdCodeSubmission from "components/contracts/deliverables/live_content/AdCodeSubmission";
import AssetSubmission from "components/contracts/deliverables/live_content/AssetSubmission";
import DisputeReasonAlert from "components/contracts/deliverables/live_content/DisputeReasonAlert";
import LiveContentSubmissionChecklist from "components/contracts/deliverables/live_content/LiveContentSubmissionChecklist";
import LiveLinkSubmission from "components/contracts/deliverables/live_content/LiveLinkSubmission";
import LiveDateOverdueAlert from "components/contracts/deliverables/live_content/LiveDateOverdueAlert";
import { ContractType } from "components/contracts/common/Common";

export default function LiveContentSubmission({
  id,
  contractType,
  contractId,
  platform,
  format,
  deliverableStatus,
  setDeliverableStatus,
  brandName,
  creatorHandle,
  liveContentUrl,
  adCode,
  assetUrl,
  assetName,
  reuseFinalDraft,
  liveDate,
  setLiveContentReviewDeadline,
  liveContentDisputeReason,
  requiredVideoReview,
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  referralLinkUrl,
  promoCode,
  usageRightsDays,
  usageRightsInPerpetuity,
  creativeBriefUrl,
  unsubmittedLiveContent,
}: {
  id: string;
  contractType: ContractType;
  contractId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  deliverableStatus: ContractDeliverableStatus;
  setDeliverableStatus: (deliverableStatus: ContractDeliverableStatus) => void;
  brandName: string;
  creatorHandle: string;
  liveContentUrl: string;
  adCode: string;
  assetUrl: string;
  assetName: string;
  reuseFinalDraft: boolean;
  liveDate: Date;
  setLiveContentReviewDeadline: (date: Date) => void;
  liveContentDisputeReason: string;
  requiredVideoReview: boolean;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  referralLinkUrl: string;
  promoCode: string;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
  creativeBriefUrl: string;
  unsubmittedLiveContent: boolean;
}) {
  if (deliverableStatus === ContractDeliverableStatus.NOT_STARTED) {
    return null;
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [liveLink, setLiveLink] = useState(liveContentUrl);
  const [adCodeState, setAdCodeState] = useState(adCode);
  const [liveLinkSubmitted, setLiveLinkSubmitted] = useState(
    liveContentUrl && liveContentUrl.length > 0,
  );
  const [adCodeSubmitted, setAdCodeSubmitted] = useState(adCode && adCode.length > 0);
  const [assetSubmitted, setAssetSubmitted] = useState(assetUrl && assetUrl.length > 0);
  const [reuseFinalDraftAsAsset, setReuseFinalDraftAsAsset] = useState(reuseFinalDraft);
  const [submissionSucceeded, setSubmissionSucceeded] = useState<boolean>(false);

  const allDisabled =
    ContractDeliverableStatusMapping[deliverableStatus] <
    ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT];

  const hasUsageRights = usageRightsDays > 0 || usageRightsInPerpetuity;
  const requiresAdCode = hasUsageRights && platform !== SupportedPlatform.YOUTUBE;
  const requiresAsset = hasUsageRights;

  const requiresLiveLinkInput =
    deliverableStatus === ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT && !liveLinkSubmitted;
  const requiresAdCodeInput =
    deliverableStatus === ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT &&
    requiresAdCode &&
    !adCodeSubmitted;

  const textFieldIsEditable =
    ContractDeliverableStatusMapping[deliverableStatus] >=
      ContractDeliverableStatusMapping[ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT] &&
    ContractDeliverableStatusMapping[deliverableStatus] <
      ContractDeliverableStatusMapping[ContractDeliverableStatus.LIVE_CONTENT_APPROVED];

  const deliverableRequiresSubmission =
    deliverableStatus === ContractDeliverableStatus.WAITING_FOR_LIVE_CONTENT ||
    deliverableStatus === ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED;

  const handleSubmission = () => {
    setIsSubmitting(true);
    completeLiveContentSubmission({ deliverableId: id, reuseFinalDraftAsAsset })
      .then((response) => {
        const { success, status, reviewDeadline, error } = response;

        if (success) {
          setDeliverableStatus(status as ContractDeliverableStatus);
          setLiveContentReviewDeadline(reviewDeadline);
          showSuccessNotification({ message: "Live content submitted successfully!" });
        } else {
          showFailureNotification({ message: error });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: error });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const allowRevision =
    submissionSucceeded || unsubmittedLiveContent || (reuseFinalDraftAsAsset && !reuseFinalDraft);

  return (
    <Stack gap="xs" mt="sm">
      <LiveDateOverdueAlert
        brandName={brandName}
        liveDate={liveDate}
        deliverableStatus={deliverableStatus}
      />
      <DisputeReasonAlert
        deliverableStatus={deliverableStatus}
        disputeReason={liveContentDisputeReason}
      />
      <LiveContentSubmissionChecklist
        deliverableStatus={deliverableStatus}
        requiredVideoReview={requiredVideoReview}
        brandName={brandName}
        format={format}
        requiresReferralLink={requiresReferralLink}
        requiresPromoCode={requiresPromoCode}
        missingReferralLink={missingReferralLink}
        missingPromoCode={missingPromoCode}
        referralLinkUrl={referralLinkUrl}
        promoCode={promoCode}
        creativeBriefUrl={creativeBriefUrl}
        disabled={liveLinkSubmitted || adCodeSubmitted || assetSubmitted}>
        <>
          <LiveLinkSubmission
            deliverableId={id}
            setDeliverableStatus={setDeliverableStatus}
            missingReferralLink={missingReferralLink}
            missingPromoCode={missingPromoCode}
            requiresInput={requiresLiveLinkInput}
            isEditable={textFieldIsEditable && liveLinkSubmitted}
            platform={platform}
            format={format}
            creatorHandle={creatorHandle}
            liveLink={liveLink}
            setLiveLink={setLiveLink}
            setLiveLinkSubmitted={setLiveLinkSubmitted}
            setLiveContentReviewDeadline={setLiveContentReviewDeadline}
            setSubmissionSucceeded={setSubmissionSucceeded}
            disabled={allDisabled}
          />
          {requiresAdCode && (
            <AdCodeSubmission
              platform={platform}
              deliverableId={id}
              usageRightsDays={usageRightsDays}
              requiresInput={requiresAdCodeInput}
              isEditable={textFieldIsEditable && adCodeSubmitted}
              adCodeState={adCodeState}
              setAdCodeState={setAdCodeState}
              setAdCodeSubmitted={setAdCodeSubmitted}
              setSubmissionSucceeded={setSubmissionSucceeded}
              disableEducation={allDisabled}
              disabled={allDisabled || !liveLinkSubmitted}
            />
          )}
          {requiresAsset && (
            <AssetSubmission
              contractType={contractType}
              contractId={contractId}
              deliverableId={id}
              requiredVideoReview={requiredVideoReview}
              requiresInput={deliverableRequiresSubmission}
              isEditable={deliverableRequiresSubmission && assetSubmitted}
              reuseFinalDraftAsAsset={reuseFinalDraftAsAsset}
              setReuseFinalDraftAsAsset={setReuseFinalDraftAsAsset}
              submittedAssetUrl={assetUrl}
              submittedAssetFileName={assetName}
              assetSubmitted={assetSubmitted}
              setAssetSubmitted={setAssetSubmitted}
              setSubmissionSucceeded={setSubmissionSucceeded}
              disabled={allDisabled || !liveLinkSubmitted || (!adCodeSubmitted && requiresAdCode)}
            />
          )}
          {!allDisabled && hasUsageRights && deliverableRequiresSubmission && (
            <Group justify="right" mt="sm" mr="sm">
              <Button
                loading={isSubmitting}
                disabled={
                  !liveLinkSubmitted ||
                  (!adCodeSubmitted && requiresAdCode) ||
                  (!assetSubmitted && !reuseFinalDraftAsAsset && requiresAsset) ||
                  (deliverableStatus ===
                    ContractDeliverableStatus.LIVE_CONTENT_REVISIONS_REQUESTED &&
                    !allowRevision)
                }
                leftSection={<IconUpload size="1rem" />}
                onClick={handleSubmission}>
                Submit Live Content
              </Button>
            </Group>
          )}
        </>
      </LiveContentSubmissionChecklist>
    </Stack>
  );
}
