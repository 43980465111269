import React, { useEffect, useState } from "react";

import { Container, Divider, LoadingOverlay, MultiSelect, Stack } from "@mantine/core";

import { fetchGMVData } from "components/contracts/common/Api";
import BudgetSummary from "components/contracts/dashboard/BudgetSummary";
import { GMVData } from "components/contracts/models/GMVData";
import { deserializeGMVData } from "components/contracts/dashboard/Utils";

export default function GMVSummary() {
  const [isLoading, setIsLoading] = useState(false);
  const [campaignIdToNameState, setCampaignIdToNameState] = useState<Record<string, string>>({});
  const [allGMVData, setAllGMVData] = useState<Record<string, GMVData>>({});
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);

  useEffect(() => {
    setIsLoading(true);
    fetchGMVData()
      .then((response) => {
        const { success, gmvData, campaignIdToName } = response;
        if (success) {
          const defaultSelectedCampaigns = Object.keys(gmvData).filter(
            (campaignId) => campaignId !== "1" && campaignId !== "35" && campaignId !== "36",
          );
          setSelectedCampaigns(defaultSelectedCampaigns);
          setCampaignIdToNameState(campaignIdToName);

          const deserializedGMVData = deserializeGMVData(gmvData);
          setAllGMVData(deserializedGMVData);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const selectorOptions = Object.entries(campaignIdToNameState).map(
    ([campaignId, campaignName]) => ({
      value: campaignId,
      label: campaignName,
    }),
  );

  const mergedGMVData = selectedCampaigns
    .map((campaignId) => allGMVData[campaignId])
    .reduce(
      (acc, campaignGMV) => ({
        pendingSignatureEntries: [
          ...acc.pendingSignatureEntries,
          ...campaignGMV.pendingSignatureEntries,
        ],
        inProgressEntries: [...acc.inProgressEntries, ...campaignGMV.inProgressEntries],
        inProgressOverdueEntries: [
          ...acc.inProgressOverdueEntries,
          ...campaignGMV.inProgressOverdueEntries,
        ],
        realizedTakeEntries: [...acc.realizedTakeEntries, ...campaignGMV.realizedTakeEntries],
        completeEntries: [...acc.completeEntries, ...campaignGMV.completeEntries],
        automaticPayments: [...acc.automaticPayments, ...campaignGMV.automaticPayments],
        manualPayments: [...acc.manualPayments, ...campaignGMV.manualPayments],
        offPlatformPayments: [...acc.offPlatformPayments, ...campaignGMV.offPlatformPayments],
      }),
      {
        pendingSignatureEntries: [],
        inProgressOverdueEntries: [],
        inProgressEntries: [],
        completeEntries: [],
        realizedTakeEntries: [],
        automaticPayments: [],
        manualPayments: [],
        offPlatformPayments: [],
      } as GMVData,
    );

  const allCampaignsBudgetSummary = (
    <BudgetSummary
      key="all"
      campaignName="All Campaign"
      gmvData={mergedGMVData}
      showBrandNameInPayment
    />
  );

  const campaignBudgetSummaries = selectedCampaigns.map((campaignId) => {
    const campaignName = campaignIdToNameState[campaignId];
    return (
      <Stack key={`stack-${campaignId}`}>
        <Divider key={`divider-${campaignId}`} />
        <BudgetSummary
          key={campaignId}
          campaignName={campaignName}
          gmvData={allGMVData[campaignId]}
        />
      </Stack>
    );
  });

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {!isLoading && (
        <Container fluid>
          <Stack>
            <MultiSelect
              label="Selected Campaigns"
              data={selectorOptions}
              value={selectedCampaigns}
              onChange={setSelectedCampaigns}
              clearable
              searchable
            />
            {allCampaignsBudgetSummary}
            {campaignBudgetSummaries}
          </Stack>
        </Container>
      )}
    </>
  );
}
