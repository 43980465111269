import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { getNumTasks } from "components/contracts/tasks/api/Api";
import { TaskCountType, TaskStage } from "components/contracts/tasks/models/Common";

export const fetchTaskCount = createAsyncThunk(
  "tasks/fetchCount",
  async ({ testMode }: { testMode?: boolean }) => {
    const response = await getNumTasks({ testMode });
    return response.data[TaskStage.ALL];
  },
);

interface TasksState {
  counts: {
    [key in TaskCountType]: number;
  };
  loading: boolean;
  error: null | string;
}

const tasksSlice = createSlice({
  name: "tasks",
  initialState: {
    counts: {
      [TaskCountType.ALL]: 0,
      [TaskCountType.PENDING]: 0,
      [TaskCountType.COMPLETED]: 0,
    } as TasksState["counts"],
    loading: false,
    error: null,
  } as TasksState,
  reducers: {
    resetGlobalTaskCount: (state) => {
      return {
        ...state,
        counts: {
          [TaskCountType.ALL]: 0,
          [TaskCountType.PENDING]: 0,
          [TaskCountType.COMPLETED]: 0,
        },
      };
    },
    updateGlobalTaskCount: (state, action: PayloadAction<TasksState["counts"]>) => {
      return {
        ...state,
        counts: action.payload,
      };
    },
    adjustGlobalTaskCount: (
      state,
      action: PayloadAction<{ pending: number; completed: number }>,
    ) => {
      return {
        ...state,
        counts: {
          ...state.counts,
          [TaskCountType.PENDING]: state.counts[TaskCountType.PENDING] + action.payload.pending,
          [TaskCountType.COMPLETED]:
            state.counts[TaskCountType.COMPLETED] + action.payload.completed,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskCount.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(fetchTaskCount.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
          counts: {
            ...state.counts,
            [TaskCountType.ALL]: action.payload[TaskCountType.ALL],
            [TaskCountType.PENDING]: action.payload[TaskCountType.PENDING],
            [TaskCountType.COMPLETED]: action.payload[TaskCountType.COMPLETED],
          },
        };
      })
      .addCase(fetchTaskCount.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error.message ?? null,
      }));
  },
});

export const { adjustGlobalTaskCount, resetGlobalTaskCount, updateGlobalTaskCount } =
  tasksSlice.actions;
export default tasksSlice.reducer;
