import React, { useEffect, Dispatch, Fragment } from "react";

import { ActionIcon, Box, Button, Flex, Title } from "@mantine/core";

import { IconCircleMinus, IconCirclePlus } from "@tabler/icons-react";

import {
  DEFAULT_TITLE_FONT_WEIGHT,
  DEFAULT_TITLE_SIZE,
  DeliverableListAction,
  DeliverableListActionType,
  MultiPlatformState,
} from "components/contracts/common/Common";

import Deliverable, { DeliverableConfigs } from "components/contracts/models/Deliverable";
import DeliverableInput from "components/contracts/contract/DeliverableInput";
import { SupportedFormat, SupportedFormatNameToInteger } from "models/Common";
import { encodeDeliverablesConfigs } from "components/contracts/deliverables/DeliverablesUrlUtils";
import { useSearchParams } from "react-router-dom";
import { addDays } from "utils/DateUtils";

export interface DeliverableListEntry {
  id: number;
  deliverable: Deliverable;
}

export interface DeliverableList {
  entries: DeliverableListEntry[];
  nextId: number;
}

export default function DeliverableListInput({
  multiPlatformState,
  deliverableList,
  editDeliverableList,
  addDeliverableWithValidation,
  isValidatingDeliverable,
  liveDateBuffer,
}: {
  multiPlatformState: MultiPlatformState;
  deliverableList: DeliverableList;
  editDeliverableList: Dispatch<DeliverableListAction>;
  addDeliverableWithValidation: () => void;
  isValidatingDeliverable: boolean;
  liveDateBuffer?: number;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  // TO DO: this just loops through all the deliverables again if its state changed
  // find better way to only update the diff (entry that was updated)
  useEffect(() => {
    if (deliverableList.entries) {
      const updateSearchParams = new URLSearchParams(searchParams.toString());
      const newDeliverableConfigs: DeliverableConfigs[] = [];
      deliverableList.entries.forEach((entry) => {
        const { format, timeline, usageRightsDays, usageRightsInPerpetuity } = entry.deliverable;
        const updateDeliverableConfigs = {
          format: SupportedFormatNameToInteger[format],
          include_script_review: timeline.requiresScriptReview,
          include_usage_rights: usageRightsDays > 0 || usageRightsInPerpetuity,
          usage_rights_duration: usageRightsDays,
          usage_rights_in_perpetuity: usageRightsInPerpetuity,
        } as DeliverableConfigs;
        newDeliverableConfigs.push(updateDeliverableConfigs);
      });

      const encodedUrl = encodeDeliverablesConfigs(newDeliverableConfigs);
      updateSearchParams.set("deliverables", encodedUrl);
      setSearchParams(updateSearchParams.toString());
    }
  }, [deliverableList]);

  const deliverables = deliverableList.entries.map(({ id, deliverable }, index) => (
    <Fragment key={id}>
      <Box my="sm">
        <Flex gap="sm" align="center">
          <ActionIcon
            key={`remove-deliverable-${id}`}
            color="gray"
            variant="subtle"
            onClick={() => {
              editDeliverableList({
                type: DeliverableListActionType.REMOVE_DELIVERABLE,
                payload: { id },
              });
            }}>
            <IconCircleMinus size="1.5rem" />
          </ActionIcon>
          <Box>
            <Title order={DEFAULT_TITLE_SIZE} fw={DEFAULT_TITLE_FONT_WEIGHT}>
              Deliverable #{index + 1}
            </Title>
            <Box mt="sm">
              <DeliverableInput
                multiPlatformState={multiPlatformState}
                deliverable={deliverable}
                deliverableId={id}
                editDeliverableList={editDeliverableList}
                liveDateBuffer={liveDateBuffer}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
    </Fragment>
  ));
  return (
    <>
      {deliverables}
      <Box mb="sm">
        <Button
          loading={isValidatingDeliverable}
          variant="light"
          onClick={addDeliverableWithValidation}
          leftSection={<IconCirclePlus size="1rem" />}>
          Add Deliverable
        </Button>
      </Box>
    </>
  );
}

// Contract types have different default usage rights days.
const defaultUsageRightsDaysForFormat = (format: SupportedFormat) => {
  // Return undefined or null if you do not want a default (i.e., default is no usage rights)
  return format === SupportedFormat.UGC ? "perpetuity" : undefined;
};

export const deliverableListReducer = (state: DeliverableList, action: DeliverableListAction) => {
  const updatedState = { ...state };

  if (action.type === DeliverableListActionType.ADD_DELIVERABLE) {
    return {
      ...state,
      entries: [
        ...state.entries,
        {
          id: state.nextId,
          deliverable: Deliverable.build(),
        },
      ],
      nextId: state.nextId + 1,
    };
  } else if (action.type === DeliverableListActionType.REMOVE_DELIVERABLE) {
    return {
      ...state,
      entries: state.entries.filter(({ id }) => id !== action.payload.id),
    };
  } else if (action.type === DeliverableListActionType.ADD_DELIVERABLE_WITH_DETAILS) {
    // need to make a blank Deliverable first then fill in details
    const blankDeliverable = Deliverable.build();
    blankDeliverable.format = action.payload.format;
    blankDeliverable.creatorHandle = action.payload.creatorHandle;
    blankDeliverable.profileLink = action.payload.profileLink;
    blankDeliverable.platform = action.payload.platform;
    blankDeliverable.timeline = blankDeliverable.timeline.adjustTimelineForPlatformAndFormat(
      action.payload.platform,
      action.payload.format,
      action.payload.liveDateBuffer,
    );
    blankDeliverable.timeline.format = action.payload.format;
    blankDeliverable.usageRightsDays = action.payload.usageRightsDays;
    blankDeliverable.usageRightsInPerpetuity = action.payload.usageRightsInPerpetuity;
    blankDeliverable.timeline.requiresScriptReview = action.payload.requiresScriptReview;
    blankDeliverable.timeline.requiresVideoReview = true;
    return {
      ...state,
      entries: [
        ...state.entries,
        {
          id: state.nextId,
          deliverable: blankDeliverable,
        },
      ],
      nextId: state.nextId + 1,
    };
  } else if (action.type === DeliverableListActionType.SET_INITIAL_FIELDS) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.format = action.payload.format;
    deliverable.creatorHandle = action.payload.creatorHandle;
    deliverable.profileLink = action.payload.profileLink;
    deliverable.platform = action.payload.platform;
    deliverable.timeline = deliverable.timeline.adjustTimelineForPlatformAndFormat(
      action.payload.platform,
      action.payload.format,
      action.payload.liveDateBuffer,
    );
    deliverable.timeline.format = action.payload.format;
    const defaultUsageRightsDays = defaultUsageRightsDaysForFormat(action.payload.format);
    if (defaultUsageRightsDays === "perpetuity") {
      deliverable.usageRightsInPerpetuity = true;
    } else if (typeof defaultUsageRightsDays === "number") {
      // NOTE: we only apply a default if we receive a number as default.
      deliverable.usageRightsDays = defaultUsageRightsDays;
      // Calculate the date
      deliverable.usageRightsExpirationDate = addDays(new Date(), defaultUsageRightsDays);
    }
  } else if (action.type === DeliverableListActionType.UPDATE_EDITABLE_LIVE_DATE) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.timeline = deliverable.timeline.updateEditableLiveDate(
      action.payload.editableLiveDate,
    );
  } else if (action.type === DeliverableListActionType.UPDATE_DATE) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.timeline = deliverable.timeline.updateDate({
      ...action.payload.dateUpdate,
      format: deliverable.format,
    });
  } else if (action.type === DeliverableListActionType.UPDATE_REQUIRES_SCRIPT_REVIEW) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.timeline = deliverable.timeline.updateRequiresScriptReview(
      action.payload.requiresScriptReview,
    );
  } else if (action.type === DeliverableListActionType.UPDATE_REQUIRES_VIDEO_REVIEW) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.timeline = deliverable.timeline.updateRequiresVideoReview(
      action.payload.requiresVideoReview,
    );
  } else if (action.type === DeliverableListActionType.UPDATE_USAGE_RIGHTS_DAYS) {
    const { deliverable } = updatedState.entries.find(({ id }) => id === action.payload.id);
    deliverable.usageRightsDays = action.payload.usageRightsDays;
    deliverable.usageRightsInPerpetuity = action.payload.usageRightsInPerpetuity;
  } else {
    throw new Error("Invalid deliverable list action type");
  }

  return updatedState;
};
