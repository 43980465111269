import React, { ReactNode, useEffect, useState } from "react";

import {
  Button,
  Divider,
  Drawer,
  Group,
  Modal,
  MultiSelect,
  NativeSelect,
  NumberInput,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import { User } from "firebase/auth";

import { ADGROUP_URL } from "campaigns/api/fetchCampaignSettings";
import { updateCampaignObjByHashId } from "campaigns/api/updateCampaignObj";
import { AdGroupStatusIcon } from "campaigns/main/campaignPage/ad_groups/AdGroupStatusIcon";
import { CampaignObjChangeConfirmation } from "campaigns/main/campaignPage/campaigns/CampaignObjChangeConfirmation";
import { CreatorSet } from "components/discovery/Datamodels";
import { Campaign, CampaignAdGroup, CampaignAdGroupStatus } from "models/Campaign";
import { CreativeBrief } from "models/CreativeBrief";

// NOTE: THIS IS THE ADMIN VERSION
export const AdGroupEditDrawer = ({
  opened,
  close,
  title,
  user,
  campaignHashId,
  campaign,
  adGroup,
  creatorSets,
  creativeBriefs,
  readOnly = false,
  position = "right",
}: {
  opened: boolean;
  close: () => void;
  title: ReactNode;
  user: User;
  campaignHashId: number;
  campaign: Campaign;
  adGroup: CampaignAdGroup;
  creatorSets: CreatorSet[];
  creativeBriefs: CreativeBrief[];
  readOnly?: boolean;
  position?: "left" | "right";
}) => {
  // eslint-disable-next-line @typescript-eslint/no-redeclare, @typescript-eslint/no-unused-vars
  const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);

  // eslint-disable-next-line prettier/prettier
  const initialObj = {
    name: adGroup.name,
    status: adGroup?.status.toString() || CampaignAdGroupStatus.Draft.toString(),
    landing_page: adGroup.landing_page ? adGroup.landing_page : "",
    brand_blurb: adGroup.brand_blurb,
    creator_fit_blurb: adGroup.creator_fit_blurb,
    brand_link: adGroup.brand_link,
    brand_display_name: adGroup.brand_display_name,
    creator_sets: adGroup?.creator_sets || [],
    brief_id: adGroup?.brief ? adGroup.brief.id.toString() : "",
    target_cpm: adGroup.target_cpm,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(initialObj);

  const form = useForm({
    initialValues: initialObj,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isBlurbInherited = (blurb: string) =>
    form.values.brand_blurb == null ||
    form.values.brand_blurb === "" ||
    form.values.brand_blurb === campaign.brand?.company_blurb;
  const [blurbIsInherited, setBlurbIsInherited] = useState(isBlurbInherited(adGroup.brand_blurb));

  useEffect(() => {
    setBlurbIsInherited(isBlurbInherited(form.values.brand_blurb));
  }, [form.values]);

  // Brand Link
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isBrandLinkInherited = (brandLink: string) =>
    form.values.brand_link == null ||
    form.values.brand_link === "" ||
    form.values.brand_link === campaign.brand?.company_website;
  const [brandLinkIsInherited, setBrandLinkIsInherited] = useState(
    isBrandLinkInherited(adGroup.brand_link),
  );

  useEffect(() => {
    setBrandLinkIsInherited(isBrandLinkInherited(form.values.brand_link));
  }, [form.values]);

  // Brand Display Name
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isBrandNameInherited = (brandName: string) =>
    form.values.brand_display_name == null ||
    form.values.brand_display_name === "" ||
    form.values.brand_display_name === campaign.brand?.display_name;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [brandNameIsInherited, setBrandNameIsInherited] = useState(
    isBrandNameInherited(adGroup.brand_display_name),
  );

  useEffect(() => {
    setBrandNameIsInherited(isBrandNameInherited(form.values.brand_display_name));
  }, [form.values]);

  const creatorSetIdToName = creatorSets.reduce((acc: { [key: number]: string }, creatorSet) => {
    acc[creatorSet.id] = creatorSet.name;
    return acc;
  }, {});

  const formatValue = (key: string, value: any) => {
    if (key === "status") {
      return CampaignAdGroupStatus[value];
    } else if (key === "creator_sets") {
      return value.map((id: number) => creatorSetIdToName[id]).join(", ");
    }
    return value;
  };

  return (
    <Drawer size="lg" opened={opened} onClose={close} title={title} position={position}>
      <Modal
        size="lg"
        opened={modalOpened}
        onClose={closeModal}
        title={<Text fw="600">AdGroup Info</Text>}>
        <Stack gap={24}>
          {/* eslint-disable-next-line react/jsx-no-undef */}
          <Text>Please confirm that the following fields were changed:</Text>
          <CampaignObjChangeConfirmation
            firebaseUser={user}
            form={form}
            campaignHashId={campaignHashId}
            customFormatValue={formatValue}
            endpoint={ADGROUP_URL}
            objId={adGroup.id}
            objType="ad group"
            formValues={form.values}
            initialValues={initialValues}
            onSubmit={updateCampaignObjByHashId}
            close={closeModal}
            // eslint-disable-next-line prettier/prettier
          />
        </Stack>
      </Modal>
      <Stack gap="md">
        <Divider />
        <Title order={5}>Basic Info</Title>
        <TextInput
          label="Ad Group Name"
          placeholder="Test adgroup name"
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("name")}
          disabled={readOnly}
        />
        <Divider />
        <Group>
          <Title order={5}>Execution</Title>{" "}
          <AdGroupStatusIcon campaign={campaign} adGroup={adGroup} />
        </Group>
        <NativeSelect
          label="Status"
          w={200}
          data={Object.keys(CampaignAdGroupStatus)
            .filter((k) => typeof CampaignAdGroupStatus[k as any] === "number")
            .map((key) => ({ label: key, value: CampaignAdGroupStatus[key as any] }))}
          {...form.getInputProps("status")}
          disabled={readOnly}
        />
        <NumberInput
          label="Target CPM"
          w={200}
          prefix="$"
          placeholder="20"
          {...form.getInputProps("target_cpm")}
          disabled={readOnly}
        />
        <Divider />
        <Title order={5}>Configurations</Title>
        <TextInput
          label="Brand Display Name"
          description={brandNameIsInherited ? "Inherited from Brand*" : "Ad-Group Override"}
          placeholder={campaign.brand.display_name || "Test Brand Name"}
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("brand_display_name")}
          disabled={readOnly}
        />
        <TextInput
          label="Brand Link"
          description={brandLinkIsInherited ? "Inherited from Brand*" : "Ad-Group Override"}
          placeholder={campaign.brand.company_website || "Test Brand Link"}
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("brand_link")}
          disabled={readOnly}
        />
        <Textarea
          autosize
          label="Brand Blurb"
          description={blurbIsInherited ? "Inherited from Brand blurb*" : "Ad-Group Override"}
          placeholder={campaign.brand.company_blurb || "Test brand blurb"}
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("brand_blurb")}
          disabled={readOnly}
        />
        <Textarea
          autosize
          label="Creator Fit Blurb"
          placeholder="Test brand blurb"
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("creator_fit_blurb")}
          disabled={readOnly}
        />
        <Select
          label="Creative Brief"
          data={creativeBriefs?.map((brief) => ({
            label: brief.name,
            value: brief.id.toString(),
          }))}
          clearable
          {...form.getInputProps("brief_id")}
        />
        <TextInput
          label="Landing Page"
          placeholder="Landing page"
          /* eslint-disable react/jsx-props-no-spreading */
          {...form.getInputProps("landing_page")}
          disabled={readOnly}
        />
        <MultiSelect
          label="Creator Sets"
          placeholder="Creator Sets"
          data={creatorSets.map((creatorSet: CreatorSet) => ({
            label: creatorSet.name,
            value: String(creatorSet.id),
          }))}
          onChange={(value) => {
            form.setFieldValue(
              "creator_sets",
              value.map((v: string) => parseInt(v, 10)),
            );
          }}
          value={form.values.creator_sets.map((id: number) => String(id))}
          disabled={readOnly}
          // eslint-disable-next-line react/jsx-no-comment-textnodes
        />
        <Button type="submit" onClick={() => openModal()} disabled={!form.isDirty()}>
          Save
        </Button>
      </Stack>
    </Drawer>
  );
};

export default AdGroupEditDrawer;
