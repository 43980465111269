import { UsageRightsRequestStatus } from "components/contracts/common/Common";
import LiveContentModel from "components/contracts/models/LiveContentModel";
import {
  DeliverableVideo,
  deserializeDeliverableVideo,
} from "components/contracts/tasks/models/Common";
import { SupportedPlatform } from "models/Common";

export interface UsageRightsRequestDetails {
  hashId: string;
  dateCreated: Date;
  brandName: string;
  // Only present for admin
  campaignId?: number;
  campaignName: string;
  contractHash: string;
  status: UsageRightsRequestStatus;
  maxBrandOfferAmount: number;
  usageRightsDays: number;
  usageRightsInPerpetuity?: boolean;
  // The newest approved live content associated with the given deliverable (if any)
  liveContent?: LiveContentModel;
  // If UGC, this will be the video for it
  // TODO(chris): actually implement this
  deliverableVideo?: DeliverableVideo;
  platform: SupportedPlatform;
  creatorHandle: string;

  // Details filled in once the contract is created
  adCodeRequired?: boolean;
  usageRightsDaysAssigned?: number;
  usageRightsInPerpetuityAssigned?: boolean;
  usageRightsEndDate?: Date;
  dueDate?: Date;
  reviewDate?: Date;
  disputeReason?: string;
  creatorLastUpdateTime?: Date;
  video?: {
    dateCreated: Date;
    videoHashId: string;
    videoLocation: string;
    transcodedLocation?: string;
  };
  instagramAdCode?: string;
  tiktokAdCode?: string;
}

export function deserializeUsageRightsRequest(data: any): UsageRightsRequestDetails {
  return {
    hashId: data.hashId,
    dateCreated: new Date(data.dateCreated),
    brandName: data.brandName,
    campaignId: data.campaignId,
    campaignName: data.campaignName,
    contractHash: data.contractHash,
    status: data.status,
    maxBrandOfferAmount: data.maxBrandOfferAmount,
    usageRightsDays: data.usageRightsDays,
    usageRightsInPerpetuity: data.usageRightsInPerpetuity,
    liveContent: data.liveContent,
    // Optional, so we can't guarantee it exists to be deserialized
    deliverableVideo: data.deliverableVideo
      ? deserializeDeliverableVideo(data.deliverableVideo)
      : null,
    platform: data.platform,
    creatorHandle: data.creatorHandle,
    adCodeRequired: data.adCodeRequired,
    usageRightsDaysAssigned: data.usageRightsDaysAssigned,
    usageRightsInPerpetuityAssigned: data.usageRightsInPerpetuityAssigned,
    usageRightsEndDate: data.usageRightsEndDate ? new Date(data.usageRightsEndDate) : undefined,
    dueDate: data.dueDate ? new Date(data.dueDate) : undefined,
    reviewDate: data.reviewDate ? new Date(data.reviewDate) : undefined,
    disputeReason: data.disputeReason,
    creatorLastUpdateTime: data.creatorLastUpdateTime
      ? new Date(data.creatorLastUpdateTime)
      : undefined,
    video: data.video
      ? {
          dateCreated: data.video.dateCreated ? new Date(data.video.dateCreated) : undefined,
          videoHashId: data.video.videoHashId,
          videoLocation: data.video.videoLocation,
          transcodedLocation: data.video.transcodedLocation,
        }
      : undefined,
    instagramAdCode: data.instagramAdCode,
    tiktokAdCode: data.tiktokAdCode,
  };
}
