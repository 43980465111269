import React from "react";
import { Button, Space, Stack, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications, Notifications } from "@mantine/notifications";
import { IconCheck, IconNotes } from "@tabler/icons-react";

import { deleteContractOffer } from "components/contracts/negotiations/api/ContractOfferApi";

const ButtonGroup = ({
  contractOfferId,
  validate,
  disableSave,
  disableWithOffer,
  withAcceptRecommendedPackage,
  recommendedPackageTriggersBrandReview,
  disableAccept,
  openDrawer,
  deleteButtonCallback,
  addPackage,
  savePackages,
  acceptRecommendedPackage,
  reportProblem,
  adminOnly = true,
}: {
  contractOfferId: number;
  validate: boolean;
  disableSave: boolean;
  disableWithOffer: boolean;
  withAcceptRecommendedPackage: boolean;
  recommendedPackageTriggersBrandReview?: boolean;
  disableAccept: boolean;
  openDrawer: () => void;
  deleteButtonCallback: (() => void) | null;
  addPackage: () => void;
  savePackages: () => void;
  acceptRecommendedPackage: null | (() => void);
  reportProblem: null | (() => void);
  adminOnly?: boolean;
}) => {
  let disableForOps = true;
  if (!adminOnly) disableForOps = false;

  const openConfirmDeleteModal = () =>
    modals.openConfirmModal({
      title: "Please confirm that you want to delete this offer.",
      children: (
        <Stack>
          <Text size="sm">Please confirm that you want to delete this offer.</Text>
        </Stack>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () =>
        deleteContractOffer(contractOfferId).then((resp) => {
          if (resp?.status === 204) {
            notifications.show({
              title: "Offer deleted successfully",
              message: "Offer deleted successfully",
            });
            if (deleteButtonCallback) {
              deleteButtonCallback();
            }
          } else {
            notifications.show({
              title: "Error deleting offer",
              message: "Error deleting offer",
              color: "red",
            });
          }
        }),
    });

  return (
    <>
      <Notifications />
      <Button variant="default" leftSection={<IconNotes size={18} />} onClick={openDrawer}>
        View Offer Notes
      </Button>
      <Space my={4} />
      {disableWithOffer && disableForOps ? (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button color="blue" data-disabled onClick={addPackage}>
            Add Deliverable Package
          </Button>
        </Tooltip>
      ) : (
        <Button color="blue" disabled={validate} onClick={addPackage}>
          Add Deliverable Package
        </Button>
      )}
      {withAcceptRecommendedPackage && (
        <>
          <Button color="green" disabled={disableAccept} onClick={acceptRecommendedPackage}>
            {recommendedPackageTriggersBrandReview
              ? "Send Recommended Package to Brand Review"
              : "Continue With Recommended Package"}
          </Button>
          <Button color="red" disabled={disableAccept} onClick={reportProblem}>
            Report a Problem
          </Button>
        </>
      )}
      {!withAcceptRecommendedPackage && disableWithOffer && disableForOps && (
        <Tooltip label="Cannot save any new packages since there is an active offer.">
          <Button
            data-disabled
            leftSection={<IconCheck size={18} />}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}>
            Save All Packages + Continue
          </Button>
        </Tooltip>
      )}
      {!withAcceptRecommendedPackage && (!disableWithOffer || !disableForOps) && (
        <Button
          leftSection={<IconCheck size={18} />}
          color="green"
          disabled={disableSave}
          onClick={savePackages}>
          Save All Packages + Continue
        </Button>
      )}
      <Button color="red" disabled={!contractOfferId} onClick={openConfirmDeleteModal}>
        Delete Offer
      </Button>
    </>
  );
};

export default ButtonGroup;
