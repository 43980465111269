import React from "react";

import { SimpleGrid, Stack, Text, Title } from "@mantine/core";
import { AreaChart } from "@mantine/charts";

export interface CurrentMonthForecastTimeSeriesItem {
  date: string;
  cumulative_completed: number;
  cumulative_overdue: number;
  cumulative_forecasted: number;
}

export interface NextMonthForecastTimeSeriesItem {
  date: string;
  cumulative_forecasted: number;
}

const currentDate = new Date().toLocaleDateString("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const currentMonthName = new Date().toLocaleDateString("en-US", {
  month: "long",
});

const nextMonthName = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleDateString(
  "en-US",
  {
    month: "long",
  },
);

const formatAmount = (value: number): string => {
  const amountInDollars = value / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency", 
    currency: "USD",
    minimumFractionDigits: 2
  }).format(amountInDollars);
};


export default function SpendForecast({
  title,
  currentMonthForecast,
  nextMonthForecast,
  adjustedBudget,
  isOneTimeBudget,
  showTotal,
}: {
  title: string;
  currentMonthForecast: CurrentMonthForecastTimeSeriesItem[];
  nextMonthForecast: NextMonthForecastTimeSeriesItem[];
  adjustedBudget?: number;
  isOneTimeBudget?: boolean;
  showTotal?: boolean;
}) {
  const getSumForMaxDate = (data: CurrentMonthForecastTimeSeriesItem[]): number => {
    if (data.length === 0) {
      return 0; // Return 0 or throw an error if the array is empty
    }

    // Find the maximum date
    const maxDate = data.reduce((max, item) => (item.date > max ? item.date : max), data[0].date);

    // Find the item with the maximum date
    const maxDateItem = data.find((item) => item.date === maxDate);

    if (!maxDateItem) {
      return 0; // This should never happen if the data is consistent
    }

    // Sum the three values
    const sum =
      maxDateItem.cumulative_completed +
      maxDateItem.cumulative_overdue +
      maxDateItem.cumulative_forecasted;

    return sum;
  };

  // Calculate the maximum y-axis value for the chart and round to nearest $1k
  const yMax =
    Math.ceil((Math.max(adjustedBudget, getSumForMaxDate(currentMonthForecast)) * 1.1) / 100000) *
    100000;

  return (
    <SimpleGrid cols={2} spacing="xl">
      <Stack>
        <Stack gap={2} py="sm" px="xl">
          <Title order={3} fw="500">
            {currentMonthName} - {title}
          </Title>
          {showTotal && currentMonthForecast && currentMonthForecast.length > 0 && (
            <Text size="sm" c="dimmed">
              <Text span fw="600">
                Total:
              </Text>{" "}
              {formatAmount(getSumForMaxDate(currentMonthForecast))}
            </Text>
          )}
        </Stack>
        <AreaChart
          h={300}
          data={currentMonthForecast}
          dataKey="date"
          type="stacked"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          series={[
            {
              name: "cumulative_completed",
              label: "Completed",
              color: "green",
            },
            {
              name: "cumulative_overdue",
              label: "Overdue",
              color: "red",
            },
            {
              name: "cumulative_forecasted",
              label: "Forecasted",
              color: "blue",
              strokeDasharray: "6 4",
            },
          ]}
          withGradient
          withDots={false}
          referenceLines={
            adjustedBudget
              ? [
                  { x: currentDate, label: "Today" },
                  {
                    y: Number(adjustedBudget),
                    label: ` ${Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(adjustedBudget / 100)} - ${
                      isOneTimeBudget ? "Remaining Lifetime Budget" : "Monthly Budget"
                    }`,
                    color: "red",
                  },
                ]
              : [{ x: currentDate, label: "Today" }]
          }
          yAxisProps={adjustedBudget && { domain: [0, yMax] }}
        />
      </Stack>
      <Stack>
        <Stack gap={2} py="sm" px="xl">
          <Title order={3} fw="500">
            {nextMonthName} - {title}
          </Title>
          {showTotal && nextMonthForecast && nextMonthForecast.length > 0 && (
            <Text size="sm" c="dimmed">
              <Text span fw="600">
                Total:
              </Text>{" "}
              {formatAmount(nextMonthForecast[nextMonthForecast.length - 1].cumulative_forecasted)}
            </Text>
          )}
        </Stack>
        <AreaChart
          h={300}
          data={nextMonthForecast}
          dataKey="date"
          valueFormatter={(value) =>
            (value / 100).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          }
          series={[
            {
              name: "cumulative_forecasted",
              label: "Forecasted",
              color: "blue",
              strokeDasharray: "6 4",
            },
          ]}
          withGradient
          withDots={false}
        />
      </Stack>
    </SimpleGrid>
  );
}
