import React, { useState } from "react";

import {
  Alert,
  Button,
  Card,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  List,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { IconCheck, IconInfoCircle, IconVideoPlus, IconX } from "@tabler/icons-react";

import {
  approveUsageRightsContent,
  requestUsageRightsRevision,
} from "components/contracts/common/Api";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { UsageRightsRequestStatus } from "components/contracts/common/Common";
import { SupportedPlatform } from "models/Common";
import { UsageRightsRequestDetails } from "components/contracts/models/UsageRightsRequestDetails";
import { AdCode, adCodeName } from "components/contracts/review/LiveContentVerification";
import { toLongDateString } from "utils/DateUtils";

import ReactPlayer from "react-player";

function DisputeButton({
  contractHash,
  usageRightsRequestId,
  creatorHandle,
  formatLabel,
  disputeReason,
  // Called on every single change to the reason (every character added/removed, etc).
  setDisputeReason,
  // Called when a dispute is successfully made on the backend.
  disputeCallback,
}: {
  contractHash: string;
  usageRightsRequestId: string;
  creatorHandle: string;
  formatLabel: string;
  disputeReason: string;
  setDisputeReason: (reason: string) => void;
  disputeCallback?: () => void;
}) {
  const [requestRevisionButtonLoading, setRequestRevisionButtonLoading] = useState(false);
  const [modalOpened, { open, close }] = useDisclosure(false);

  const handleRequestRevision = () => {
    setRequestRevisionButtonLoading(true);
    requestUsageRightsRevision(contractHash, usageRightsRequestId, disputeReason)
      .then((response) => {
        const { success, error } = response;

        if (success) {
          showSuccessNotification({
            message: `Requested a revision for ${creatorHandle}'s ${formatLabel}.`,
          });
          setRequestRevisionButtonLoading(false);
          if (disputeCallback) {
            disputeCallback();
          }
        } else {
          setRequestRevisionButtonLoading(false);
          showFailureNotification({ message: error });
        }
        close();
      })
      .catch(() => {
        setRequestRevisionButtonLoading(false);
        showFailureNotification({ message: "Failed to request a revision." });
      });
  };

  return (
    <>
      <Modal
        opened={modalOpened}
        size="lg"
        onClose={close}
        title={<Text fw="600">Describe the Issue to {creatorHandle}</Text>}>
        <Stack>
          <Textarea
            minRows={3}
            autosize
            value={disputeReason}
            onChange={(event) => setDisputeReason(event.currentTarget.value)}
          />
          <Flex gap="sm" justify="right" align="center">
            <Button variant="default" color="gray" onClick={close}>
              Cancel
            </Button>
            <Button loading={requestRevisionButtonLoading} onClick={handleRequestRevision}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Button
        leftSection={<IconX size="1rem" />}
        color="red"
        variant="light"
        size="xs"
        onClick={open}>
        Report Issue
      </Button>
    </>
  );
}

function AssetContent({
  platform,
  assetUrl,
  adCode,
  usageRightsEndDate,
  usageRightsInPerpetuity,
  status,
}: {
  platform: SupportedPlatform;
  assetUrl: string;
  adCode: string;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
  status: UsageRightsRequestStatus;
}) {
  const hasAdCode = platform !== SupportedPlatform.YOUTUBE && adCode;

  return (
    <Stack gap="xs">
      {status === UsageRightsRequestStatus.PENDING && (
        <>
          <Text>
            <Text span fw={700}>
              Please review the following and confirm they meet your requirements.
            </Text>{" "}
            You cannot undo this step; once you confirm the deliverables, payment is released to the
            creator.
          </Text>
          <List>
            <List.Item>
              <Text span fw={700}>
                Video File
              </Text>
              <Text>
                Confirm the video copy provided is high-resolution and matches the live video.
              </Text>
            </List.Item>
            <List.Item>
              <Text span fw={700}>
                {adCodeName(platform)}
              </Text>
              <Text>
                Confirm the partnership ad code works and has the correct expiration date.
              </Text>
            </List.Item>
          </List>
        </>
      )}
      {status === UsageRightsRequestStatus.REJECTED && (
        <Text>
          The usage rights request was
          <Text span fw={700}>
            DENIED
          </Text>
          by the creator.
        </Text>
      )}
      {status === UsageRightsRequestStatus.ACCEPTED && (
        <Text>
          The usage rights request is now complete. Please see below for the video
          {hasAdCode ? " and ad code." : "."}
        </Text>
      )}
      {status !== UsageRightsRequestStatus.REJECTED && (
        <Stack gap="xs">
          <Group justify="center">
            <Card shadow="xs" padding={0} radius="sm" withBorder>
              <ReactPlayer url={assetUrl} muted controls />
              {hasAdCode && (
                <Group justify="center" p="xs">
                  <AdCode platform={platform} adCode={adCode} />
                </Group>
              )}
              <Flex pl="xs" pb="xs" gap={2} align="center">
                <ThemeIcon size="sm" variant="subtle">
                  <IconInfoCircle size="1rem" />
                </ThemeIcon>
                {usageRightsInPerpetuity ? (
                  <Text size="xs">Usage Rights In Perpetuity</Text>
                ) : (
                  <Text size="xs">
                    Usage Rights Expire on{" "}
                    <Text span fw="500">
                      {toLongDateString(usageRightsEndDate)}
                    </Text>
                  </Text>
                )}
              </Flex>
            </Card>
          </Group>
        </Stack>
      )}
    </Stack>
  );
}

export const UsageRightsContentVerification = ({
  usageRightsRequest,
  usageRightsStatus,
  setUsageRightsStatus,
  disputeReason,
  setDisputeReason,
  creatorHandle,
  assetUrl,
  adCode,
  platform,
  usageRightsEndDate,
  usageRightsInPerpetuity,
  disputeCallback,
}: {
  usageRightsRequest: UsageRightsRequestDetails;
  usageRightsStatus: UsageRightsRequestStatus;
  setUsageRightsStatus: (status: UsageRightsRequestStatus) => void;
  disputeReason: string;
  setDisputeReason: (reason: string) => void;
  creatorHandle: string;
  assetUrl: string;
  adCode: string;
  platform: SupportedPlatform;
  usageRightsEndDate: Date;
  usageRightsInPerpetuity: boolean;
  disputeCallback?: () => void;
}) => {
  const needsVerification = usageRightsStatus === UsageRightsRequestStatus.PENDING;
  const formatLabel = "video";

  const [approveButtonLoading, setApproveButtonLoading] = useState(false);

  const handleApprove = () => {
    setApproveButtonLoading(true);
    approveUsageRightsContent(usageRightsRequest)
      .then((response) => {
        const { success, status, error } = response;

        if (success) {
          setUsageRightsStatus(status);
          showSuccessNotification({
            message: `Successfully approved ${creatorHandle}'s ${formatLabel}!`,
          });
          setApproveButtonLoading(false);
        } else {
          setApproveButtonLoading(false);
          showFailureNotification({ message: error });
        }
      })
      .catch(() => {
        setApproveButtonLoading(false);
        showFailureNotification({ message: "Failed to approve live content." });
      });
  };

  return (
    <>
      {needsVerification && (
        <Alert
          my="md"
          variant="outline"
          color="red"
          radius="sm"
          title="Content Submitted!"
          icon={<IconVideoPlus />}>
          <AssetContent
            platform={platform}
            assetUrl={assetUrl}
            adCode={adCode}
            usageRightsEndDate={usageRightsEndDate}
            usageRightsInPerpetuity={usageRightsInPerpetuity}
            status={usageRightsStatus}
          />
          <Group mt="sm" justify={/* hasDraftContent ? "space-between" : */ "right"}>
            <Group justify="right">
              <DisputeButton
                contractHash={usageRightsRequest.contractHash}
                usageRightsRequestId={usageRightsRequest.hashId}
                creatorHandle={creatorHandle}
                formatLabel={formatLabel}
                disputeReason={disputeReason}
                setDisputeReason={setDisputeReason}
                disputeCallback={disputeCallback}
              />
              <Button
                leftSection={<IconCheck size="1rem" />}
                color="green"
                variant="light"
                size="xs"
                loading={approveButtonLoading}
                onClick={handleApprove}>
                Confirm
              </Button>
            </Group>
          </Group>
        </Alert>
      )}
      {!needsVerification && (
        <AssetContent
          platform={platform}
          assetUrl={assetUrl}
          adCode={adCode}
          usageRightsEndDate={usageRightsEndDate}
          usageRightsInPerpetuity={usageRightsInPerpetuity}
          status={usageRightsStatus}
        />
      )}
    </>
  );
};

export default UsageRightsContentVerification;
