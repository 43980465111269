import Uppy, { UppyFile } from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";

import {
  getPresignedUploadUrl,
  initializeMultipartUpload,
  listMultipartUploadParts,
  createVideoPartUploadUrl,
  completeMultipartUpload,
  completeSinglePartUpload,
  abortMultipartUpload,
  completeUsageRightsVideoSinglePartUpload,
} from "components/contracts/common/Api";
import { ContractType } from "components/contracts/common/Common";

function shouldUseMultipart(file: UppyFile) {
  return file.size > 10 * 2 ** 20; // 10 MB
}

export default class VideoUploader {
  private uppy: Uppy;

  private handleUploadSuccess: (location: string) => void;

  private handleUploadFailure: () => void;

  private contractId: string;

  private contractType: ContractType;

  /**
   * Uploading videos for usage rights contracts do not require a valid deliverable id.
   */
  constructor({
    contractType,
    contractId,
    deliverableId,
    setProgress,
    handleUploadSuccess,
    handleUploadFailure,
  }: {
    contractType: ContractType;
    contractId: string;
    deliverableId: string;
    setProgress: (progress: number) => void;
    handleUploadSuccess: (location: string) => void;
    handleUploadFailure: () => void;
  }) {
    this.handleUploadSuccess = handleUploadSuccess;
    this.handleUploadFailure = handleUploadFailure;
    this.contractId = contractId;
    this.contractType = contractType;

    const uppyId = contractType === ContractType.USAGE_RIGHTS ? contractId : deliverableId;

    this.uppy = new Uppy({ id: uppyId }).use(AwsS3, {
      id: "AwsS3",
      shouldUseMultipart: (file) => shouldUseMultipart(file),
      getUploadParameters: async (file) => {
        const response = await getPresignedUploadUrl(
          contractType,
          contractId,
          deliverableId,
          file.meta.title as string,
          file.meta.caption as string,
          file.type,
          file.name,
        );

        const { success, uploadUrl, key, error } = response;

        if (success) {
          this.uppy.setFileMeta(file.id, { key });

          return {
            method: "PUT",
            url: uploadUrl,
            fields: {},
            headers: { "content-type": file.type },
          };
        }
        throw new Error(error);
      },
      async createMultipartUpload(file) {
        const response = await initializeMultipartUpload(
          contractType,
          contractId,
          deliverableId,
          file.meta.title as string,
          file.meta.caption as string,
          file.type,
          file.name,
          file.meta.integrationTimestampInSeconds as number,
        );
        const { success, uploadId, key, error } = response;

        if (success) {
          return { uploadId, key };
        }
        throw new Error(error);
      },
      async listParts(file, { uploadId, key }) {
        const response = await listMultipartUploadParts(key, uploadId);
        const { success, parts, error } = response;

        if (success) {
          return parts;
        }
        throw new Error(error);
      },
      async signPart(file, partData) {
        const response = await createVideoPartUploadUrl(partData.key, partData.partNumber);
        const { success, url, error } = response;

        if (success) {
          return { url };
        }
        throw new Error(error);
      },
      async abortMultipartUpload(file, { uploadId, key }) {
        abortMultipartUpload(key, uploadId);
      },
      async completeMultipartUpload(file, { uploadId, key, parts }) {
        const response = await completeMultipartUpload(
          contractType,
          contractId,
          key,
          uploadId,
          parts,
        );
        const { success, location, error } = response;

        if (success) {
          return { location };
        }

        throw new Error(error);
      },
    });

    this.uppy.on("progress", (progress) => {
      setProgress(progress);
    });
  }

  public addFile({
    name,
    deliverableId,
    type,
    data,
    title,
    caption,
    integrationTimestampInSeconds,
  }: {
    name: string;
    deliverableId: string;
    type: string;
    data: File;
    title?: string;
    caption?: string;
    integrationTimestampInSeconds?: number;
  }) {
    // Remove all files before adding a new file
    this.uppy.getFiles().forEach((existingFile) => this.uppy.removeFile(existingFile.id));

    // Add a new file
    this.uppy.addFile({
      name,
      type,
      data,
      meta: { title, caption, deliverableId, integrationTimestampInSeconds },
    });
  }

  public upload() {
    this.uppy
      .upload()
      .then((result) => {
        const { successful, failed } = result;

        if (failed.length > 0) {
          this.handleUploadFailure();
          return;
        }

        const successfulFile = successful[0];
        const usedMultipart = shouldUseMultipart(successfulFile);

        if (usedMultipart) {
          const location = successfulFile.uploadURL;
          this.handleUploadSuccess(location);
        } else {
          // Upload server-side state for single part uploads.
          let completeResponse;
          if (this.contractType === ContractType.USAGE_RIGHTS) {
            completeResponse = completeUsageRightsVideoSinglePartUpload(
              successfulFile.meta.key as string,
              this.contractId,
            );
          } else {
            completeResponse = completeSinglePartUpload(
              successfulFile.meta.key as string,
              successfulFile.meta.deliverableId as string,
              successfulFile.meta.title as string,
              successfulFile.meta.caption as string,
              successfulFile.meta.integrationTimestampInSeconds as number,
            );
          }
          completeResponse
            .then((response) => {
              const { success, location } = response;

              if (success) {
                this.handleUploadSuccess(location);
              } else {
                this.handleUploadFailure();
              }
            })
            .catch(() => {
              this.handleUploadFailure();
            });
        }
      })
      .catch(() => {
        this.handleUploadFailure();
      });
  }

  public close() {
    this.uppy.close();
  }

  public cancel() {
    this.uppy.cancelAll();
  }

  public static build({
    contractType,
    contractId,
    deliverableId,
    setProgress,
    handleUploadSuccess,
    handleUploadFailure,
  }: {
    contractType: ContractType;
    contractId: string;
    deliverableId: string;
    setProgress: (progress: number) => void;
    handleUploadSuccess: (location: string) => void;
    handleUploadFailure: () => void;
  }) {
    return new VideoUploader({
      contractType,
      contractId,
      deliverableId,
      setProgress,
      handleUploadSuccess,
      handleUploadFailure,
    });
  }
}
