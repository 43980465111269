import React, { useEffect } from "react";

import { Stack, Textarea, TextInput } from "@mantine/core";

import { SUPPORTED_FORMATS_TO_PLATFORMS } from "components/contracts/common/Common";

import { SupportedFormat, SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES } from "models/Common";

import { isValidTimestamp } from "utils/DateUtils";

const MIN_INPUT_FIELD_LENGTH = 10;
const MAX_TITLE_LENGTH = 100; // YouTube max title length
const MAX_CAPTION_LENGTH = 5000; // YouTube max description length (IG and TikTok are shorter)

export default function MetadataFields({
  format,
  title,
  setTitle,
  caption,
  setCaption,
  integrationTimestamp,
  setIntegrationTimestamp,
  setMetadataValid,
  disableMetadata,
  showErrors,
  forUsageRights,
}: {
  format: SupportedFormat;
  title: string;
  setTitle: (title: string) => void;
  caption: string;
  setCaption: (caption: string) => void;
  integrationTimestamp: string;
  setIntegrationTimestamp: (timestamp: string) => void;
  setMetadataValid: (valid: boolean) => void | null;
  disableMetadata: boolean;
  showErrors: boolean;
  forUsageRights?: boolean;
}) {
  const platformDisplayName =
    SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[SUPPORTED_FORMATS_TO_PLATFORMS[format]];

  let requiresTitle = false;
  let requiresCaption = false;
  let requiresIntegrationTimestamp = false;

  switch (!forUsageRights && format) {
    case SupportedFormat.INSTAGRAM_DEDICATED_REEL:
    case SupportedFormat.TIKTOK_DEDICATED_VIDEO:
    case SupportedFormat.YOUTUBE_SHORT:
      requiresTitle = false;
      requiresCaption = true;
      break;
    case SupportedFormat.YOUTUBE_DEDICATED_VIDEO:
      requiresTitle = true;
      requiresCaption = true;
      break;
    case SupportedFormat.YOUTUBE_30S_INTEGRATED_VIDEO:
    case SupportedFormat.YOUTUBE_60S_INTEGRATED_VIDEO:
    case SupportedFormat.YOUTUBE_90S_INTEGRATED_VIDEO:
      requiresTitle = true;
      requiresCaption = true;
      requiresIntegrationTimestamp = true;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (setMetadataValid) {
      // Only call setMetadataValid if it is non-null
      setMetadataValid(
        (!requiresTitle ||
          (title.length >= MIN_INPUT_FIELD_LENGTH && title.length < MAX_TITLE_LENGTH)) &&
          (!requiresCaption ||
            (caption.length >= MIN_INPUT_FIELD_LENGTH && caption.length < MAX_CAPTION_LENGTH)) &&
          (!requiresIntegrationTimestamp || isValidTimestamp(integrationTimestamp)),
      );
    }
  }, [title, caption, integrationTimestamp]);

  if (!requiresTitle && !requiresCaption && !requiresIntegrationTimestamp) {
    return null;
  }

  return (
    <Stack gap="xs">
      {requiresIntegrationTimestamp && (
        <TextInput
          label={`${platformDisplayName} Video Integration Start Time`}
          placeholder={`Enter the start time of your ${platformDisplayName} integration in the format mm:ss or hh:mm:ss here...`}
          size="sm"
          withAsterisk
          value={integrationTimestamp}
          disabled={disableMetadata}
          onChange={(event) => {
            setIntegrationTimestamp(event.currentTarget.value);
          }}
          error={
            (showErrors &&
              integrationTimestamp.length === 0 &&
              "Enter a valid integration start time.") ||
            (integrationTimestamp &&
              integrationTimestamp.length > 0 &&
              !isValidTimestamp(integrationTimestamp) &&
              "Enter a valid integration start time in the format hh:mm:ss or mm:ss.")
          }
        />
      )}
      {requiresTitle && (
        <TextInput
          label={`${platformDisplayName} Video Title`}
          placeholder={`Enter the title you plan to use for your ${platformDisplayName} video here...`}
          size="sm"
          withAsterisk
          value={title}
          disabled={disableMetadata}
          onChange={(event) => {
            setTitle(event.currentTarget.value);
          }}
          error={
            (showErrors && title.length === 0 && "Enter a video title.") ||
            (title.length > 0 && title.length < MIN_INPUT_FIELD_LENGTH && "Title is too short.") ||
            (title.length > MAX_TITLE_LENGTH &&
              `Title must not exceed ${MAX_TITLE_LENGTH} characters.`)
          }
        />
      )}
      {requiresCaption && (
        <Textarea
          label={`${platformDisplayName} Video ${requiresTitle ? "Description" : "Caption"}`}
          placeholder={`Enter the ${
            requiresTitle ? "description" : "caption"
          } you plan to use for your ${platformDisplayName} video here...`}
          size="sm"
          withAsterisk
          autosize
          minRows={3}
          value={caption}
          disabled={disableMetadata}
          onChange={(event) => {
            setCaption(event.currentTarget.value);
          }}
          error={
            (showErrors &&
              caption.length === 0 &&
              `Enter a video ${requiresTitle ? "description" : "caption"}.`) ||
            (caption.length > 0 &&
              caption.length < MIN_INPUT_FIELD_LENGTH &&
              `${requiresTitle ? "Description" : "Caption"} is too short.`) ||
            (caption.length > MAX_CAPTION_LENGTH &&
              `${
                requiresTitle ? "Description" : "Caption"
              } must not exceed ${MAX_CAPTION_LENGTH} characters.`)
          }
        />
      )}
    </Stack>
  );
}
