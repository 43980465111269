import React, { Dispatch, useEffect } from "react";

import { Stack } from "@mantine/core";

import {
  DeliverableListAction,
  DeliverableListActionType,
} from "components/contracts/common/Common";

import Deliverable from "components/contracts/models/Deliverable";
import UGCTimelineSelector from "components/contracts/contract/UGCTimelineSelector";
import UsageRightsSelector from "components/contracts/contract/UsageRightsSelector";

import { SupportedFormat, SupportedPlatform } from "models/Common";

/**
 * Simplified deliverable input that assumes UGC (so a specific platform and format).
 */
export default function UGCDeliverableInput({
  deliverable,
  deliverableId,
  editDeliverableList,
  liveDateBuffer,
}: {
  deliverable: Deliverable;
  deliverableId: number;
  editDeliverableList: Dispatch<DeliverableListAction>;
  liveDateBuffer?: number;
}) {
  useEffect(() => {
    editDeliverableList({
      type: DeliverableListActionType.SET_INITIAL_FIELDS,
      payload: {
        id: deliverableId,
        format: SupportedFormat.UGC,
        platform: SupportedPlatform.NONE,
        creatorHandle: null,
        profileLink: null,
        liveDateBuffer,
      },
    });
  }, [deliverable]);

  return (
    <Stack mb="md">
      <UGCTimelineSelector
        deliverableId={deliverableId}
        platform={SupportedPlatform.NONE}
        format={SupportedFormat.UGC}
        timeline={deliverable.timeline}
        editDeliverableList={editDeliverableList}
      />
      <UsageRightsSelector
        deliverableId={deliverableId}
        platform={SupportedPlatform.NONE}
        usageRightsDays={
          deliverable.usageRightsInPerpetuity ? "perpetuity" : deliverable.usageRightsDays
        }
        setUsageRightsDays={(value) =>
          editDeliverableList({
            type: DeliverableListActionType.UPDATE_USAGE_RIGHTS_DAYS,
            payload: {
              id: deliverableId,
              usageRightsDays: value === "perpetuity" ? 0 : Number(value),
              usageRightsInPerpetuity: value === "perpetuity",
            },
          })
        }
      />
    </Stack>
  );
}
