import React from "react";

import { ThemeIcon } from "@mantine/core";

import { IconCheck, IconNotes } from "@tabler/icons-react";

import { ContentStatus } from "components/contracts/common/Common";
import { Script } from "components/contracts/tasks/models/Common";

import DeliverableStep from "components/contracts/tasks/cards/DeliverableStep";
import ScriptContentDisplay from "components/contracts/tasks/content/ScriptDisplay";

function ScriptDraftStep({
  taskId,
  displayName,
  script,
  version,
  isCurrent,
  isLatestScript,
  waitingForCreator,
  missingReferralLink,
  missingPromoCode,
  handleCompleteTask,
  numUnreadMessages,
  messagesLastRead,
  clearUnreadMessages,
  showAdminOptions,
}: {
  taskId: string;
  displayName: string;
  script: Script;
  version: number;
  isCurrent: boolean;
  isLatestScript: boolean;
  waitingForCreator?: boolean;
  missingReferralLink?: boolean;
  missingPromoCode?: boolean;
  handleCompleteTask?: (requireCreatorAction?: boolean) => void;
  numUnreadMessages?: number;
  messagesLastRead?: Date;
  clearUnreadMessages?: () => void;
  showAdminOptions?: boolean;
}) {
  const isApproved = script.status === ContentStatus.APPROVED;

  let label = null;
  let icon = null;

  if (isApproved) {
    label = "Approved Concept";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem">
        <IconCheck size="0.9rem" />
      </ThemeIcon>
    );
  } else if (waitingForCreator && isLatestScript) {
    label = "Current Concept Draft";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="yellow">
        <IconNotes size="0.9rem" />
      </ThemeIcon>
    );
  } else if (isCurrent) {
    label = "Current Concept Draft";
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="red">
        <IconNotes size="0.9rem" />
      </ThemeIcon>
    );
  } else {
    label = `Concept Draft #${version}`;
    icon = (
      <ThemeIcon radius="xl" size="1.4rem" color="blue" variant="light">
        <IconNotes size="0.9rem" />
      </ThemeIcon>
    );
  }

  return (
    <DeliverableStep
      isOpen={(isCurrent && !waitingForCreator) || (numUnreadMessages && numUnreadMessages > 0)}
      label={label}
      submissionDate={script.dateCreated}
      icon={icon}
      content={
        <ScriptContentDisplay
          taskId={taskId}
          displayName={displayName}
          script={script}
          isLatestScript={isLatestScript}
          missingReferralLink={missingReferralLink}
          missingPromoCode={missingPromoCode}
          handleCompleteTask={handleCompleteTask}
          messagesLastRead={messagesLastRead}
          clearUnreadMessages={clearUnreadMessages}
          showAdminOptions={showAdminOptions}
        />
      }
    />
  );
}

export default function ScriptDraftStage({
  taskId,
  displayName,
  scripts,
  waitingForCreator,
  missingReferralLink,
  missingPromoCode,
  handleCompleteTask,
  messagesLastRead,
  clearUnreadMessages,
  showAdminOptions,
}: {
  taskId: string;
  displayName: string;
  scripts: Script[];
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  waitingForCreator?: boolean;
  handleCompleteTask: (requireCreatorAction?: boolean) => void;
  messagesLastRead?: Date;
  clearUnreadMessages: () => void;
  showAdminOptions?: boolean;
}) {
  if (!scripts || scripts.length === 0) {
    return null;
  }

  return (
    <>
      {[...scripts].reverse().map((script, index) => (
        <ScriptDraftStep
          key={`${script.scriptId}-${script.deliverableId}`}
          taskId={taskId}
          displayName={displayName}
          script={script}
          version={index + 1}
          isCurrent={script.status === ContentStatus.PENDING_REVIEW}
          isLatestScript={index === scripts.length - 1}
          missingReferralLink={missingReferralLink}
          missingPromoCode={missingPromoCode}
          waitingForCreator={waitingForCreator}
          numUnreadMessages={script.numUnreadMessages}
          handleCompleteTask={handleCompleteTask}
          messagesLastRead={messagesLastRead}
          clearUnreadMessages={clearUnreadMessages}
          showAdminOptions={showAdminOptions}
        />
      ))}
    </>
  );
}
