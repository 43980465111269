import React, { useState } from "react";

import { User } from "firebase/auth";

import { useDisclosure } from "@mantine/hooks";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import ContentSubmissionCardContent from "components/contracts/deliverables/ContentSubmissionCardContent";
import ContentSubmissionCardControls from "components/contracts/deliverables/ContentSubmissionCardControls";
import ContentSubmissionCardHeader from "components/contracts/deliverables/ContentSubmissionCardHeader";
import DeliverableTimeline from "components/contracts/contract/DeliverableTimeline";
import DeliverableVideo from "components/contracts/models/DeliverableVideo";
import { ContractType } from "components/contracts/common/Common";

export default function DeliverableCard({
  user,
  id,
  adGroupId,
  contractId,
  contractType,
  platform,
  format,
  status,
  brandName,
  creatorHandle,
  profileLink,
  liveContentUrl,
  adCode,
  assetUrl,
  assetName,
  reuseFinalDraft,
  unsubmittedLiveContent,
  timeline,
  liveContentReviewDeadline,
  liveContentDisputeReason,
  actionRequired,
  requiresReferralLink,
  requiresPromoCode,
  missingReferralLink,
  missingPromoCode,
  referralLinkUrl,
  promoCode,
  usageRightsDays,
  usageRightsInPerpetuity,
  creativeBriefUrl,
  numDeliverablesInContract,
  latestVideosForContract,
  setLatestVideosForContract,
  handleRefetchDeliverable,
  handleRefetchUploadedVideos,
  updateActionItem,
  showAdminOptions,
}: {
  user: User;
  id: string;
  adGroupId: number;
  contractId: string;
  contractType: ContractType;
  platform: SupportedPlatform;
  format: SupportedFormat;
  status: ContractDeliverableStatus;
  brandName: string;
  creatorHandle: string;
  profileLink: string;
  liveContentUrl: string;
  adCode: string;
  assetUrl: string;
  assetName: string;
  reuseFinalDraft: boolean;
  unsubmittedLiveContent: boolean;
  timeline: DeliverableTimeline;
  liveContentReviewDeadline: Date;
  liveContentDisputeReason: string;
  actionRequired: boolean;
  requiresReferralLink: boolean;
  requiresPromoCode: boolean;
  missingReferralLink: boolean;
  missingPromoCode: boolean;
  referralLinkUrl: string;
  promoCode: string;
  usageRightsDays: number;
  usageRightsInPerpetuity: boolean;
  creativeBriefUrl: string;
  numDeliverablesInContract: number;
  latestVideosForContract: DeliverableVideo[];
  setLatestVideosForContract: (videos: DeliverableVideo[]) => void;
  handleRefetchDeliverable: (deliverableId: string, handleFetched: () => void) => void;
  handleRefetchUploadedVideos: (handleFetched: () => void) => void;
  updateActionItem: (status: ContractDeliverableStatus) => void;
  showAdminOptions: boolean;
}) {
  const [deliverableStatus, setDeliverableStatus] = useState<ContractDeliverableStatus>(status);

  const wrappedSetDeliverableStatus = (newStatus: ContractDeliverableStatus) => {
    setDeliverableStatus(newStatus);
    updateActionItem(newStatus);
  };

  const isOpen =
    actionRequired ||
    status === ContractDeliverableStatus.NOT_STARTED ||
    numDeliverablesInContract === 1;
  const [opened, { toggle }] = useDisclosure(isOpen);

  return (
    <CollapsibleCard
      isOpen={isOpen}
      header={
        <ContentSubmissionCardHeader
          platform={platform}
          format={format}
          liveDate={format === SupportedFormat.UGC ? timeline.approvalDate : timeline.liveDate}
          deliverableStatus={deliverableStatus}
        />
      }
      controls={
        <ContentSubmissionCardControls
          user={user}
          deliverableId={id}
          format={format}
          timeline={timeline}
          deliverableStatus={deliverableStatus}
          requiresReferralLink={requiresReferralLink}
          missingReferralLink={missingReferralLink}
          handleRefetchDeliverable={handleRefetchDeliverable}
          showAdminOptions={showAdminOptions}
        />
      }
      content={
        <ContentSubmissionCardContent
          id={id}
          adGroupId={adGroupId}
          contractId={contractId}
          contractType={contractType}
          platform={platform}
          format={format}
          deliverableStatus={deliverableStatus}
          setDeliverableStatus={wrappedSetDeliverableStatus}
          brandName={brandName}
          creatorHandle={creatorHandle}
          profileLink={profileLink}
          liveContentUrl={liveContentUrl}
          adCode={adCode}
          assetUrl={assetUrl}
          assetName={assetName}
          reuseFinalDraft={reuseFinalDraft}
          unsubmittedLiveContent={unsubmittedLiveContent}
          scriptDate={timeline.requiresScriptReview && timeline.scriptDate}
          videoDraftDate={timeline.requiresVideoReview && timeline.videoDraftDate}
          liveDate={timeline.liveDate}
          analyticsDate={timeline.analyticsDate}
          liveContentReviewDeadline={liveContentReviewDeadline}
          liveContentDisputeReason={liveContentDisputeReason}
          requiresReferralLink={requiresReferralLink}
          requiresPromoCode={requiresPromoCode}
          missingReferralLink={missingReferralLink}
          missingPromoCode={missingPromoCode}
          referralLinkUrl={referralLinkUrl}
          promoCode={promoCode}
          usageRightsDays={usageRightsDays}
          usageRightsInPerpetuity={usageRightsInPerpetuity}
          creativeBriefUrl={creativeBriefUrl}
          latestVideosForContract={latestVideosForContract}
          setLatestVideosForContract={setLatestVideosForContract}
          handleRefetchUploadedVideos={handleRefetchUploadedVideos}
          showAdminOptions={showAdminOptions}
        />
      }
      disableHoverStyle
      customOpened={opened}
      customToggle={toggle}
    />
  );
}
