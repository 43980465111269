import React, { KeyboardEvent } from "react";
import { Box, Button, TextField } from "gestalt";
import "gestalt/dist/gestalt.css";
import { useAuthState } from "react-firebase-hooks/auth";

import Spacer from "components/Spacer";
import { auth } from "auth/firebaseAuthHelpers";
import { useUser } from "utils/UserContext";
import { API_URL } from "configs/Configs";
import { handleResult } from "utils/ApiUtils";
import { User } from "firebase/auth";
import { User as UserProfile } from "models/User";
import { isMobile } from "react-device-detect";

type QueryLog = {
  query: string;
  user?: string;
};

const logQuery = async (query: string, user: User, userProfile: UserProfile) => {
  const firebaseToken = await user?.getIdToken();
  const authorization = firebaseToken ? `Bearer ${firebaseToken}` : "";

  const loggedInfo: QueryLog = {
    query,
  };
  if (userProfile) loggedInfo.user = userProfile.key;

  const request = new Request(`${API_URL}/analytics/related_queries/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
    body: JSON.stringify(loggedInfo),
  });
  await handleResult(request);
};

const runSubmit = async (
  handleSubmit: () => void,
  query: string,
  user: User,
  userProfile: UserProfile,
) => {
  handleSubmit();
  logQuery(query, user, userProfile);
};

const RelatedSearch = ({
  handleSubmit,
  onChange,
  input,
  errorMessage,
}: {
  handleSubmit: () => void;
  onChange: (value: string) => void;
  input?: string;
  errorMessage?: string;
}) => {
  const [user] = useAuthState(auth);
  const [userProfile] = useUser();

  return (
    <Box direction="row" display="flex" justifyContent="center" wrap={isMobile}>
      <Box width="min(100%, 400px)">
        <TextField
          id="Profile Input"
          label="YouTube Profile Link"
          helperText="The URL of a YouTube Creator's profile page"
          placeholder="https://www.youtube.com/@humphrey"
          onChange={({ value }) => {
            onChange(value);
          }}
          value={input}
          errorMessage={errorMessage || ""}
          onKeyDown={({
            event,
            value,
          }: {
            event: KeyboardEvent<HTMLInputElement>;
            value: string;
          }) => {
            if (event.code === "Enter") {
              runSubmit(handleSubmit, value, user, userProfile);
            }
          }}
        />
      </Box>
      <Spacer width={12} height={8} />
      <Button
        text="Find look-alikes"
        color="blue"
        fullWidth={isMobile}
        onClick={() => {
          runSubmit(handleSubmit, input, user, userProfile);
        }}
      />
    </Box>
  );
};

export default RelatedSearch;
