import React, { useState } from "react";

import MessageModel, { MessageParty } from "components/contracts/models/Message";
import { getFormattedTimestamp } from "utils/DateUtils";

import {
  IconChecks,
  IconChevronDown,
  IconChevronUp,
  IconEdit,
  IconEye,
  IconInfoCircle,
  IconSend,
  IconSettings,
  IconTrash,
} from "@tabler/icons-react";

import {
  ActionIcon,
  Alert,
  Blockquote,
  Box,
  Button,
  Collapse,
  Flex,
  Group,
  Indicator,
  Menu,
  Modal,
  Skeleton,
  Stack,
  Space,
  Text,
  Textarea,
  Tooltip,
  rem,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { deleteMessage, editMessage, sendMessageAsPlatform } from "components/contracts/common/Api";
import ConfirmModal from "components/contracts/common/ConfirmModal";

const NUM_MESSAGES_TO_PREVIEW = 2;

function getMessageColor(messageViewer: MessageParty, senderType: MessageParty) {
  if (messageViewer === senderType) {
    return "blue";
  } else if (senderType === MessageParty.PLATFORM || senderType === MessageParty.AUTO) {
    return "orange";
  }

  return "gray";
}

function SkeletonMessage({ messagesLoaded }: { messagesLoaded: boolean }) {
  if (messagesLoaded) {
    return null;
  }

  return (
    <Stack gap="xs">
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} width="70%" radius="xl" />
    </Stack>
  );
}

function NewlineText({ date, text }: { date: Date; text: string }) {
  const textArray = text.split("\n");

  return (
    <>
      {textArray.map((line: string, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Text component="span" key={`${date}-${index}`}>
          {line}
          <br />
        </Text>
      ))}
    </>
  );
}

function Timestamp({ timestamp }: { timestamp: Date }) {
  return (
    <Text fs="italic" size="sm" span>
      {getFormattedTimestamp(timestamp)}
    </Text>
  );
}

function MessageEditor({
  message,
  handleDeleteMessage,
  handleEditMessage,
}: {
  message: MessageModel;
  handleDeleteMessage: (messageId: string, callbackFn: () => void) => void;
  handleEditMessage: (messageId: string, text: string, callbackFn: () => void) => void;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteOpened, { open: openDelete, close: closeDelete }] = useDisclosure(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);

  const [newMessageText, setNewMessageText] = useState(message.text);

  return (
    <>
      <ConfirmModal
        modalMessage={
          <Text>
            <Text mb="xs">Are you sure you want to delete the following message?</Text>
            <Text size="sm" fs="italic">
              {message.text}
            </Text>
          </Text>
        }
        onConfirm={() => {
          setIsDeleting(true);
          handleDeleteMessage(message.hashId, () => setIsDeleting(false));
        }}
        isProcessing={isDeleting}
        opened={deleteOpened}
        close={closeDelete}
      />
      <Modal
        opened={editOpened}
        onClose={closeEdit}
        size="xl"
        title={<Text fw="600">Edit Message</Text>}>
        <Stack>
          <Textarea
            label={
              <Text mb="xs">
                <Text fw="600" size="sm" span>
                  {message.senderDisplayName}{" "}
                </Text>
                - <Timestamp timestamp={message.dateCreated} />
              </Text>
            }
            minRows={3}
            autosize
            value={newMessageText}
            onChange={(event) => setNewMessageText(event.currentTarget.value)}
          />
          <Flex gap="sm" justify="right" align="center">
            <Button onClick={closeEdit} variant="default" color="gray">
              Cancel
            </Button>
            <Button
              loading={isEditing}
              onClick={() => {
                setIsEditing(true);
                handleEditMessage(message.hashId, newMessageText, () => {
                  setIsEditing(false);
                  closeEdit();
                });
              }}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Menu shadow="md">
        <Menu.Target>
          <ActionIcon variant="transparent" color="gray">
            <IconSettings size="1.1rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<IconEdit size="1.1rem" />} onClick={openEdit}>
            Edit Message
          </Menu.Item>
          <Menu.Item color="red" leftSection={<IconTrash size="1.1rem" />} onClick={openDelete}>
            Delete Message
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

export function Message({
  message,
  messagesLastRead,
  messageViewer,
  handleDeleteMessage,
  handleEditMessage,
  showAdminOptions,
}: {
  message: MessageModel;
  messagesLastRead?: Date;
  messageViewer: MessageParty;
  handleDeleteMessage: (messageId: string, callbackFn: () => void) => void;
  handleEditMessage: (messageId: string, text: string, callbackFn: () => void) => void;
  showAdminOptions?: boolean;
}) {
  let viewingPartyDisplayName = "";
  if (messageViewer === MessageParty.CREATOR) {
    viewingPartyDisplayName = "you";
  } else if (messageViewer === MessageParty.BRAND) {
    viewingPartyDisplayName = message.recipientDisplayName;
  } else {
    throw new Error(`Invalid message viewer: ${messageViewer}`);
  }

  const isUnread =
    message.isUnread ||
    (messagesLastRead &&
      message.dateCreated > messagesLastRead &&
      messageViewer !== message.senderType &&
      message.senderType !== MessageParty.AUTO);

  return (
    <Indicator disabled={!isUnread} withBorder color="red" size="0.8rem">
      <Blockquote
        p={rem(8)}
        pl="sm"
        color={getMessageColor(messageViewer, message.senderType)}
        radius="xs">
        {(message.senderType === MessageParty.PLATFORM ||
          message.senderType === MessageParty.AUTO) && (
          <Flex ml="-0.3rem" mb="0.1rem" gap="0.3rem" align="center">
            <IconEye size="0.9rem" />
            <Text size="xs" fw="500" fs="italic">
              Only visible to {viewingPartyDisplayName}
            </Text>
          </Flex>
        )}
        <Flex justify="space-between" align="center">
          <Box>
            <Text>
              <Text fw="600" size="sm" span>
                {message.senderDisplayName}{" "}
              </Text>
              - <Timestamp timestamp={message.dateCreated} />
            </Text>
            <Text size="sm" mt="0.2rem">
              <NewlineText date={message.dateCreated} text={message.text} />
            </Text>
          </Box>
          {showAdminOptions && (
            <MessageEditor
              message={message}
              handleDeleteMessage={handleDeleteMessage}
              handleEditMessage={handleEditMessage}
            />
          )}
        </Flex>
      </Blockquote>
    </Indicator>
  );
}

function LoadingError({ loadingError }: { loadingError: boolean }) {
  if (!loadingError) {
    return null;
  }

  return (
    <Alert variant="light" color="red" radius="md" title="Error" icon={<IconInfoCircle />}>
      There was an error loading the messages for this video.
    </Alert>
  );
}

function SendPlatformMessage({
  messageViewer,
  handleSendMessage,
}: {
  messageViewer: MessageParty;
  handleSendMessage: (messageText: string, recipientType: string, callbackFn: () => void) => void;
}) {
  let recipientDisplayType = "";
  let recipientType = "";
  if (messageViewer === MessageParty.CREATOR) {
    recipientDisplayType = "Creator";
    recipientType = MessageParty.CREATOR.toString();
  } else if (messageViewer === MessageParty.BRAND) {
    recipientDisplayType = "Brand";
    recipientType = MessageParty.BRAND.toString();
  } else {
    throw new Error(`Invalid message viewer: ${messageViewer}`);
  }

  const [opened, { open, close }] = useDisclosure(false);
  const [isSending, setIsSending] = useState(false);
  const [messageText, setMessageText] = useState("");

  return (
    <>
      <Modal padding="xs" opened={opened} onClose={close} size="xl" withCloseButton={false}>
        <Stack gap="xs">
          <Textarea
            label={`Send Message to ${recipientDisplayType}`}
            minRows={3}
            autosize
            value={messageText}
            onChange={(event) => setMessageText(event.currentTarget.value)}
          />
          <Flex gap="sm" justify="right" align="center">
            <Button onClick={close} variant="default" color="gray">
              Cancel
            </Button>
            <Button
              loading={isSending}
              onClick={() => {
                setIsSending(true);
                handleSendMessage(messageText, recipientType, () => {
                  setIsSending(false);
                  setMessageText("");
                  close();
                });
              }}>
              Submit
            </Button>
          </Flex>
        </Stack>
      </Modal>
      <Button
        variant="outline"
        size="xs"
        color="red"
        leftSection={<IconSend size="0.8rem" />}
        onClick={open}>
        Send Platform Message to {recipientDisplayType}
      </Button>
    </>
  );
}

export default function MessageHistory({
  messages,
  setMessages,
  messageViewer,
  messagesLoaded,
  loadingError,
  numUnreadMessages,
  messagesLastRead,
  handleMarkMessagesRead,
  showAdminOptions,
  deliverableId,
  scriptId,
  videoId,
}: {
  messages: MessageModel[];
  setMessages: (messages: MessageModel[]) => void;
  messageViewer: MessageParty;
  messagesLoaded: boolean;
  loadingError: boolean;
  numUnreadMessages?: number;
  messagesLastRead?: Date;
  handleMarkMessagesRead?: () => void;
  showAdminOptions?: boolean;
  deliverableId?: string;
  scriptId?: string;
  videoId?: string;
}) {
  if (messages.length === 0 && messagesLoaded && !loadingError) {
    return null;
  }

  const enablePlatformMessaging = showAdminOptions && deliverableId && (scriptId || videoId);
  const enableAdmin = showAdminOptions;

  const handleDeleteMessage = (messageId: string, callbackFn: () => void) => {
    deleteMessage(messageId)
      .then((response) => {
        if (response.success) {
          const newMessages = messages.filter((m: MessageModel) => m.hashId !== messageId);
          setMessages(newMessages);
          showSuccessNotification({ message: "Successfully deleted message." });
        } else {
          showFailureNotification({ message: `Failed to delete message. ${response.error}` });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to delete message. ${error}` });
      })
      .finally(callbackFn);
  };

  const handleEditMessage = (messageId: string, text: string, callbackFn: () => void) => {
    editMessage(messageId, text)
      .then((response) => {
        if (response.success) {
          const newMessage = MessageModel.deserialize(response.message);
          const newMessages = messages.map((m: MessageModel) =>
            m.hashId === messageId ? newMessage : m,
          );
          setMessages(newMessages);
          showSuccessNotification({ message: "Successfully edited message." });
        } else {
          showFailureNotification({ message: `Failed to edit message. ${response.error}` });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to edit message. ${error}.` });
      })
      .finally(callbackFn);
  };

  const handleSendMessage = (
    messageText: string,
    recipientType: string,
    callbackFn: () => void,
  ) => {
    sendMessageAsPlatform({
      deliverableId,
      messageText,
      recipientType,
      scriptId,
      videoId,
    })
      .then((response) => {
        if (response.success) {
          const newMessage = MessageModel.deserialize(response.message);
          const newMessages = [...messages, newMessage];
          setMessages(newMessages);
          showSuccessNotification({ message: "Successfully sent message." });
        } else {
          showFailureNotification({ message: `Failed to send message. ${response.error}` });
        }
      })
      .catch((error) => {
        showFailureNotification({ message: `Failed to send message. ${error}` });
      })
      .finally(callbackFn);
  };

  const [opened, { toggle }] = useDisclosure(false);

  const messagesToDisplay = messages.map((message: MessageModel) => (
    <Message
      key={`${message.dateCreated}-${message.senderType}`}
      message={message}
      messagesLastRead={messagesLastRead}
      messageViewer={messageViewer}
      handleDeleteMessage={handleDeleteMessage}
      handleEditMessage={handleEditMessage}
      showAdminOptions={enableAdmin}
    />
  ));

  if (messagesToDisplay.length === 0 && messagesLoaded && !loadingError) {
    return <Space />;
  }

  // TODO(albert): This is a hack for now to uncollapse unread messages for creators.
  // We should store state in the backend once we finish refactoring the messaging system.
  let computedNumUnreadMessages = 0;

  if (messageViewer === MessageParty.BRAND) {
    computedNumUnreadMessages = numUnreadMessages;
  } else {
    [...messages].reverse().every((message: MessageModel) => {
      if (message.recipientType === MessageParty.CREATOR) {
        computedNumUnreadMessages += 1;
        return true;
      }
      return false;
    });
  }

  const sliceLength = computedNumUnreadMessages
    ? Math.max(NUM_MESSAGES_TO_PREVIEW, computedNumUnreadMessages)
    : NUM_MESSAGES_TO_PREVIEW;
  const messagesToDisplayPreview = messagesToDisplay.slice(-sliceLength);
  const remainingMessagesToDisplay = messagesToDisplay.slice(0, -sliceLength);

  // Count the number of unread messages for the brand based on messagesLastRead
  let brandNumUnreadMessages = 0;
  if (messageViewer === MessageParty.BRAND && messagesLastRead) {
    messages.forEach((message: MessageModel) => {
      if (
        message.dateCreated > messagesLastRead &&
        message.senderType === MessageParty.CREATOR
      ) {
        brandNumUnreadMessages += 1;
      }
    });
  }

  return (
    <Stack>
      <LoadingError loadingError={loadingError} />
      <SkeletonMessage messagesLoaded={messagesLoaded} />
      {messagesLoaded && (
        <Flex align="flex-end" justify="space-between">
          <Group gap="0.3rem" align="baseline" justify="space-between" mb="-0.3rem">
            <Text mb="-xs" fw="500">
              Messages
            </Text>
            {remainingMessagesToDisplay.length > 0 && (
              <Tooltip label={opened ? "Collapse Messages" : "Expand Messages"}>
                <ActionIcon onClick={toggle} size="sm" variant="subtle" color="rgba(0, 0, 0, 1)">
                  {opened ? <IconChevronDown size="0.7rem" /> : <IconChevronUp size="0.7rem" />}
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
          <Group gap="xs" align="baseline">
            {enablePlatformMessaging && (
              <SendPlatformMessage
                messageViewer={messageViewer}
                handleSendMessage={handleSendMessage}
              />
            )}
            {(numUnreadMessages > 0 || brandNumUnreadMessages > 0) && (
              <Button
                leftSection={<IconChecks size="0.7rem" />}
                variant="default"
                size="compact-sm"
                color="gray"
                onClick={handleMarkMessagesRead}>
                <Text size="xs">Mark all as read</Text>
              </Button>
            )}
          </Group>
        </Flex>
      )}
      <Stack gap="xs">
        <Collapse in={opened}>
          <Stack gap="xs">{remainingMessagesToDisplay}</Stack>
        </Collapse>
        {messagesToDisplayPreview}
      </Stack>
    </Stack>
  );
}
