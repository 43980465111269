import React, { useEffect, useState } from "react";

import { Container, Paper, Stack, Switch } from "@mantine/core";

import { ContractOfferTable } from "admin/app/negotiations/ContractOfferTable";
import {
  getContractOffersPendingInternalReview,
  getContractOffersBrandApprovedPendingInternalReview,
  getContractOffersBrandRejected,
  getContractOffersApprovedForContract,
  getContractOffersPendingBrandReview,
  getOffersWithOverrides,
} from "components/contracts/negotiations/api/ContractOfferApi";

export const ContractOfferDashboard = () => {
  const [offersPendingInternalReview, setOffersPendingInternalReview] = useState([]);
  const [offersPendingBrandReview, setOffersPendingBrandReview] = useState([]);
  const [offersBrandApprovedPendingInternalReview, setOffersBrandApprovedPendingInternalReview] =
    useState([]);
  const [offersBrandRejected, setOffersBrandRejected] = useState([]);
  const [offersApprovedForContract, setOffersApprovedForContract] = useState([]);
  const [overriddenOffers, setOverriddenOffers] = useState([]);
  const [hideTestOffers, setHideTestOffers] = useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    getContractOffersPendingInternalReview(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOffersPendingInternalReview(offers.results);
      }
    });
    getContractOffersPendingBrandReview(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOffersPendingBrandReview(offers.results);
      }
    });
    getContractOffersBrandApprovedPendingInternalReview(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOffersBrandApprovedPendingInternalReview(offers.results);
      }
    });
    getContractOffersBrandRejected(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOffersBrandRejected(offers.results);
      }
    });
    getContractOffersApprovedForContract(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOffersApprovedForContract(offers.results);
      }
    });
    getOffersWithOverrides(abortController).then((offers) => {
      if (offers?.results?.length > 0) {
        setOverriddenOffers(offers.results);
      }
    });

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Container fluid>
      <Stack gap="md">
        <Paper p="md">
          <Switch
            checked={hideTestOffers}
            onChange={(event) => setHideTestOffers(event.currentTarget.checked)}
            label="Hide Test Offers"
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={offersPendingInternalReview}
            caption="Creator Offers - Pending Internal Review (Michael)"
            hideTests={hideTestOffers}
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={overriddenOffers}
            caption="Brand Override Requests (Michael)"
            hideTests={hideTestOffers}
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={offersPendingBrandReview}
            caption="Creator Offers - Pending Brand Review (NO ACTION NEEDED)"
            hideTests={hideTestOffers}
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={offersBrandApprovedPendingInternalReview}
            caption="Brand Approved Offers - Pending Internal Review (Michael)"
            hideTests={hideTestOffers}
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={offersBrandRejected}
            caption="Brand Rejected Offers - Pending Internal Review (Michael)"
            hideTests={hideTestOffers}
          />
        </Paper>
        <Paper p="md">
          <ContractOfferTable
            contractOffers={offersApprovedForContract}
            caption="Offers Ready For Creators (Ops Team)"
            hideTests={hideTestOffers}
          />
        </Paper>
      </Stack>
    </Container>
  );
};

export default ContractOfferDashboard;
