import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";

import { Blockquote, Button, Divider, Group, rem, Stack, Text, TextInput } from "@mantine/core";

import { finalizeUsageRightsRequestUpload } from "components/contracts/common/Api";
import { ContractType, UsageRightsRequestStatus } from "components/contracts/common/Common";
import VideoDropzone from "components/contracts/deliverables/VideoDropzone";
import { ContractDeliverableStatus } from "components/contracts/models/Deliverable";
import { UsageRightsRequestDetails } from "components/contracts/models/UsageRightsRequestDetails";
import { SupportedPlatform } from "models/Common";
import { toLongDateTimeStringNoDay } from "utils/DateUtils";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

export const UsageRightsSubmissionContent = ({
  contractId,
  brandName,
  usageRightsRequest,
  setCreatorLastUpdateTime,
}: {
  contractId: string;
  brandName: string;
  usageRightsRequest: UsageRightsRequestDetails;
  setCreatorLastUpdateTime: (date: Date) => void;
}) => {
  const platform = usageRightsRequest.liveContent?.platform;
  let defaultAdCode = "";
  switch (platform) {
    case SupportedPlatform.INSTAGRAM:
      defaultAdCode = usageRightsRequest.instagramAdCode || "";
      break;
    case SupportedPlatform.TIKTOK:
      defaultAdCode = usageRightsRequest.tiktokAdCode || "";
      break;
    default:
      defaultAdCode = usageRightsRequest.instagramAdCode || usageRightsRequest.tiktokAdCode || "";
      break;
  }

  const [adCode, setAdCode] = useState<string>(defaultAdCode);
  const [adCodeErrorMessage, setAdCodeErrorMessage] = useState<string>();

  const [newVideoUploaded, setNewVideoUploaded] = useState<boolean>(false);
  const [uploadedLocation, setUploadedLocation] = useState<string>();

  const playerRef = useRef(null);

  const submitVideoAndAdCode = () => {
    setAdCodeErrorMessage("");
    if (usageRightsRequest.adCodeRequired && (!adCode || adCode.length === 0)) {
      setAdCodeErrorMessage("Ad code cannot be empty.");
      return;
    }
    if (!uploadedLocation || uploadedLocation.length === 0) {
      showFailureNotification({
        message: "Please upload a video first.",
        stayOpen: true,
      });
      return;
    }
    finalizeUsageRightsRequestUpload(
      contractId,
      usageRightsRequest.hashId,
      uploadedLocation,
      adCode,
    )
      .then((response) => {
        if (response.success) {
          showSuccessNotification({
            message: "Upload successful!",
            stayOpen: true,
          });
          setCreatorLastUpdateTime(new Date());
        } else {
          showFailureNotification({
            message: response.message,
            stayOpen: true,
          });
        }
      })
      .catch(() => {
        showFailureNotification({
          message: "Unknown server error.",
          stayOpen: true,
        });
      });
  };

  const uploadButtonDisabled = () => {
    if (usageRightsRequest.status !== UsageRightsRequestStatus.PENDING) {
      return true;
    }
    if (usageRightsRequest.adCodeRequired && (!adCode || adCode.length === 0)) {
      return true;
    }
    if (!uploadedLocation || uploadedLocation.length === 0) {
      return true;
    }
    return false;
  };

  const disputeDate =
    usageRightsRequest.disputeReason && usageRightsRequest.reviewDate
      ? toLongDateTimeStringNoDay(usageRightsRequest.reviewDate)
      : "";
  return (
    <Stack>
      <Text size="sm">
        {usageRightsRequest.status === UsageRightsRequestStatus.REJECTED &&
          `${brandName} has rejected your request.`}
        {!usageRightsRequest.video &&
          !newVideoUploaded &&
          `Please submit the requested video file${
            usageRightsRequest.adCodeRequired ? " and ad code" : ""
          }.`}
        {(usageRightsRequest.video || newVideoUploaded) &&
          usageRightsRequest.status === UsageRightsRequestStatus.ACCEPTED &&
          `${brandName} accepted your video!`}
        {(usageRightsRequest.video || newVideoUploaded) &&
          usageRightsRequest.status === UsageRightsRequestStatus.PENDING &&
          usageRightsRequest.disputeReason &&
          `As of ${disputeDate}, ${brandName} has requested revisions to your draft submission. The message they provided was:`}
        {(usageRightsRequest.video || newVideoUploaded) &&
          usageRightsRequest.status === UsageRightsRequestStatus.PENDING &&
          !usageRightsRequest.disputeReason &&
          `${brandName} is currently reviewing the draft of your video${
            usageRightsRequest.adCodeRequired ? " and ad code" : ""
          }. If you would like to replace the video with an updated version, please upload a new version below.`}
      </Text>
      {(usageRightsRequest.video || newVideoUploaded) &&
        usageRightsRequest.status === UsageRightsRequestStatus.PENDING &&
        usageRightsRequest.disputeReason && (
          <Blockquote p={rem(8)} pl="sm" color="orange" radius="xs">
            <Text size="sm">{usageRightsRequest.disputeReason}</Text>
          </Blockquote>
        )}
      {(usageRightsRequest.video || uploadedLocation) && (
        <Stack>
          <ReactPlayer
            ref={playerRef}
            url={
              // Prefer local video over transcoded video over remote location.
              uploadedLocation ||
              usageRightsRequest.video.transcodedLocation ||
              usageRightsRequest.video.videoLocation
            }
            muted
            controls
          />
          <Divider />
        </Stack>
      )}
      {usageRightsRequest.status === UsageRightsRequestStatus.PENDING && (
        <VideoDropzone
          format={null}
          title=""
          setTitle={undefined}
          caption=""
          setCaption={undefined}
          integrationTimestamp={undefined}
          setIntegrationTimestamp={undefined}
          metadataValid
          setMetadataValid={() => undefined}
          disableMetadata
          setDisableMetadata={() => undefined}
          contractId={contractId}
          contractType={ContractType.USAGE_RIGHTS}
          deliverableId=""
          // At the end of a successful upload, the dropzone will attempt to set the deliverable status.
          // We don't care about the status, we just care that it was changed to signify success.
          setDeliverableStatus={(deliverableStatus) => {
            setNewVideoUploaded(
              deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED,
            );
            if (deliverableStatus === ContractDeliverableStatus.VIDEO_DRAFT_SUBMITTED) {
              setCreatorLastUpdateTime(new Date());
            }
          }}
          // The handle refetch function has an argument that is itself a function. The intent is to
          // call this function at the end of that function, so we just call that argument directly.
          handleRefetchUploadedVideos={(fn) => fn()}
          forUsageRights
          // Capture the location when an upload succeeds
          notifyUploadedLocation={setUploadedLocation}
        />
      )}
      {usageRightsRequest.status === UsageRightsRequestStatus.PENDING &&
        usageRightsRequest.adCodeRequired && (
          <Group>
            <TextInput
              w="100%"
              label={
                usageRightsRequest.liveContent?.platform === SupportedPlatform.TIKTOK
                  ? "TikTok Spark Code"
                  : "Instagram Ad Code"
              }
              error={adCodeErrorMessage || (!adCode && "Code required.")}
              value={adCode}
              onChange={(event) => setAdCode(event.currentTarget.value)}
            />
          </Group>
        )}
      {usageRightsRequest.status === UsageRightsRequestStatus.PENDING && (
        <Group justify="flex-end">
          <Button onClick={submitVideoAndAdCode} disabled={uploadButtonDisabled()}>
            {usageRightsRequest.adCodeRequired ? "Submit Video and Code" : "Submit Video"}
          </Button>
        </Group>
      )}
    </Stack>
  );
};

export default null;
