import React, { useEffect, useState } from "react";

import {
  Container,
  Group,
  LoadingOverlay,
  List,
  Mark,
  Paper,
  Stack,
  Text,
  Title,
  Button,
} from "@mantine/core";
import { AreaChart, BarChart, LineChart } from "@mantine/charts";

import { getCashFlowMetrics, getRevenueMetrics, refreshCashFlowMetrics } from "components/metrics/Api";
import { formatAmount } from "components/contracts/dashboard/Utils";
import { IconRefresh } from "@tabler/icons-react";
import { timeAgo } from "utils/DateUtils";

interface CashFlowTimeSeriesItem {
  date: string;
  balance: number;
  balance_and_float: number;
  average_burn_rate: number;
  remaining_runway_months: number;
  cumulative_float: number;
  liquidity_threshold: number;
}

interface RevenueTimeSeriesItem {
  date: string;
  billable_net_revenue: number;
  realized_net_revenue: number;
  losses: number;
  uncaptured_revenue: number;
}

interface CashBalanceTimeSeriesItem {
  date: string;
  balance: number;
  balance_and_float: number;
  liquidity_threshold: number;
}

interface BurnRateTimeSeriesItem {
  date: string;
  average_burn_rate: number;
}

interface CashFloatTimeSeriesItem {
  date: string;
  cumulative_float: number;
}

export function formatDollarAmount(amount: number): string {
  const absoluteAmount = Math.abs(amount); // Get the absolute value of the amount
  let formattedAmount: string;

  if (absoluteAmount < 1e3) {
    // For amounts less than $1k, format as $0.Xk or -$0.Xk
    formattedAmount = `${(absoluteAmount / 1e3).toFixed(1)}k`;
  } else if (absoluteAmount < 1e6) {
    // For amounts $1k to $1m, format as $Xk or -$Xk
    formattedAmount = `${(absoluteAmount / 1e3).toFixed(1)}k`;
  } else {
    // For amounts $1m and above, format as $X.XXm or -$X.XXm
    formattedAmount = `${(absoluteAmount / 1e6).toFixed(2)}m`;
  }

  // Prepend the dollar sign and, if the original amount was negative, also prepend the negative sign
  return `${amount < 0 ? "-" : ""}$${formattedAmount}`;
}

export default function CashFlowMetrics() {
  const [loading, setLoading] = useState(false);
  const [cashBalance, setCashBalance] = useState<CashBalanceTimeSeriesItem[]>([]);
  const [burnRate, setBurnRate] = useState<BurnRateTimeSeriesItem[]>([]);
  const [cashFloat, setCashFloat] = useState<CashFloatTimeSeriesItem[]>([]);
  const [revenue, setRevenue] = useState<RevenueTimeSeriesItem[]>([]);
  const [metricsLastUpdated, setMetricsLastUpdated] = useState<Date>(null);
  const [, setUpdateTrigger] = useState(0);

  const fetchCashFlowMetrics = async (abortController: AbortController) => {
    return getCashFlowMetrics(abortController);
  };

  const fetchRevenueMetrics = async (abortController: AbortController) => {
    return getRevenueMetrics(abortController);
  };

  const fetchMetrics = async (abortController?: AbortController) => {
    setLoading(true);
    try {
      const [cashFlowResponse, revenueResponse] = await Promise.all([
        fetchCashFlowMetrics(abortController),
        fetchRevenueMetrics(abortController)
      ]);

      if (!cashFlowResponse || !revenueResponse) {
        throw new Error("Failed to fetch metrics");
      }

      const { success: cashFlowSuccess, cashFlowMetrics, lastUpdated: cashFlowLastUpdated } = cashFlowResponse;
      const { success: revenueSuccess, revenueMetrics, lastUpdated: revenueLastUpdated } = revenueResponse;

      if (!cashFlowSuccess || !revenueSuccess) {
        throw new Error("Failed to fetch data");
      }

      // Process cash flow metrics
      const data: CashFlowTimeSeriesItem[] = JSON.parse(cashFlowMetrics);
      setCashBalance(data.filter((item) => item.balance !== null));
      setBurnRate(data.filter((item) => item.average_burn_rate !== null)); 
      setCashFloat(data.filter((item) => item.cumulative_float !== null));
      setMetricsLastUpdated(new Date(cashFlowLastUpdated));

      // Process revenue metrics
      const revenueData: RevenueTimeSeriesItem[] = JSON.parse(revenueMetrics);
      setRevenue(revenueData);
      
      // Update last updated timestamp if revenue data is newer
      const revenueLastUpdatedDate = new Date(revenueLastUpdated);
      if (revenueLastUpdatedDate > metricsLastUpdated) {
        setMetricsLastUpdated(revenueLastUpdatedDate);
      }

    } catch (error) {
      console.error("Error fetching metrics:", error);
    } finally {
      setLoading(false);
    }
  };

  const refreshMetrics = async (abortController?: AbortController) => {
    setLoading(true);
    refreshCashFlowMetrics(abortController).finally(() => fetchMetrics(abortController));
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMetrics(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTrigger((prev) => prev + 1);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  const latestBalance = cashBalance[cashBalance.length - 1]?.balance;
  const latestBurnRate = burnRate[burnRate.length - 1]?.average_burn_rate;
  const latestCashFloat = cashFloat[cashFloat.length - 1]?.cumulative_float;
  const latestLiquidityThreshold = cashBalance[cashBalance.length - 1]?.liquidity_threshold;

  return (
    <Paper radius="sm" p="xl">
      <LoadingOverlay visible={loading} />
      <Group justify="space-between">
          <Stack gap={2} px="xl">
            <Title order={1} fw="500">
              Cash Flow Metrics
            </Title>
            {metricsLastUpdated && (
              <Text size="sm" c="dimmed">
                Last updated {timeAgo(metricsLastUpdated)}
              </Text>
            )}
          </Stack>
          <Button
            leftSection={<IconRefresh size="1rem" />}
            size="xs"
            onClick={() => refreshMetrics()}>
            Refresh Metrics
          </Button>
        </Group>
      <Container>
        <Stack py="sm" px="xl" gap={2}>
          <Group justify="space-between">
            <Title order={2} fw="500">
              Daily Cash Balance
            </Title>
            <Text fw="500">{formatAmount(latestBalance)}</Text>
          </Group>
          <Text size="sm">
            The cumulative sum of all transactions across Brex and Stripe.
            <List size="sm">
              <List.Item>
                <Text size="sm" c="dimmed">
                  Liquidity Threshold - Estimate of the minimum cash balance required to ensure
                  liquidity for a month of operations.
                </Text>
              </List.Item>
            </List>
          </Text>
        </Stack>
        <LineChart
          h={300}
          data={cashBalance}
          dataKey="date"
          valueFormatter={(value) => formatDollarAmount(value / 100)}
          series={[
            { name: "balance", label: "Total Balance (Brex + Stripe)", color: "teal" },
            {
              name: "balance_and_float",
              label: "Total Balance + Float",
              color: "blue",
              strokeDasharray: "6 4",
            },
            { name: "liquidity_threshold", label: "Liquidity Threshold", color: "red" },
          ]}
          curveType="natural"
          withDots={false}
          referenceLines={[
            { y: latestLiquidityThreshold, label: "Liquidity Threshold", color: "red" },
          ]}
        />
        <Stack py="sm" px="xl" gap={2}>
          <Group justify="space-between">
            <Title order={2} fw="500">
              Daily Cash Float
            </Title>
            <Text fw="500">{formatAmount(latestCashFloat)}</Text>
          </Group>
          <Text size="sm" c="dimmed">
            The cumulative sum of all paid creator fees owed to us by brands.
          </Text>
        </Stack>
        <LineChart
          h={300}
          data={cashFloat}
          dataKey="date"
          valueFormatter={(value) => formatDollarAmount(value / 100)}
          series={[{ name: "cumulative_float", label: "Current Float", color: "orange" }]}
          curveType="linear"
          withDots={false}
        />
        <Stack py="sm" px="xl" gap={2}>
          <Title order={2} fw="500">
            Monthly Net Revenue
          </Title>
          <List size="sm">
            <List.Item>
              <Text size="sm" c="dimmed">
                <Text fw="500" span>
                  Net Revenue
                </Text>{" "}
                - The amount of revenue realized per month, minus creator fees.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm" c="dimmed">
                <Text fw="500" span>
                  Uncapture Take
                </Text>{" "}
                - Platform fees that have been credited back to brands.
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm" c="dimmed">
                <Text fw="500" span>
                  Losses
                </Text>{" "}
                - Creator fees that have been credited back to brands.
              </Text>
            </List.Item>
          </List>
        </Stack>
        <BarChart
          h={300}
          data={revenue}
          valueFormatter={(value) => {
            const amountInDollars = value / 100;
            const formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            });
            return formatter.format(amountInDollars);
          }}
          dataKey="date"
          type="stacked"
          series={[
            { name: "realized_net_revenue", label: "Net Revenue", color: "blue" },
            { name: "uncaptured_revenue", label: "Uncaptured Take", color: "gray" },
            { name: "losses", label: "Losses", color: "red" },
          ]}
          withLegend
        />
        <Stack py="sm" px="xl" gap={2}>
          <Group justify="space-between">
            <Title order={2} fw="500">
              Monthly Net Burn Rate
            </Title>
            <Mark color={latestBurnRate > 0 ? "teal" : "red"}>
              <Text fw="500">{formatAmount(latestBurnRate)}</Text>
            </Mark>
          </Group>
          <Text size="sm">
            Inflows minus Outflows across a 30 day window, averaged across the last 30 time windows.{" "}
            <List size="sm">
              <List.Item>
                <Text size="sm" c="dimmed">
                  Excludes fundraising Inflows by excluding Domestic Wire and Check transactions.
                </Text>
              </List.Item>
              <List.Item>
                <Text size="sm" c="dimmed">
                  As of Feb 1, 2024, adjusts burn rate against Cash Float for paid Creator Fees.
                </Text>
              </List.Item>
            </List>
          </Text>
        </Stack>
        <AreaChart
          h={300}
          data={burnRate}
          dataKey="date"
          valueFormatter={(value) => formatDollarAmount(value / 100)}
          series={[{ name: "average_burn_rate", label: "Burn Rate", color: "bright" }]}
          curveType="natural"
          withDots={false}
          type="split"
          strokeWidth={1}
          splitColors={["teal", "red"]}
          referenceLines={[{ x: "Feb 01, 2024", label: "Correct for Cash Float" }]}
        />
      </Container>
    </Paper>
  );
}
