import React, { useState } from "react";

import {
  Badge,
  Box,
  Button,
  Card,
  Collapse,
  Flex,
  Grid,
  Group,
  NumberInput,
  Space,
  Spoiler,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core";

import { useDisclosure, useElementSize } from "@mantine/hooks";

import { IconCheck, IconPlus, IconMinus } from "@tabler/icons-react";

import { approveContractReview, rejectContractReview } from "components/contracts/tasks/api/Api";

import DueDateBadge from "components/contracts/tasks/common/DueDateBadge";
import { ContractBrandReviewDeliverable } from "components/contracts/brand_review/ContractBrandReviewDeliverable";
import CreatorAvatar from "components/contracts/tasks/common/CreatorAvatar";
import { ContractReview } from "components/contracts/tasks/models/Common";
import PlatformTable from "components/creator_lists/unified_creator_rep/PlatformTable";
import { CreatorDetails } from "components/discovery/Datamodels";

import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

import { useUser } from "utils/UserContext";

const MAX_FEEDBACK_LENGTH = 5000;
const DESCRIPTION_HEIGHT = 35;

function StatusBadge({ deadline }: { deadline?: Date }) {
  if (!deadline) {
    return (
      <Badge variant="light" color="red" w="fit-content">
        Review Required
      </Badge>
    );
  }

  return (
    <Group gap="xs">
      <Badge variant="light" color="red" w="fit-content">
        Review Required
      </Badge>
      <DueDateBadge deadline={deadline} />
    </Group>
  );
}

export function CreatorHeader({
  displayName,
  creatorDetails,
  deadline,
  includeBadge = true,
}: {
  displayName: string;
  creatorDetails: CreatorDetails;
  deadline?: Date;
  includeBadge?: boolean;
}) {
  const [userProfile, userProfileLoading] = useUser();

  const isStaff = userProfile?.is_staff;

  const avatarUrl =
    creatorDetails.youtube_channel?.avatar_url ||
    creatorDetails.tiktok_profile?.info?.avatar_url ||
    creatorDetails.instagram_profile?.info?.avatar_url;
  const description =
    creatorDetails.youtube_channel?.description ||
    creatorDetails.tiktok_profile?.info?.description ||
    creatorDetails.instagram_profile?.info?.biography;

  const { ref: descriptionRef, height: descriptionHeight } = useElementSize();

  return (
    <Grid grow>
      <Grid.Col span={deadline && includeBadge? 7 : 9}>
        <CreatorAvatar
          displayName={displayName}
          avatarUrl={avatarUrl}
          size="lg"
          description={
            descriptionHeight > DESCRIPTION_HEIGHT ? (
              <Spoiler
                maxHeight={DESCRIPTION_HEIGHT}
                showLabel={<Text size="xs">Show more</Text>}
                hideLabel={<Text size="xs">Hide</Text>}>
                <Text ref={descriptionRef} size="xs" c="var(--mantine-color-gray-9)">
                  {description}
                </Text>
              </Spoiler>
            ) : (
              <Text ref={descriptionRef} size="xs" c="var(--mantine-color-gray-9)">
                {description}
              </Text>
            )
          }
          onClick={() => {
            if (isStaff && creatorDetails.creator_id) {
              window.open(`/admin/creator?creatorId=${creatorDetails.creator_id}`, "_blank");
            }
          }}
        />
      </Grid.Col>
      {includeBadge && (
        <Grid.Col span={deadline ? 3 : 1}>
          <Group justify="right" align="center">
            <StatusBadge deadline={deadline} />
          </Group>
        </Grid.Col>
      )}
    </Grid>
  );
}

function DeliverablePackage({
  contractReview,
  creatorAskingPrice,
  combinedRecommendedPrice,
}: {
  contractReview: ContractReview;
  creatorAskingPrice: number;
  combinedRecommendedPrice: number;
}) {
  return (
    <Card
      radius="md"
      withBorder
      styles={{
        root: {
          borderColor: "var(--mantine-color-blue-6)",
          backgroundColor: "var(--mantine-color-blue-0)",
        },
      }}>
      <Stack gap="xs">
        <Flex justify="space-between" align="center">
          <Title order={4}>Package</Title>
          <Button size="xs" leftSection={<IconCheck size="1rem" />}>
            Selected
          </Button>
        </Flex>
        <Stack gap={0}>
          <Text size="md" fw="600">
            Deliverables:
          </Text>
          <Flex gap="sm" mt={4} mb="xs">
            {contractReview.deliverables.map((deliverable) => (
              <ContractBrandReviewDeliverable
                key={`deliverable-card-${deliverable.format}`}
                format={deliverable.format}
                platform={deliverable.platform}
                usageRights={deliverable.usageRightsDays}
              />
            ))}
          </Flex>
          <Flex justify="space-between" align="center" w="200px">
            <Text size="sm">Creator Asking Price:</Text>
            <Text size="sm" fw="600">
              ${creatorAskingPrice.toLocaleString("en-US", {})}
            </Text>
          </Flex>
          <Flex justify="space-between" align="center" w="200px">
            <Text size="sm">Recommended Price:</Text>
            <Text size="sm" fw="600">
              ${combinedRecommendedPrice.toLocaleString("en-US", {})}
            </Text>
          </Flex>
        </Stack>
      </Stack>
    </Card>
  );
}

function BrandFeedback({
  brandFeedback,
  setBrandFeedback,
}: {
  brandFeedback: string;
  setBrandFeedback: (value: string) => void;
}) {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box>
      <Button
        variant="transparent"
        color="gray"
        size="sm"
        onClick={() => {
          toggle();
          setBrandFeedback("");
        }}
        leftSection={opened ? <IconMinus size="1rem" /> : <IconPlus size="1rem" />}
        styles={{
          root: {
            padding: 0,
            height: "auto",
          },
          section: {
            marginRight: 4,
          },
        }}>
        <Text size="sm" c="dimmed">
          Add Note
        </Text>
      </Button>
      <Collapse in={opened}>
        <Textarea
          mt="xs"
          size="sm"
          placeholder="Optional: Add a note"
          value={brandFeedback}
          onChange={(e) => setBrandFeedback(e.target.value)}
          rightSection={
            <Text size="xs" ta="end" pr="4px">
              {brandFeedback.length}/{MAX_FEEDBACK_LENGTH}
            </Text>
          }
          error={brandFeedback.length > MAX_FEEDBACK_LENGTH}
          rightSectionWidth="67px"
          miw="200px"
          styles={{
            section: {
              justifyContent: "flex-end",
            },
          }}
        />
      </Collapse>
    </Box>
  );
}

function ActionRow({
  taskId,
  displayName,
  contractReview,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  contractReview: ContractReview;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  const creatorAskingPrice = contractReview.creator_asking_price / 100;
  const combinedRecommendedPrice = contractReview.recommended_price / 100;

  const [brandFeedback, setBrandFeedback] = useState("");
  const [maxOffer, setMaxOffer] = useState(combinedRecommendedPrice);

  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const handleApproveContractReview = () => {
    setApproveLoading(true);
    approveContractReview({ taskId, feedback: brandFeedback, maxOfferCap: maxOffer })
      .then((response) => {
        if (response.success) {
          handleCompleteTask();
          showSuccessNotification({
            title: "Creator approved",
            message: `${displayName} has been approved, and the maximum bid has been set to $${maxOffer.toLocaleString()}.`,
          });
        } else {
          showFailureNotification({
            title: "Failed to approve creator",
            message: response.error,
          });
        }
      })
      .catch((e) => {
        showFailureNotification({
          title: "Failed to approve creator",
          message: e.message,
        });
      })
      .finally(() => {
        setApproveLoading(false);
      });
  };

  const handleRejectContractReview = () => {
    setRejectLoading(true);
    rejectContractReview({ taskId, feedback: brandFeedback })
      .then((response) => {
        if (response.success) {
          handleCompleteTask();
          showSuccessNotification({
            title: "Creator declined",
            message: `${displayName} has been declined and will not be a part of this campaign.`,
          });
        } else {
          showFailureNotification({
            title: "Failed to decline creator",
            message: response.error,
          });
        }
      })
      .catch((e) => {
        showFailureNotification({
          title: "Failed to decline creator",
          message: e.message,
        });
      })
      .finally(() => {
        setRejectLoading(false);
      });
  };

  return (
    <Stack gap="sm">
      <DeliverablePackage
        contractReview={contractReview}
        creatorAskingPrice={creatorAskingPrice}
        combinedRecommendedPrice={combinedRecommendedPrice}
      />
      <Stack gap={0}>
        <Text fw="500" size="sm">
          Enter your max price for the selected package
        </Text>
        <Text size="xs" c="dimmed">
          Once submitted, your maximum offer is final. Regardless of your max offer, we will try and
          contract the creator for the lowest rate possible.
        </Text>
        <NumberInput
          allowDecimal={false}
          hideControls
          id="brandMaxOffer"
          min={1}
          mt="xs"
          onChange={(e) => setMaxOffer(Number(e))}
          prefix="$"
          thousandSeparator=","
          value={maxOffer}
          w={160}
          error={maxOffer < combinedRecommendedPrice}
        />
        {maxOffer < combinedRecommendedPrice && (
          <Text size="xs" c="red" mt="xs" mb="-xs">
            The creator will be more likely to accept if your offer is at least $
            {combinedRecommendedPrice.toLocaleString("en-US", {})}
          </Text>
        )}
        <Space h="xs" />
        <BrandFeedback brandFeedback={brandFeedback} setBrandFeedback={setBrandFeedback} />
      </Stack>
      <Group justify="space-bewteen" grow>
        <Button
          color="red"
          fullWidth
          radius="md"
          disabled={brandFeedback.length > MAX_FEEDBACK_LENGTH}
          loading={rejectLoading}
          onClick={handleRejectContractReview}>
          Decline Creator
        </Button>
        <Button
          color="green"
          fullWidth
          radius="md"
          disabled={brandFeedback.length > MAX_FEEDBACK_LENGTH}
          loading={approveLoading}
          onClick={handleApproveContractReview}>
          Approve Max Offer
        </Button>
      </Group>
    </Stack>
  );
}

export default function ContractReviewContent({
  taskId,
  contractReview,
  deadline,
  handleCompleteTask,
}: {
  taskId: string;
  contractReview: ContractReview;
  deadline: Date;
  handleCompleteTask: (waitingForCreator?: boolean) => void;
}) {
  // NOTE(albert, 11/14/24): We set this as a state variable so that the
  // PlatformTable doesn't re-render every time the ActionRow re-renders.
  // This is a hack to prevent unnecessary re-renders, since the underlying
  // has a useEffect that depends on this value.
  const [defaultExpandedPlatforms, ] = useState<string[]>([]);

  const displayName =
    contractReview.creator_details.youtube_channel?.title ||
    contractReview.creator_details.tiktok_profile?.info?.display_name ||
    contractReview.creator_details.instagram_profile?.info?.display_name;

  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <CreatorHeader
          displayName={displayName}
          creatorDetails={contractReview.creator_details}
          deadline={deadline}
        />
        <PlatformTable
          creator={contractReview.creator_details}
          defaultExpandedPlatforms={defaultExpandedPlatforms}
          refetchThumbnails
        />
        <ActionRow
          taskId={taskId}
          displayName={displayName}
          contractReview={contractReview}
          handleCompleteTask={handleCompleteTask}
        />
      </Stack>
    </Card>
  );
}
