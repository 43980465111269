import React, { useEffect, useState } from "react";

import { Button, Group, Stack, Table, Text } from "@mantine/core";

import { modals } from "@mantine/modals";

import { resetOpsSourcingDailyTasks } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { OpsDailyTaskRow } from "admin/app/ops/tasks/OpsDailyTaskRow";
import { CreatorSetSourcingPlan } from "models/OpsTask";
import { User } from "models/User";

export const OpsDailyTaskTable = ({
  opsUsers,
  sourcingAllocations,
  priority,
}: {
  opsUsers: User[];
  sourcingAllocations: CreatorSetSourcingPlan[];
  priority: Record<number, number>;
}) => {
  const { creatorSets } = useAdminAppContext();

  const [sortedAllocations, setSortedAllocations] = useState([]);

  useEffect(() => {
    // Sort by priority array
    if (creatorSets && sourcingAllocations && priority) {
      if (Object.keys(priority)?.length > 0) {
        sourcingAllocations.sort((a, b) => {
          const aPriority = priority[a.creator_set_id];
          const bPriority = priority[b.creator_set_id];
          return aPriority - bPriority;
        });
        setSortedAllocations(sourcingAllocations);
      } else {
        // Fallback sort by brand min priority, brand name, priority
        sourcingAllocations.sort((a, b) => {
          // get the creator set
          const aCreatorSet = creatorSets.find((creatorSet) => creatorSet.id === a.creator_set_id);
          const bCreatorSet = creatorSets.find((creatorSet) => creatorSet.id === b.creator_set_id);

          if (aCreatorSet?.brand.id < bCreatorSet?.brand.id) {
            return -1;
          } else if (aCreatorSet.brand.id > bCreatorSet.brand.id) {
            return 1;
          }

          if (aCreatorSet.name < bCreatorSet.name) {
            return -1;
          } else if (aCreatorSet.name > bCreatorSet.name) {
            return 1;
          }
          return a.priority - b.priority;
        });
      }
      setSortedAllocations(sourcingAllocations);
    }
  }, [sourcingAllocations, priority, creatorSets]);

  return (
    <Stack gap="md">
      <Table horizontalSpacing="md" verticalSpacing="md" highlightOnHover withTableBorder>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Brand</Table.Th>
            <Table.Th>Creator Set</Table.Th>
            <Table.Th>Priority</Table.Th>
            <Table.Th>Difficulty</Table.Th>
            <Table.Th>Assignee</Table.Th>
            <Table.Th>Sourcing Goal</Table.Th>
            <Table.Th>Unallocated (Remaining)</Table.Th>
            <Table.Th>Remaining in Brand Review</Table.Th>
            <Table.Th>Remaining in Internal Review</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sortedAllocations.map((creatorSetPlan) => (
            <OpsDailyTaskRow
              key={creatorSetPlan.creator_set_id}
              creatorSetPlan={creatorSetPlan}
              opsUsers={opsUsers}
              priority={priority[creatorSetPlan.creator_set_id]}
            />
          ))}
        </Table.Tbody>
      </Table>
      <Group align="center" justify="center">
        <Button
          color="red"
          onClick={() =>
            modals.openConfirmModal({
              title: "Reset Daily Sourcing Assignments",
              children: (
                <Stack>
                  <Text size="sm">
                    Please confirm that you want to clear the current assignments.
                  </Text>
                </Stack>
              ),
              labels: { confirm: "Confirm", cancel: "Cancel" },
              onCancel: () => console.log("Cancel"),
              onConfirm: () =>
                resetOpsSourcingDailyTasks().finally(() => {
                  window.location.href = "/admin/ops?navItem=sourcing";
                }),
            })
          }>
          Clear Plan
        </Button>
      </Group>
    </Stack>
  );
};

export default OpsDailyTaskTable;
