import React from "react";
import { Divider, MantineColor, NumberInput, Stack, Text } from "@mantine/core";
import { IconCurrencyDollar } from "@tabler/icons-react";

import AdminButton from "components/creator/AdminButton";
import DeliverablePackage from "models/DeliverablePackage";
import { UsageRightsDurationMultiplier, UsageRightsDurationToDays } from "models/Common";

const NeedsApprovalModule = ({
  selectedDeliverable,
  approvedPrice,
  creatorAskingPrice,
  usagePrice,
  showError,
  errorMessage,
  buttonText,
  buttonColor,
  buttonLoading,
  hasRecommended,
  onChangeHandler,
  setUsagePrice,
  adminHandler,
  isOps,
}: {
  selectedDeliverable: DeliverablePackage;
  approvedPrice: number;
  creatorAskingPrice: number;
  usagePrice: number;
  showError: boolean;
  errorMessage: string;
  buttonText: string;
  buttonColor: MantineColor;
  buttonLoading: boolean;
  hasRecommended?: boolean;
  onChangeHandler: (value: string | number) => void;
  setUsagePrice: (value: number) => void;
  adminHandler: () => void;
  isOps?: boolean;
}) => {
  const usageRightsDescription = selectedDeliverable.usage_rights
    ? `$${Math.round(
        approvedPrice * UsageRightsDurationMultiplier[selectedDeliverable.usage_rights],
      )} is recommended for an offer price of $${approvedPrice} with ${
        UsageRightsDurationToDays[selectedDeliverable.usage_rights]
      } days of usage rights`
    : "";
  return (
    <>
      <Stack>
        <Text size="sm" fw={600}>
          Manually Approve Price
        </Text>
        <NumberInput
          label="Recommended Offer Price"
          description={`Enter a price between $1 and $${creatorAskingPrice}`}
          value={approvedPrice}
          required
          allowNegative={false}
          allowDecimal={false}
          min={1}
          max={creatorAskingPrice}
          clampBehavior="strict"
          leftSection={<IconCurrencyDollar />}
          onChange={onChangeHandler}
        />
        <NumberInput
          label="Recommended Usage Rights Price"
          description={usageRightsDescription}
          value={usagePrice}
          required
          allowNegative={false}
          allowDecimal={false}
          leftSection={<IconCurrencyDollar />}
          onChange={(value) => setUsagePrice(Number(value))}
        />
        <Text size="sm" fw={600}>
          Total Recommended Price: ${approvedPrice + usagePrice}
        </Text>
        {showError && (
          <Text c="red" size="sm">
            {errorMessage}
          </Text>
        )}
        <AdminButton
          loading={buttonLoading}
          color={buttonColor}
          // disabled={(hasRecommended && selectedDeliverable.is_recommended === false) || isOps}
          disabled={isOps}
          variant="light"
          onClick={adminHandler}>
          {buttonText}
        </AdminButton>
      </Stack>
      <Divider my="sm" />
    </>
  );
};

export default NeedsApprovalModule;
