import React, { useEffect, useState } from "react";

import {
  Anchor,
  Button,
  Flex,
  Group,
  Modal,
  Pagination,
  Paper,
  Pill,
  Select,
  Space,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core";
import { fetchDeliverablesForBrand } from "components/contracts/common/Api";
import { DatePickerInput } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { IconRotateClockwise } from "@tabler/icons-react";

import {
  actionButton,
  getExternalDisplayUsername,
  iconFromBoolean,
  makeCreatorRep,
  makeDeliverableFormatName,
  makeDeliverableFormatRep,
  sortData,
  Th,
} from "campaigns/main/campaignPage/contracts_overview/Common";
import { getFormattedCurrency, SocialHandle } from "components/contracts/dashboard/Utils";
import { RangeFilter } from "components/discovery/search/SearchFilters";
import { fromISODateString, toShortDateString } from "utils/DateUtils";
import { chunk } from "utils/PaginationUtils";
import { useUser } from "utils/UserContext";

const PAGE_SIZE = 50;

interface DeliverableInfo {
  deliverableHash: string;
  contractId: number;
  campaignHash: number;
  campaignName: string;
  adGroupId: number;
  adGroupName: string;
  creatorId: number;
  firstName: string;
  lastName: string;
  socialHandles: SocialHandle[];
  avatarUrl: string;
  platform: string;
  format: string;
  liveDate: Date;
  usageRightsState: string;
  imputedCost: number;
  status: string;
}

interface RowData {
  // Most of these fields are for sorting
  creatorName: string;
  campaignName: string;
  adGroupName: string;
  completionDate: Date;
  cost: number;
  platform: string;
  format: string;
  usageRightsState: string;
  status: string;
  postContent: boolean;
  // This link to ContractInfo aids in rendering row values
  deliverableInfo: DeliverableInfo;
}

// Extract filter values from a list of contract info.
// This is intended to populate filter fields.
function extractFilterValues(deliverables: DeliverableInfo[]) {
  const res = {
    campaignNames: [
      ...new Set(
        deliverables.map((deliverableInfo) => deliverableInfo.campaignName).filter((val) => val),
      ),
    ].sort(),
    adGroupNames: [
      ...new Set(
        deliverables.map((deliverableInfo) => deliverableInfo.adGroupName).filter((val) => val),
      ),
    ].sort(),
    formats: [
      ...new Set(
        deliverables
          .map((deliverableInfo) => makeDeliverableFormatName(deliverableInfo.format))
          .filter((val) => val),
      ),
    ].sort(),
    maximumCost: deliverables
      .map((deliverableInfo) => deliverableInfo.imputedCost)
      .reduce((prev, curr) => Math.max(prev, curr)),
  };
  return res;
}

// A single summary card, showing the state and number of deliverables in that state.
const SummaryCard = ({
  label,
  value,
  isSelected,
  onClick,
  isLoading = false,
}: {
  label: string;
  value: number;
  isSelected: boolean;
  onClick: () => void;
  isLoading?: boolean;
}) => {
  const buttonStylesWhenSelected = {
    root: {
      // Border is blue when selected
      outlineColor: "#228BE6",
      borderColor: "#228BE6",
      boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    },
    label: {
      width: "100%",
    },
  };

  const buttonStylesWhenNotSelected = {
    // Border is default when not selected
    root: {
      outlineColor: "",
      borderColor: "",
      boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    },
    label: {
      width: "100%",
    },
  };

  return (
    <Button
      loading={isLoading}
      radius="md"
      color="#868e96"
      variant="outline"
      onClick={onClick}
      justify="flex-start"
      h={100}
      styles={isSelected ? buttonStylesWhenSelected : buttonStylesWhenNotSelected}>
      <Stack w="100%">
        <Flex justify="flex-start">
          <Title order={6} c="black">
            {label}
          </Title>
        </Flex>
        <Flex justify="flex-start">
          <Title order={4} c="black">
            {value}
          </Title>
        </Flex>
      </Stack>
    </Button>
  );
};

// Summary cards are buttons that filter on status but display information
// about contracts in that status
const SummaryCards = ({
  deliverables,
  selected,
  setSelected,
  isLoading,
}: {
  // The filtered list of contracts
  deliverables: DeliverableInfo[];
  // Which of the cards is currently selected (or null)
  selected: string | null;
  // A callback to update the selected state
  setSelected: (whichSelected: string) => void;
  // If the tab is currently loading data
  isLoading: boolean;
}) => {
  const [cardCounts, setCardCounts] = useState<Record<string, number>>({
    notStarted: 0,
    conceptReview: 0,
    draftReview: 0,
    live: 0,
    verified: 0,
  });

  // Update card values when the input changes
  useEffect(() => {
    const newCounts = {
      notStarted: 0,
      conceptReview: 0,
      draftReview: 0,
      live: 0,
      verified: 0,
    };
    deliverables.forEach((deliverableInfo) => {
      switch (deliverableInfo.status) {
        case "NOT_STARTED":
          newCounts.notStarted += 1;
          break;
        case "WAITING_FOR_SCRIPT":
        case "SCRIPT_SUBMITTED":
        case "SCRIPT_REVISIONS_REQUESTED":
          newCounts.conceptReview += 1;
          break;
        case "WAITING_FOR_VIDEO_DRAFT":
        case "VIDEO_DRAFT_SUBMITTED":
        case "VIDEO_DRAFT_REVISIONS_REQUESTED":
        case "WAITING_FOR_LIVE_CONTENT":
          newCounts.draftReview += 1;
          break;
        case "LIVE_CONTENT_SUBMITTED":
        case "LIVE_CONTENT_REVISIONS_REQUESTED":
          newCounts.live += 1;
          break;
        case "COMPLETE":
          newCounts.verified += 1;
          break;
        default:
          break;
      }
    });
    setCardCounts(newCounts);
  }, [deliverables]);

  return (
    <Group grow>
      <SummaryCard
        isLoading={isLoading}
        label="Not Started"
        value={cardCounts.notStarted}
        isSelected={selected === "notStarted"}
        onClick={() => {
          if (selected === "notStarted") {
            setSelected(null);
          } else {
            setSelected("notStarted");
          }
        }}
      />
      <SummaryCard
        isLoading={isLoading}
        label="Concept Review"
        value={cardCounts.conceptReview}
        isSelected={selected === "conceptReview"}
        onClick={() => {
          if (selected === "conceptReview") {
            setSelected(null);
          } else {
            setSelected("conceptReview");
          }
        }}
      />
      <SummaryCard
        isLoading={isLoading}
        label="Draft Review"
        value={cardCounts.draftReview}
        isSelected={selected === "draftReview"}
        onClick={() => {
          if (selected === "draftReview") {
            setSelected(null);
          } else {
            setSelected("draftReview");
          }
        }}
      />
      <SummaryCard
        isLoading={isLoading}
        label="Live (Unverified)"
        value={cardCounts.live}
        isSelected={selected === "live"}
        onClick={() => {
          if (selected === "live") {
            setSelected(null);
          } else {
            setSelected("live");
          }
        }}
      />
      <SummaryCard
        isLoading={isLoading}
        label="Live (Verified)"
        value={cardCounts.verified}
        isSelected={selected === "verified"}
        onClick={() => {
          if (selected === "verified") {
            setSelected(null);
          } else {
            setSelected("verified");
          }
        }}
      />
    </Group>
  );
};

// Change status into a pill
function pillify(status: string) {
  let text = status;
  let textColor = "";
  let bgColor = "";
  switch (status) {
    case "NOT_STARTED":
      text = "NOT STARTED";
      textColor = "#FAB005";
      bgColor = "#FFF9DB";
      break;
    case "WAITING_FOR_SCRIPT":
    case "SCRIPT_SUBMITTED":
    case "SCRIPT_REVISIONS_REQUESTED":
      text = "CONCEPT REVIEW";
      textColor = "#FA5252";
      bgColor = "#FFF5F5";
      break;
    case "WAITING_FOR_VIDEO_DRAFT":
    case "VIDEO_DRAFT_SUBMITTED":
    case "VIDEO_DRAFT_REVISIONS_REQUESTED":
    case "WAITING_FOR_LIVE_CONTENT":
      text = "DRAFT REVIEW";
      textColor = "#FA5252";
      bgColor = "#FFF5F5";
      break;
    case "LIVE_CONTENT_SUBMITTED":
    case "LIVE_CONTENT_REVISIONS_REQUESTED":
      text = "LIVE";
      textColor = "#228BE6";
      bgColor = "#E7F5FF";
      break;
    case "COMPLETE":
      text = "VERIFIED";
      textColor = "#40C057";
      bgColor = "#EBFBEE";
      break;
    default:
      break;
  }
  return (
    <Pill
      c={textColor}
      styles={{
        root: {
          backgroundColor: bgColor,
        },
      }}>
      {text}
    </Pill>
  );
}

const PaginatedTableSortRow = ({ row }: { row: RowData }) => {
  const [userProfile, userProfileLoading] = useUser();
  const [isStaff, setIsStaff] = useState<boolean>(false);

  useEffect(() => {
    if (!userProfileLoading && userProfile?.is_staff) {
      setIsStaff(userProfile.is_staff);
    }
  }, [userProfileLoading, userProfile]);

  const displayCompletionDate = row.completionDate ? toShortDateString(row.completionDate) : "TBD";
  const usageRights = row.usageRightsState !== "not_required";

  return (
    <Table.Tr key={row.deliverableInfo.deliverableHash}>
      <Table.Td>
        {makeCreatorRep(
          row.creatorName,
          row.deliverableInfo.creatorId,
          row.deliverableInfo.avatarUrl,
          row.deliverableInfo.socialHandles,
          isStaff,
        )}
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`https://www.1stcollab.com/campaigns/${row.deliverableInfo.campaignHash}/`}
          target="_blank">
          {row.campaignName}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Anchor
          href={`https://www.1stcollab.com/campaigns/${row.deliverableInfo.campaignHash}/${row.deliverableInfo.adGroupId}/`}
          target="_blank">
          {row.adGroupName}
        </Anchor>
      </Table.Td>
      <Table.Td>{makeDeliverableFormatRep(row.format)}</Table.Td>
      <Table.Td>{displayCompletionDate}</Table.Td>
      <Table.Td>{iconFromBoolean(row.postContent)}</Table.Td>
      <Table.Td>{iconFromBoolean(usageRights)}</Table.Td>
      <Table.Td>{getFormattedCurrency(row.cost || 0)}</Table.Td>
      <Table.Td>{pillify(row.deliverableInfo.status)}</Table.Td>
      <Table.Td>{actionButton(row.deliverableInfo.contractId)}</Table.Td>
    </Table.Tr>
  );
};

function PaginatedTableSort({ rowData }: { rowData: RowData[] }) {
  // const [sortedData, setSortedData] = useState(rowData);
  const [chunkedSortedData, setChunkedSortedData] = useState<RowData[][]>([]);
  const [sortBy, setSortBy] = useState<keyof RowData | null>("completionDate");
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  // The current page of the paginated table (1-indexed)
  const [currentPage, setCurrentPage] = useState<number>(1);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setChunkedSortedData(chunk(sortData(rowData, { sortBy: field, reversed }), PAGE_SIZE));
    // Whenever resorting we reset the pagination to the first page
    setCurrentPage(1);
  };

  useEffect(() => {
    // Chunk the data whenever it has changed. The most likely source of a data
    // change is from changing filters.
    setChunkedSortedData(
      chunk(sortData(rowData, { sortBy, reversed: reverseSortDirection }), PAGE_SIZE),
    );
    // When new rowData appears (because of filtering), we want to reset to
    // the first page
    setCurrentPage(1);
  }, [rowData]);

  return (
    <Stack>
      <Table.ScrollContainer minWidth={500}>
        <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="auto" stickyHeader>
          <Table.Tbody>
            <Table.Tr>
              <Th
                sorted={sortBy === "creatorName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("creatorName")}>
                Creator
              </Th>
              <Th
                sorted={sortBy === "campaignName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("campaignName")}>
                Campaign
              </Th>
              <Th
                sorted={sortBy === "adGroupName"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("adGroupName")}>
                Ad Group
              </Th>
              <Th
                sorted={sortBy === "format"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("format")}>
                Format
              </Th>
              <Th
                sorted={sortBy === "completionDate"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("completionDate")}>
                Completion Date
              </Th>
              <Th
                sorted={sortBy === "postContent"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("postContent")}>
                Post Content
              </Th>
              <Th
                sorted={sortBy === "usageRightsState"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("usageRightsState")}>
                Usage Rights
              </Th>
              <Th
                sorted={sortBy === "cost"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("cost")}>
                Implied Cost
              </Th>
              <Th
                sorted={sortBy === "status"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("status")}>
                Status
              </Th>
              <Table.Th>
                <Text fw={500} fz="sm">
                  Action
                </Text>
              </Table.Th>
            </Table.Tr>
          </Table.Tbody>
          <Table.Tbody>
            {(chunkedSortedData.length > 0 ? chunkedSortedData[currentPage - 1] : []).map(
              (row: RowData) => (
                <PaginatedTableSortRow key={row.deliverableInfo.deliverableHash} row={row} />
              ),
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <Pagination
        ml={10}
        mb={10}
        total={chunkedSortedData.length}
        value={currentPage}
        onChange={setCurrentPage}
        hideWithOnePage
      />
    </Stack>
  );
}

const DeliverablesTab = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rawDeliverables, setRawDeliverables] = useState<DeliverableInfo[]>([]);
  // This is with all filters applied including cards.
  const [filteredDeliverables, setFilteredDeliverables] = useState<DeliverableInfo[]>([]);
  // This is with all filters applied excluding cards.
  const [filteredDeliverablesForCards, setFilteredDeliverablesForCards] = useState<
    DeliverableInfo[]
  >([]);
  // A version of the filtered contracts suitable for use with the table.
  const [rowData, setRowData] = useState<RowData[]>([]);

  // A list of filter values for some of the filters
  const [availableCampaignNames, setAvailableCampaignNames] = useState<string[]>([]);
  const [availableAdGroupNames, setAvailableAdGroupNames] = useState<string[]>([]);
  // const [availablePlatforms, setAvailablePlatforms] = useState<string[]>([]);
  const [availableFormats, setAvailableFormats] = useState<string[]>([]);
  // TODO(chris): Fix this to properly update when we learn of the maximum cost
  // The issue here is we don't want to update the displayed value of the
  // filter if it has been adjusted by the user.
  const [maximumImputedCost, setMaximumImputedCost] = useState<number>(10000);

  // Selected values for filters
  const [selectedCampaign, setSelectedCampaign] = useState<string>("All");
  const [selectedAdGroup, setSelectedAdGroup] = useState<string>("All");
  // const [selectedPlatform, setSelectedPlatform] = useState<string>("All");
  const [selectedFormat, setSelectedFormat] = useState<string>("All");
  const [selectedUsageRights, setSelectedUsageRights] = useState<string>("All");

  // Some filters have a range, for which we only want to make an update
  // when the full range is given.
  // Local versions absorb the partial updates, and we only trigger a change
  // the global version when both ends of the range have updated.
  const [localSelectedCompletionDateRange, setLocalSelectedCompletionDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [selectedCompletionDateRange, setSelectedCompletionDateRange] = useState<
    [Date | null, Date | null]
  >([null, null]);
  const [localSelectedCostRange, setLocalSelectedCostRange] = useState<[number, number]>([
    0,
    maximumImputedCost,
  ]);
  const [selectedCostRange, setSelectedCostRange] = useState<[number | null, number | null]>([
    null,
    null,
  ]);

  // The summary card that's selected (if any)
  const [selectedSummaryCard, setSelectedSummaryCard] = useState<string>(null);

  // The display state of the cost select modal
  const [costSelectModal, { open: openCostSelectModal, close: closeCostSelectModal }] =
    useDisclosure(false);

  // Initial load of the page, fetch the data
  useEffect(() => {
    fetchDeliverablesForBrand()
      .then((response) => {
        const { success, deliverables } = response;
        if (success) {
          setRawDeliverables(deliverables);

          // Capture the available filter values
          const { campaignNames, adGroupNames, formats, maximumCost } =
            extractFilterValues(deliverables);
          setAvailableCampaignNames(campaignNames.filter((campaignName) => campaignName !== "All"));
          setAvailableAdGroupNames(adGroupNames.filter((adGroupName) => adGroupName !== "All"));
          setAvailableFormats(formats);
          setMaximumImputedCost(Math.max((maximumCost || 0) / 100, 100));
        } else {
          setRawDeliverables([]);
        }
      })
      .catch(() => {
        setRawDeliverables([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Update the filtered deliverables whenever the filters change
  // Respond to changes in the filter criteria (and the raw data)
  useEffect(() => {
    const filteredForTable = [] as DeliverableInfo[];
    const filteredForCards = [] as DeliverableInfo[];

    rawDeliverables.forEach((deliverableInfo) => {
      let skip = false;
      if (
        selectedCampaign !== undefined &&
        selectedCampaign !== "All" &&
        deliverableInfo.campaignName !== selectedCampaign
      ) {
        skip = true;
      }
      if (
        !skip &&
        selectedAdGroup !== undefined &&
        selectedAdGroup !== "All" &&
        deliverableInfo.adGroupName !== selectedAdGroup
      ) {
        skip = true;
      }
      if (
        !skip &&
        selectedFormat !== undefined &&
        selectedFormat !== "All" &&
        makeDeliverableFormatName(deliverableInfo.format) !== selectedFormat
      ) {
        skip = true;
      }
      // Check completion date (which is either the completion date or the live date)
      if (
        !skip &&
        selectedCompletionDateRange !== undefined &&
        !selectedCompletionDateRange.includes(null)
      ) {
        const [startDate, endDate] = selectedCompletionDateRange;
        const dateUsed = deliverableInfo.liveDate;
        if (!dateUsed) {
          skip = true;
        } else {
          const verifiedDateUsed = fromISODateString(dateUsed.toString());
          skip = verifiedDateUsed < startDate || verifiedDateUsed > endDate;
        }
      }
      // Check the usage rights
      if (!skip && selectedUsageRights !== undefined && selectedUsageRights !== "All") {
        switch (selectedUsageRights) {
          case "Requested":
            skip =
              deliverableInfo.usageRightsState !== "valid" &&
              deliverableInfo.usageRightsState !== "expired";
            break;
          case "Not Requested":
            skip = deliverableInfo.usageRightsState !== "not_required";
            break;
          default:
            break;
        }
      }
      // Check the cost filter range
      if (!skip && selectedCostRange && !selectedCostRange.includes(null)) {
        const [minCost, maxCost] = selectedCostRange;
        const costInMajorUnits = deliverableInfo.imputedCost / 100;
        skip = costInMajorUnits < minCost || costInMajorUnits > maxCost;
      }
      // Skip for cards does not include the cards filter (or else all other boxes will zero)
      const skipForCards = skip;
      if (!skip && selectedSummaryCard !== undefined && selectedSummaryCard !== null) {
        switch (selectedSummaryCard) {
          case "notStarted":
            skip = deliverableInfo.status !== "NOT_STARTED";
            break;
          case "conceptReview":
            skip = !new Set([
              "WAITING_FOR_SCRIPT",
              "SCRIPT_SUBMITTED",
              "SCRIPT_REVISIONS_REQUESTED",
            ]).has(deliverableInfo.status);
            break;
          case "draftReview":
            skip = !new Set([
              "WAITING_FOR_VIDEO_DRAFT",
              "VIDEO_DRAFT_SUBMITTED",
              "VIDEO_DRAFT_REVISIONS_REQUESTED",
              "WAITING_FOR_LIVE_CONTENT",
            ]).has(deliverableInfo.status);
            break;
          case "live":
            skip = !new Set(["LIVE_CONTENT_SUBMITTED", "LIVE_CONTENT_REVISIONS_REQUESTED"]).has(
              deliverableInfo.status,
            );
            break;
          case "verified":
            skip = deliverableInfo.status !== "COMPLETE";
            break;
          default:
            break;
        }
      }
      if (!skip) {
        filteredForTable.push(deliverableInfo);
      }
      if (!skipForCards) {
        filteredForCards.push(deliverableInfo);
      }
    });
    setFilteredDeliverables(filteredForTable);
    setFilteredDeliverablesForCards(filteredForCards);
  }, [
    selectedCampaign,
    selectedAdGroup,
    selectedFormat,
    selectedCompletionDateRange,
    selectedUsageRights,
    selectedCostRange,
    selectedSummaryCard,
    rawDeliverables,
  ]);

  // Produce row data from the filtered deliverables, triggered whenever the
  // filtered contracts are changed.
  useEffect(() => {
    const newRowData: RowData[] = [];
    filteredDeliverables.forEach((deliverableInfo) => {
      let creatorName = `${deliverableInfo.firstName} ${deliverableInfo.lastName}`;
      if (creatorName.trim().length === 0) {
        creatorName = getExternalDisplayUsername(deliverableInfo.socialHandles);
      }
      const singleRow: RowData = {
        creatorName,
        campaignName: deliverableInfo.campaignName,
        adGroupName: deliverableInfo.adGroupName,
        completionDate: deliverableInfo.liveDate ? deliverableInfo.liveDate : new Date(),
        cost: deliverableInfo.imputedCost,
        status: deliverableInfo.status,
        platform: deliverableInfo.platform,
        format: deliverableInfo.format,
        usageRightsState: deliverableInfo.usageRightsState,
        // TODO(chris): consider including platform NONE here, as it seems very unlikely any
        // platformless content will be posted.
        postContent: deliverableInfo.format !== "UGC",
        deliverableInfo,
      };
      newRowData.push(singleRow);
    });
    setRowData(newRowData);
  }, [filteredDeliverables]);

  return (
    <>
      <Space h="lg" />
      <Stack gap="xs">
        <Group justify="space-between">
          <Title order={4}>Filter by:</Title>
          <Button
            leftSection={<IconRotateClockwise color="black" />}
            variant="filled"
            color="#DEE2E6"
            onClick={() => {
              // Return all filter values to their default states (including status cards)
              setSelectedCampaign("All");
              setSelectedAdGroup("All");
              setSelectedFormat("All");
              setLocalSelectedCompletionDateRange([null, null]);
              setSelectedCompletionDateRange([null, null]);
              setLocalSelectedCostRange([0, maximumImputedCost]);
              setSelectedUsageRights("All");
              setSelectedCostRange([null, null]);
              setSelectedSummaryCard(null);
            }}>
            <Title order={6} c="black">
              Reset Filters
            </Title>
          </Button>
        </Group>
        <Group grow>
          <Select
            label="Campaign"
            value={selectedCampaign}
            data={["All", ...availableCampaignNames]}
            onChange={(newValue) => {
              setSelectedCampaign(newValue);
            }}
          />
          <Select
            label="Ad Group"
            value={selectedAdGroup}
            data={["All", ...availableAdGroupNames]}
            onChange={(newValue) => {
              setSelectedAdGroup(newValue);
            }}
          />
          <Select
            label="Format"
            value={selectedFormat}
            data={["All", ...availableFormats]}
            onChange={(newValue) => {
              setSelectedFormat(newValue);
            }}
          />
        </Group>
        <Group grow>
          <DatePickerInput
            type="range"
            label="Completion Date"
            placeholder="All"
            valueFormat="MM/DD/YYYY"
            value={localSelectedCompletionDateRange}
            onChange={(newState) => {
              // Always update the local value for the picker
              setLocalSelectedCompletionDateRange(newState);
              if (newState.includes(null)) {
                // We only care about the selection of a full range.
                // Half-selecting a range is not actionable, so we just track it locally.
                return;
              }
              setSelectedCompletionDateRange(newState);
            }}
            clearable
          />
          <Select
            label="Usage Rights"
            value={selectedUsageRights}
            data={["All", "Requested", "Not Requested"]}
            onChange={(newValue) => {
              setSelectedUsageRights(newValue);
            }}
          />
          <>
            <Modal
              opened={costSelectModal}
              onClose={closeCostSelectModal}
              closeOnClickOutside={false}
              withCloseButton={false}>
              <Stack>
                <Title order={5}>Select price range</Title>
                <RangeFilter
                  rangeValue={localSelectedCostRange}
                  setRangeValue={setLocalSelectedCostRange}
                  min={0}
                  max={maximumImputedCost}
                  stepSize={10}
                  marks={undefined}
                  hideNumberInput={false}
                  showsSuffix={false}
                />
                <Group>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedCostRange(localSelectedCostRange);
                      closeCostSelectModal();
                    }}>
                    Accept
                  </Button>
                  <Button
                    color="red"
                    onClick={() => {
                      setLocalSelectedCostRange(
                        selectedCostRange.includes(null)
                          ? [0, maximumImputedCost]
                          : selectedCostRange,
                      );
                      closeCostSelectModal();
                    }}>
                    Cancel
                  </Button>
                  <Button
                    variant="outline"
                    color="gray"
                    onClick={() => {
                      setLocalSelectedCostRange([0, maximumImputedCost]);
                      setSelectedCostRange([null, null]);
                      closeCostSelectModal();
                    }}>
                    Clear
                  </Button>
                </Group>
              </Stack>
            </Modal>
            <Select
              label="Cost"
              placeholder={
                selectedCostRange.includes(null)
                  ? "Any"
                  : `${new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(selectedCostRange[0] || 0)} - ${new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                    }).format(selectedCostRange[1] || 0)}`
              }
              onClick={openCostSelectModal}
            />
          </>
        </Group>
        <Paper radius="lg" p={10}>
          <Stack>
            <Title order={2} p={10}>
              Status Summary
            </Title>
            <SummaryCards
              isLoading={isLoading}
              // Card need data that do not include the cards filter
              deliverables={filteredDeliverablesForCards}
              selected={selectedSummaryCard}
              setSelected={setSelectedSummaryCard}
            />
          </Stack>
        </Paper>
        <Paper radius="lg">
          <Stack>
            <Title order={2} p={10}>
              Deliverables
            </Title>
            <PaginatedTableSort rowData={rowData} />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default DeliverablesTab;
