import React, { useEffect, useState } from "react";

import {
  ActionIcon,
  Anchor,
  Badge,
  Checkbox,
  Group,
  Menu,
  rem,
  Stack,
  Switch,
  Table,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { IconListCheck, IconListSearch, IconMenu2, IconSettings } from "@tabler/icons-react";

import {
  getCreatorSetSourcingDetails,
  updateOverrideSourcingPriority,
  updateSourcingExhausted,
} from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { CreatorSet, CreatorSetStatus } from "components/discovery/Datamodels";
import { BrandStatus } from "models/Brand";
import { formatCurrencyAmount, getAbbreviatedNumber } from "utils/AnalyticsUtils";
import { fromISODateString } from "utils/DateUtils";

const CreatorSetOverflowMenu = ({ creatorSet }: { creatorSet: CreatorSet }) => {
  return (
    <Menu width={160} withinPortal={false} trigger="click-hover" loop={false} trapFocus={false}>
      <Menu.Target>
        <ActionIcon variant="subtle" color="gray" component="button" size="sm">
          <IconMenu2 style={{ width: rem(20), height: rem(20) }} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={
            <IconListCheck style={{ width: rem(16), height: rem(16) }} color="green" stroke={1.5} />
          }
          component="a"
          target="_blank"
          color="black"
          href={`/admin/labeling?creatorSetId=${creatorSet.id}`}>
          Labeling Feed
        </Menu.Item>
        <Menu.Item
          leftSection={
            <IconListSearch style={{ width: rem(16), height: rem(16) }} color="red" stroke={1.5} />
          }
          component="a"
          target="_blank"
          color="black"
          href={`/labeling-search?brandId=${creatorSet.brand.id}&creatorSetId=${creatorSet.id}&pageNum=1&pageSize=1000&useRelevanceSort=true&datasetFilterMap=eJyrrgUAAXUA%2BQ%3D%3D`}>
          Debug Feed
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const getBudgetUtilizationColor = (utilization: number) => {
  if (utilization < 20.0) {
    return "red";
  } else if (utilization < 40.0) {
    return "orange";
  } else if (utilization < 60.0) {
    return "yellow";
  }
  return "green";
};

const getBudgetCell = (budget: number, spend: number) => {
  if (budget > 0) {
    const utilization = (100 * spend) / budget;
    return (
      <>
        {formatCurrencyAmount(spend)} / {formatCurrencyAmount(budget)} (
        <Text span fw={700} inherit c={getBudgetUtilizationColor(utilization)}>
          {getAbbreviatedNumber(utilization, 2)}%
        </Text>
        )
      </>
    );
  }
  return "N/A";
};

const getOpsApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.2) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.1) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.05) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getOpsApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_last_1000)} |{" "}
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_30d)} |{" "}
      {getOpsApprovalRateText(sourcingDetails?.ops_approval_rate_90d)}
    </>
  );
};

const getBrandApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.5) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.25) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.1) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getBrandApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getBrandApprovalRateText(sourcingDetails?.brand_approval_rate_30d)} |{" "}
      {getBrandApprovalRateText(sourcingDetails?.brand_approval_rate_90d)}
    </>
  );
};

const getInternalApprovalRateText = (rate: number) => {
  if (!rate) {
    return "N/A";
  } else if (rate > 0.9) {
    return (
      <Text inherit span c="green">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.75) {
    return (
      <Text inherit span c="yellow">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  } else if (rate > 0.5) {
    return (
      <Text inherit span c="orange">
        {getAbbreviatedNumber(rate * 100, 2)}%
      </Text>
    );
  }
  return (
    <Text inherit span c="red">
      {getAbbreviatedNumber(rate * 100, 2)}%
    </Text>
  );
};

const getInternalApprovalRateCell = (sourcingDetails: any) => {
  return (
    <>
      {getInternalApprovalRateText(sourcingDetails?.internal_approval_rate_30d)} |{" "}
      {getInternalApprovalRateText(sourcingDetails?.internal_approval_rate_90d)}
    </>
  );
};

const getBrandApprovedCell = (sourcingDetails: any) => {
  if (sourcingDetails?.approved > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.approved}
      </Text>
    );
  } else if (sourcingDetails?.approved > 10) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.approved}
      </Text>
    );
  } else if (sourcingDetails?.approved > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.approved}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

const getNeedsBrandReviewCell = (sourcingDetails: any) => {
  if (sourcingDetails?.need_brand_review > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  } else if (sourcingDetails?.need_brand_review > 50) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  } else if (sourcingDetails?.need_brand_review > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.need_brand_review}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

const getNeedsInternalReviewCell = (sourcingDetails: any) => {
  if (sourcingDetails?.need_internal_review > 100) {
    return (
      <Text fw={700} inherit span c="green">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  } else if (sourcingDetails?.need_internal_review > 50) {
    return (
      <Text fw={700} inherit span c="yellow">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  } else if (sourcingDetails?.need_internal_review > 0) {
    return (
      <Text fw={700} inherit span c="orange">
        {sourcingDetails?.need_internal_review}
      </Text>
    );
  }
  return (
    <Text fw={700} inherit span c="red">
      0
    </Text>
  );
};

export const CreatorSetSourcingRow = ({
  creatorSet,
  priority,
  showDebug,
}: {
  creatorSet: CreatorSet;
  priority: number;
  showDebug: boolean;
}) => {
  const [sourcingDetails, setSourcingDetails] = useState(null);

  const [overrideSourcingPriority, setOverrideSourcingPriority] = useState(false);
  const [exhausted, setExhausted] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    if (creatorSet?.id) {
      getCreatorSetSourcingDetails(creatorSet.id, abortController).then((resp) => {
        if (resp) {
          setSourcingDetails(resp);
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [creatorSet]);

  useEffect(() => {
    setOverrideSourcingPriority(sourcingDetails?.override_sourcing_priority);
    setExhausted(
      sourcingDetails?.last_exhausted_date !== null &&
        sourcingDetails?.last_exhausted_date !== undefined,
    );
  }, [sourcingDetails]);

  return (
    <Table.Tr>
      <Table.Td>
        <Anchor href={`/admin/brands?brandId=${creatorSet.brand.id}`} target="_blank">
          {creatorSet.brand.brand_name}
        </Anchor>
      </Table.Td>
      <Table.Td>
        <Group wrap="nowrap">
          <Anchor href={`/admin/creator_sets?creatorSetId=${creatorSet.id}`} target="_blank">
            {creatorSet.name}
          </Anchor>
          <CreatorSetOverflowMenu creatorSet={creatorSet} />
        </Group>
      </Table.Td>
      <Table.Td>
        {creatorSet.ad_groups?.length > 0 &&
          creatorSet.ad_groups.map((adGroup) => (
            <Tooltip
              key={`creative-brief-adgroup=${adGroup.id}-tooltip`}
              label={`(${adGroup.campaign_name}) ${adGroup.name}`}>
              <Badge variant="light" key={`creator-set-adgroup=${adGroup.id}`}>
                {adGroup.name}
              </Badge>
            </Tooltip>
          ))}
      </Table.Td>
      <Table.Td>
        <Group wrap="nowrap">
          <Text inherit span>
            {priority}
          </Text>
          <Tooltip label="Override priority">
            <Checkbox
              checked={overrideSourcingPriority}
              onChange={() =>
                updateOverrideSourcingPriority(
                  creatorSet.id,
                  !overrideSourcingPriority,
                  setOverrideSourcingPriority,
                )
              }
            />
          </Tooltip>
        </Group>
      </Table.Td>
      <Table.Td>
        <Group wrap="nowrap">
          <Text inherit span>
            {fromISODateString(sourcingDetails?.last_exhausted_date)?.toDateString()}
          </Text>
          <Tooltip label="Mark as exhausted">
            <Checkbox
              checked={exhausted}
              onChange={() => {
                updateSourcingExhausted(
                  creatorSet.id,
                  !exhausted,
                  sourcingDetails,
                  setSourcingDetails,
                );
              }}
            />
          </Tooltip>
        </Group>
      </Table.Td>
      <Table.Td>
        {fromISODateString(sourcingDetails?.enable_recommendations_timestamp)?.toDateString()}
      </Table.Td>
      <Table.Td>
        {getBudgetCell(sourcingDetails?.this_month_budget, sourcingDetails?.this_month_spend)}
      </Table.Td>
      <Table.Td>
        {getBudgetCell(sourcingDetails?.next_month_budget, sourcingDetails?.next_month_spend)}
      </Table.Td>
      <Table.Td>{getBrandApprovedCell(sourcingDetails)}</Table.Td>
      <Table.Td hidden={!showDebug}>{getBrandApprovalRateCell(sourcingDetails)}</Table.Td>
      <Table.Td>{getNeedsBrandReviewCell(sourcingDetails)}</Table.Td>
      <Table.Td hidden={!showDebug}>{getInternalApprovalRateCell(sourcingDetails)}</Table.Td>
      <Table.Td hidden={!showDebug}>{getNeedsInternalReviewCell(sourcingDetails)}</Table.Td>
      <Table.Td hidden={!showDebug}>{sourcingDetails?.need_enrichment}</Table.Td>
      <Table.Td hidden={!showDebug}>{sourcingDetails?.missing_email}</Table.Td>
      <Table.Td>{sourcingDetails?.ops_approved}</Table.Td>
      <Table.Td>{getOpsApprovalRateCell(sourcingDetails)}</Table.Td>
      <Table.Td hidden={!showDebug}>{sourcingDetails?.num_remaining_candidates}</Table.Td>
    </Table.Tr>
  );
};

export const CreatorSetSourcingTable = ({
  creatorSets,
  priority,
  showDebug,
}: {
  creatorSets: CreatorSet[];
  priority: Record<number, number>;
  showDebug: boolean;
}) => {
  return (
    <Table.ScrollContainer minWidth={600} type="native">
      <Table
        horizontalSpacing="md"
        verticalSpacing="md"
        highlightOnHover
        withTableBorder
        stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Brand</Table.Th>
            <Table.Th>Creator Set</Table.Th>
            <Table.Th>Campaigns</Table.Th>
            <Table.Th>Priority (Override Priority)</Table.Th>
            <Table.Th>Last Exhausted Date</Table.Th>
            <Table.Th>Recommendations Enabled Date</Table.Th>
            <Table.Th>This Month Budget</Table.Th>
            <Table.Th>Next Month Budget</Table.Th>
            <Table.Th>Lifetime Brand Approved</Table.Th>
            <Table.Th hidden={!showDebug}>Brand Approval Rate [30d | 90d]</Table.Th>
            <Table.Th>Needs Brand Review</Table.Th>
            <Table.Th hidden={!showDebug}>Internal Approval Rate [30d | 90d]</Table.Th>
            <Table.Th hidden={!showDebug}>Needs Internal Review</Table.Th>
            <Table.Th hidden={!showDebug}>Needs Email Enrichment</Table.Th>
            <Table.Th hidden={!showDebug}>Missing Email</Table.Th>
            <Table.Th>Lifetime Ops Approved</Table.Th>
            <Table.Th>Ops Approval Rate [Last 1000 | 30d | 90d]</Table.Th>
            <Table.Th hidden={!showDebug}>Remaining Candidates</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {creatorSets.map((creatorSet) => (
            <CreatorSetSourcingRow
              key={creatorSet.id}
              creatorSet={creatorSet}
              priority={priority[creatorSet.id]}
              showDebug={showDebug}
            />
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export const CreatorSetSourcingSummary = ({ priority }: { priority: Record<number, number> }) => {
  const { creatorSets } = useAdminAppContext();
  const [growCreatorSets, setGrowCreatorSets] = useState([]);
  const [showDebug, { toggle: toggleDebug }] = useDisclosure(false);

  useEffect(() => {
    if (creatorSets) {
      // Filter by creator set id in priority
      const filteredCreatorSets = creatorSets.filter(
        (creatorSet) => priority[creatorSet.id] !== undefined,
      );
      filteredCreatorSets.sort((a, b) => {
        const aPriority = priority[a.id];
        const bPriority = priority[b.id];
        return aPriority - bPriority;
      });
      setGrowCreatorSets(filteredCreatorSets);
    }
  }, [creatorSets, priority]);

  return (
    <Stack>
      <Switch label="Show Debug" checked={showDebug} onChange={toggleDebug} />
      <CreatorSetSourcingTable
        creatorSets={growCreatorSets}
        priority={priority}
        showDebug={showDebug}
      />
    </Stack>
  );
};

export default CreatorSetSourcingSummary;
