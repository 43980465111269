import React, { useState } from "react";

import { Group, Pill, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { SupportedFormat, SupportedPlatform } from "models/Common";

import CollapsibleCard from "components/contracts/common/CollapsibleCard";
import { UsageRightsRequestStatus } from "components/contracts/common/Common";
import DeliverableCardHeader from "components/contracts/common/DeliverableCardHeader";
import { UsageRightsSubmissionContent } from "components/contracts/deliverables/UsageRightsSubmissionContent";
import { UsageRightsRequestDetails } from "components/contracts/models/UsageRightsRequestDetails";

import { toLongDateString } from "utils/DateUtils";

const StatusPill = ({
  creatorLastUpdateTime,
  status,
  disputeDate,
}: {
  creatorLastUpdateTime?: Date;
  status: UsageRightsRequestStatus;
  disputeDate: Date;
}) => {
  const brandActionRequired =
    creatorLastUpdateTime && (!disputeDate || disputeDate < creatorLastUpdateTime);
  const revisionsRequested =
    disputeDate && (!creatorLastUpdateTime || disputeDate > creatorLastUpdateTime);

  let text = "";
  let statusBackgroundColor = "";
  let textColor = "white";

  if (status === UsageRightsRequestStatus.REJECTED) {
    text = "REQUEST DENIED";
    statusBackgroundColor = "var(--mantine-color-gray-1";
    textColor = "gray.6";
  } else if (status === UsageRightsRequestStatus.ACCEPTED) {
    text = "APPROVED";
    statusBackgroundColor = "#D0EBFF";
    textColor = "blue.6";
  } else if (revisionsRequested) {
    text = "REVISIONS REQUESTED";
    statusBackgroundColor = "#FFF5F5";
    textColor = "red.6";
  } else if (brandActionRequired) {
    text = "WAITING FOR BRAND";
    statusBackgroundColor = "#FFF9DB";
    textColor = "yellow.6";
  } else {
    text = "UPLOAD CONTENT";
    statusBackgroundColor = "#FFF5F5";
    textColor = "red.6";
  }

  return (
    text.length > 0 && (
      <Pill
        c={textColor}
        styles={{
          root: {
            backgroundColor: statusBackgroundColor,
          },
        }}>
        <Text inherit size="10" fw={600}>
          {text}
        </Text>
      </Pill>
    )
  );
};

const CardHeader = ({
  platform,
  format,
  dueDate,
  usageRightsStatus,
  disputeDate,
  creatorLastUpdateTime,
}: {
  platform: SupportedPlatform;
  format: SupportedFormat;
  dueDate: Date;
  usageRightsStatus: UsageRightsRequestStatus;
  disputeDate?: Date;
  creatorLastUpdateTime?: Date;
}) => {
  return (
    <Group justify="space-between" py="0.2rem">
      <DeliverableCardHeader
        platform={platform}
        format={format}
        subHeading={
          <Text size="sm" c="dimmed">
            by {toLongDateString(dueDate)}
          </Text>
        }
      />
      <StatusPill
        status={usageRightsStatus}
        disputeDate={disputeDate}
        creatorLastUpdateTime={creatorLastUpdateTime}
      />
    </Group>
  );
};

export const UsageRightsCard = ({
  usageRightsRequest,
  contractId,
  platform,
  format,
  brandName,
}: {
  usageRightsRequest: UsageRightsRequestDetails;
  contractId: string;
  platform: SupportedPlatform;
  format: SupportedFormat;
  brandName: string;
}) => {
  const [creatorLastUpdateTime, setCreatorLastUpdateTime] = useState<Date | null>(
    usageRightsRequest.creatorLastUpdateTime,
  );

  const defaultOpen = usageRightsRequest.status !== UsageRightsRequestStatus.REJECTED;
  const [opened, { toggle }] = useDisclosure(defaultOpen);

  return (
    <CollapsibleCard
      isOpen={opened}
      header={
        <CardHeader
          platform={platform}
          format={format}
          dueDate={usageRightsRequest.dueDate}
          usageRightsStatus={usageRightsRequest.status}
          disputeDate={usageRightsRequest.disputeReason && usageRightsRequest.reviewDate}
          creatorLastUpdateTime={creatorLastUpdateTime}
        />
      }
      content={
        <UsageRightsSubmissionContent
          contractId={contractId}
          brandName={brandName}
          usageRightsRequest={usageRightsRequest}
          setCreatorLastUpdateTime={setCreatorLastUpdateTime}
        />
      }
      disableHoverStyle
      customOpened={opened}
      customToggle={toggle}
    />
  );
};

export default UsageRightsCard;
