import React from "react";
import { Card, Group, Stack, Text } from "@mantine/core";

import { ProductAccess, TaskStage } from "components/contracts/tasks/models/Common";

import ProductAccessContent from "components/contracts/tasks/content/ProductAccessContent";

import ActionBadge from "components/contracts/tasks/common/ActionBadge";
import DueDateBadge from "components/contracts/tasks/common/DueDateBadge";

function TaskCardHeader({ deadline }: { deadline: Date }) {
  return (
    <Group justify="space-between">
      <Group gap="xs">
        <Text fw="600" size="lg">
          Access Details
        </Text>
      </Group>
      <Group gap="xs">
        <ActionBadge taskType={TaskStage.PRODUCT_ACCESS} />
        <DueDateBadge deadline={deadline} />
      </Group>
    </Group>
  );
}

export default function ProductAccessCard({
  taskId,
  displayName,
  productAccess,
  deadline,
  handleCompleteTask,
}: {
  taskId: string;
  displayName: string;
  productAccess: ProductAccess;
  deadline: Date;
  handleCompleteTask: () => void;
}) {
  return (
    <Card radius="md" mt="sm" withBorder>
      <Stack gap="xs">
        <TaskCardHeader deadline={deadline} />
        <ProductAccessContent
          taskId={taskId}
          displayName={displayName}
          productAccess={productAccess}
          handleCompleteTask={handleCompleteTask}
        />
      </Stack>
    </Card>
  );
}
