import React, { useState } from "react";

import { Button, Card, Group, Stack, Text, TextInput } from "@mantine/core";
import { IconMail } from "@tabler/icons-react";

import { showFailureNotification } from "components/common/Notifications";
import { brandUpdateEmail } from "campaigns/api/brandUpdateEmail";
import { invalidEmail } from "utils/ValidationUtils";

export const UpdateEmail = ({
  opportunityId,
  currentEmail,
  closeModal,
  updateSuccessCallback,
}: {
  opportunityId: string;
  currentEmail: string;
  closeModal: () => void;
  updateSuccessCallback: () => void;
}) => {
  const [email, setEmail] = useState<string>(currentEmail || "");

  return (
    <Stack>
      <Text>
        We couldn&apos;t reach the email address for this creator. You can add in a new email
        address for the creator if you&apos;ve got one, and we&apos;ll try contacting them there.
      </Text>
      <Card color="var(--mantine-color-gray-0)" withBorder>
        <TextInput
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value)}
          label="Email"
          leftSection={<IconMail />}
        />
      </Card>
      <Group justify="flex-end">
        <Button
          variant="light"
          color="blue.6"
          disabled={!email}
          onClick={() => {
            if (!email) {
              showFailureNotification({ message: "Email must not be empty" });
              return;
            }
            if (invalidEmail(email)) {
              showFailureNotification({
                message: `${email} does not appear to be a valid email, please update and try again.`,
              });
              return;
            }

            brandUpdateEmail(opportunityId, email, null)
              .then((response) => {
                if (response.success) {
                  updateSuccessCallback();
                  closeModal();
                } else {
                  const message = response.message || response.result?.message;
                  showFailureNotification({
                    message: message || response.text || "Server error.",
                    stayOpen: true,
                  });
                }
              })
              .catch(() => {
                showFailureNotification({
                  message: "Server error.",
                  stayOpen: true,
                });
              });
          }}>
          Save and Send Email
        </Button>
      </Group>
    </Stack>
  );
};

export default UpdateEmail;
