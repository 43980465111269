import React, { useState } from "react";

import { Button, Flex, Stack, Text, TextInput } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";

import {
  addReferralLinkToDeliverable,
  addPromoCodeToDeliverable,
} from "components/contracts/tasks/api/Api";

import { SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES, SupportedPlatform } from "models/Common";

import { TaskStatus } from "components/contracts/tasks/models/Common";
import { showFailureNotification, showSuccessNotification } from "components/common/Notifications";

function isValidUrl(url: string) {
  const regex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(url);
}

function isValidPromoCode(promoCode: string) {
  return promoCode.length > 0 && promoCode.length <= 255;
}

function ReferralLinkInput({
  taskId,
  platform,
  redirectUrl,
  status,
  handleCompleteTask,
}: {
  taskId: string;
  platform: SupportedPlatform;
  redirectUrl: string;
  status: TaskStatus;
  handleCompleteTask: () => void;
}) {
  if (status === TaskStatus.NONE) {
    return null;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(redirectUrl);
  const [acceptInput, setAcceptInput] = useState<boolean>(status === TaskStatus.PENDING);

  const handleAddReferralLink = () => {
    setLoading(true);
    addReferralLinkToDeliverable({ taskId, url })
      .then((response) => {
        const { success, isTaskComplete } = response;
        if (success) {
          setAcceptInput(false);
          if (isTaskComplete) {
            handleCompleteTask();
          }
        } else {
          showFailureNotification({
            title: "Failed to add Referral Link",
            message: "Please try again.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex gap="xs" align="flex-start" w="100%" px="xs">
      <TextInput
        leftSection={acceptInput ? null : <IconCheck size="1rem" />}
        label={
          <Text size="sm" fw="500" mb={4}>
            {`${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} Referral Link`}
          </Text>
        }
        value={url}
        onChange={(event) => setUrl(event.currentTarget.value)}
        error={url && !isValidUrl(url) ? "Please enter a valid URL." : null}
        size="xs"
        placeholder="https://www.example.com"
        w="100%"
        disabled={!acceptInput}
      />
      <Button
        mt={25}
        size="xs"
        w={80}
        disabled={!url || !isValidUrl(url)}
        loading={loading}
        onClick={acceptInput ? handleAddReferralLink : () => setAcceptInput(true)}
        color={acceptInput ? "blue" : "gray"}>
        {acceptInput ? "Submit" : "Edit"}
      </Button>
    </Flex>
  );
}

function PromoCodeInput({
  taskId,
  platform,
  promoCode,
  status,
  handleCompleteTask,
}: {
  taskId: string;
  platform: SupportedPlatform;
  promoCode: string;
  status: TaskStatus;
  handleCompleteTask: () => void;
}) {
  if (status === TaskStatus.NONE) {
    return null;
  }

  const [loading, setLoading] = useState<boolean>(false);
  const [promoCodeInput, setPromoCodeInput] = useState<string>(promoCode);
  const [acceptInput, setAcceptInput] = useState<boolean>(status === TaskStatus.PENDING);

  const handleAddPromoCode = () => {
    setLoading(true);
    addPromoCodeToDeliverable({ taskId, promoCode: promoCodeInput })
      .then((response) => {
        const { success, isTaskComplete } = response;
        if (success) {
          setAcceptInput(false);
          if (isTaskComplete) {
            handleCompleteTask();
          }
        } else {
          showFailureNotification({
            title: "Failed to add Promo Code",
            message: "Please try again.",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Flex gap="xs" align="flex-start" w="100%" px="xs">
      <TextInput
        leftSection={acceptInput ? null : <IconCheck size="1rem" />}
        label={
          <Text size="sm" fw="500" mb={4}>
            {`${SUPPORTED_PLATFORMS_TO_DISPLAY_NAMES[platform]} Promo Code`}
          </Text>
        }
        value={promoCodeInput}
        onChange={(event) => setPromoCodeInput(event.currentTarget.value)}
        error={
          promoCodeInput && !isValidPromoCode(promoCodeInput) ? "Promo Code is too long." : null
        }
        size="xs"
        placeholder="EXAMPLE10"
        w="100%"
        disabled={!acceptInput}
      />
      <Button
        mt={25}
        size="xs"
        w={80}
        disabled={!promoCodeInput || !isValidPromoCode(promoCodeInput)}
        loading={loading}
        onClick={acceptInput ? handleAddPromoCode : () => setAcceptInput(true)}
        color={acceptInput ? "blue" : "gray"}>
        {acceptInput ? "Submit" : "Edit"}
      </Button>
    </Flex>
  );
}

export default function CodeAndLink({
  taskId,
  platform,
  displayName,
  redirectUrl,
  promoCode,
  referralLinkStatus,
  promoCodeStatus,
  handleCompleteTask,
}: {
  taskId: string;
  platform: SupportedPlatform;
  displayName: string;
  redirectUrl: string;
  promoCode: string;
  referralLinkStatus: TaskStatus;
  promoCodeStatus: TaskStatus;
  handleCompleteTask: () => void;
}) {
  const handleCompleteTaskAndNotify = () => {
    const referralLinkRequired = referralLinkStatus !== TaskStatus.NONE;
    const promoCodeRequired = promoCodeStatus !== TaskStatus.NONE;

    let title = "";
    let messageSubText = "";

    if (referralLinkRequired && promoCodeRequired) {
      title = "Referral Link and Promo Code provided";
      messageSubText = "your Referral Link and Promo Code";
    } else if (referralLinkRequired) {
      title = "Referral Link provided";
      messageSubText = "your Referral Link";
    } else if (promoCodeRequired) {
      title = "Promo Code provided";
      messageSubText = "your Promo Code";
    }

    handleCompleteTask();
    showSuccessNotification({
      title,
      message: `${displayName} has been notified to use ${messageSubText} when their content goes live.`,
    });
  };

  return (
    <Stack gap="xs">
      <ReferralLinkInput
        taskId={taskId}
        platform={platform}
        redirectUrl={redirectUrl}
        status={referralLinkStatus}
        handleCompleteTask={handleCompleteTaskAndNotify}
      />
      <PromoCodeInput
        taskId={taskId}
        platform={platform}
        promoCode={promoCode}
        status={promoCodeStatus}
        handleCompleteTask={handleCompleteTaskAndNotify}
      />
    </Stack>
  );
}
