import React from "react";

import { Badge, Group, Text } from "@mantine/core";

import { IconBrandInstagram, IconBrandTiktok, IconBrandYoutube } from "@tabler/icons-react";

import { SupportedFormat, SupportedPlatform } from "models/Common";
import { SupportedFormatToLabel, TaskStatus } from "components/contracts/tasks/models/Common";
import { TaskStage } from "components/contracts/tasks/models/Common";

import ActionBadge from "components/contracts/tasks/common/ActionBadge";
import DueDateBadge from "components/contracts/tasks/common/DueDateBadge";

function UnreadMessagesBadge({ numUnreadMessages }: { numUnreadMessages: number }) {
  return numUnreadMessages && numUnreadMessages > 0 ? (
    <Badge variant="dot" color="red">
      {numUnreadMessages}
    </Badge>
  ) : null;
}

function BadgeGroup({
  numUnreadMessages,
  taskType,
  waitingForCreator,
  referralLinkStatus,
  promoCodeStatus,
  deadline,
  showAsCompleted,
}: {
  numUnreadMessages?: number;
  taskType: TaskStage;
  waitingForCreator?: boolean;
  referralLinkStatus: TaskStatus;
  promoCodeStatus: TaskStatus;
  deadline: Date;
  showAsCompleted?: boolean;
}) {
  if (showAsCompleted) {
    return (
      <Badge variant="light" color="teal">
        Completed
      </Badge>
    );
  }

  return (
    <Group gap="xs">
      <UnreadMessagesBadge numUnreadMessages={numUnreadMessages} />
      <ActionBadge
        taskType={taskType}
        waitingForCreator={waitingForCreator}
        referralLinkStatus={referralLinkStatus}
        promoCodeStatus={promoCodeStatus}
      />
      {!waitingForCreator && <DueDateBadge deadline={deadline} />}
    </Group>
  );
}

export default function TaskCardHeader({
  deliverableFormat,
  platform,
  deadline,
  taskType,
  referralLinkStatus,
  promoCodeStatus,
  numUnreadMessages,
  waitingForCreator,
  showAsCompleted,
}: {
  deliverableFormat: SupportedFormat;
  platform: SupportedPlatform;
  deadline: Date;
  taskType: TaskStage;
  referralLinkStatus: TaskStatus;
  promoCodeStatus: TaskStatus;
  numUnreadMessages?: number;
  waitingForCreator?: boolean;
  showAsCompleted?: boolean;
}) {
  if (taskType === TaskStage.PRODUCT_ACCESS) {
    return (
      <Group justify="space-between">
        <Text fw="600" size="lg">
          Product Access
        </Text>
        <BadgeGroup
          numUnreadMessages={numUnreadMessages}
          taskType={taskType}
          waitingForCreator={waitingForCreator}
          referralLinkStatus={referralLinkStatus}
          promoCodeStatus={promoCodeStatus}
          deadline={deadline}
          showAsCompleted={showAsCompleted}
        />
      </Group>
    );
  }

  let icon = null;
  if (platform === SupportedPlatform.INSTAGRAM) {
    icon = <IconBrandInstagram />;
  } else if (platform === SupportedPlatform.TIKTOK) {
    icon = <IconBrandTiktok />;
  } else if (platform === SupportedPlatform.YOUTUBE) {
    icon = <IconBrandYoutube />;
  }

  const label = SupportedFormatToLabel[deliverableFormat];

  return (
    <Group justify="space-between">
      <Group gap="xs">
        {icon}
        <Text fw="600" size="lg">
          {label}
        </Text>
      </Group>
      <BadgeGroup
        numUnreadMessages={numUnreadMessages}
        taskType={taskType}
        waitingForCreator={waitingForCreator}
        referralLinkStatus={referralLinkStatus}
        promoCodeStatus={promoCodeStatus}
        deadline={deadline}
        showAsCompleted={showAsCompleted}
      />
    </Group>
  );
}
