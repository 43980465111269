import React from "react";

import { Badge } from "@mantine/core";

import { toShortDateString } from "utils/DateUtils";

export default function DueDateBadge({ deadline }: { deadline: Date }) {
  if (!deadline) {
    return null;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const deadlineDate = new Date(deadline);
  deadlineDate.setHours(0, 0, 0, 0);

  const dueDate = toShortDateString(deadline);

  if (deadlineDate.getTime() === today.getTime()) {
    return (
      <Badge color="red" variant="outline">
        Due Today {dueDate}
      </Badge>
    );
  } else if (deadlineDate.getTime() === tomorrow.getTime()) {
    return (
      <Badge color="orange" variant="outline">
        Due Tomorrow {dueDate}
      </Badge>
    );
  } else if (deadlineDate > today) {
    return (
      <Badge color="yellow" variant="outline">
        Due By {dueDate}
      </Badge>
    );
  }

  return (
    <Badge color="red" variant="outline">
      Overdue {dueDate}
    </Badge>
  );
}
