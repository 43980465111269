import { Container, Paper, SegmentedControl, Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";

import { getOpsTasks, getOpsSourcingDailyTasks } from "admin/api/opsApi";
import { useAdminAppContext } from "admin/app/AdminAppShell";
import { OpsDailyTasks } from "admin/app/ops/tasks/OpsDailyTasks";
import { OpsTaskHome } from "admin/app/ops/OpsTaskHome";
import { OpsUserTable } from "admin/app/ops/OpsUserTable";
import { OpsCreatorSetPlanningMatrix } from "admin/app/ops/tasks/planning/OpsCreatorSetPlanningMatrix";
import { useSearchParams } from "react-router-dom";

enum NavItem {
  TEAM = "team",
  TASKS = "tasks",
  PLANNING = "planning",
  SOURCING = "sourcing",
}

export const OpsAdminHome = () => {
  const { user, opsUsers } = useAdminAppContext();

  // search params
  const [navItem, setNavItem] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // parse search params
  useEffect(() => {
    // First check if there are any changes to the searchParams
    const searchNavItem = searchParams.get("navItem");
    if (searchNavItem !== null) {
      if (searchNavItem !== navItem) {
        setNavItem(searchNavItem);
      }
    } else if (searchNavItem === null || searchNavItem === "") {
      // Fallback to team
      setNavItem(NavItem.TEAM.valueOf());
    }
  }, [searchParams]);

  // set search params based on nav item
  useEffect(() => {
    const currSearchParamNavItem = searchParams.get("navItem");
    let changed = false;
    // update the searchParams
    if (
      navItem === NavItem.TEAM.valueOf() &&
      // ok to be null
      (currSearchParamNavItem !== null || currSearchParamNavItem !== NavItem.TEAM.valueOf())
    ) {
      searchParams.delete("navItem");
      changed = true;
    } else if (
      navItem === NavItem.TASKS.valueOf() &&
      currSearchParamNavItem !== NavItem.TASKS.valueOf()
    ) {
      searchParams.set("navItem", NavItem.TASKS.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.PLANNING.valueOf() &&
      currSearchParamNavItem !== NavItem.PLANNING.valueOf()
    ) {
      searchParams.set("navItem", NavItem.PLANNING.valueOf());
      changed = true;
    } else if (
      navItem === NavItem.SOURCING.valueOf() &&
      currSearchParamNavItem !== NavItem.SOURCING.valueOf()
    ) {
      searchParams.set("navItem", NavItem.SOURCING.valueOf());
      changed = true;
    }
    if (changed) {
      setSearchParams(searchParams);
    }
  }, [navItem]);

  // Fetch ops tasks
  const [opsTasks, setOpsTasks] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    if (user != null) {
      getOpsTasks(user, abortController).then((taskResponse) => {
        if (taskResponse?.results && taskResponse?.results?.length > 0) {
          setOpsTasks(taskResponse.results);
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [user]);

  // Fetch daily tasks
  const [dailyTasks, setDailyTasks] = useState([]);
  const [priority, setPriority] = useState<Record<number, number>>({});
  useEffect(() => {
    const abortController = new AbortController();
    if (user != null) {
      getOpsSourcingDailyTasks(abortController).then((taskResponse) => {
        if (taskResponse?.results && taskResponse?.results?.length > 0) {
          setDailyTasks(taskResponse.results);
          setPriority(taskResponse.priority);
          console.log(taskResponse);
        }
      });
    }
    return () => {
      abortController.abort();
    };
  }, [user]);

  return (
    <Container fluid>
      <Stack gap="md">
        <Paper p="md">
          <SegmentedControl
            fullWidth
            color="blue"
            value={navItem}
            onChange={(value) => setNavItem(value)}
            data={[
              { label: "Team", value: NavItem.TEAM.valueOf() },
              { label: "Tasks", value: NavItem.TASKS.valueOf() },
              { label: "Planning", value: NavItem.PLANNING.valueOf() },
              { label: "Daily Sourcing Tasks (Beta)", value: NavItem.SOURCING.valueOf() },
            ]}
          />
        </Paper>
        {navItem === NavItem.TEAM.toString() && (
          <Paper p="md">
            <OpsUserTable opsUsers={opsUsers} opsTasks={opsTasks} />
          </Paper>
        )}
        {navItem === NavItem.TASKS.toString() && (
          <OpsTaskHome
            opsUsers={opsUsers}
            opsTasks={opsTasks}
            setOpsTasks={setOpsTasks}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        )}
        {navItem === NavItem.PLANNING.toString() && (
          <OpsCreatorSetPlanningMatrix setTasks={setOpsTasks} />
        )}
        {navItem === NavItem.SOURCING.toString() && (
          <OpsDailyTasks opsUsers={opsUsers} sourcingAllocations={dailyTasks} priority={priority} />
        )}
      </Stack>
    </Container>
  );
};

export default OpsAdminHome;
