import React, { useEffect, useState } from "react";
import { Drawer, Flex } from "@mantine/core";

import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import { CreatorDetails } from "components/discovery/Datamodels";
import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";

const CreatorDrawer = ({
  creatorId,
  opened,
  close,
}: {
  creatorId: string;
  opened: boolean;
  close: () => void;
}) => {
  const creatorIdInt = Number(creatorId);
  const [creatorDetails, setCreatorDetails] = useState<Record<number, CreatorDetails>>({});

  useEffect(() => {
    const abortController = new AbortController();
    fetchCreatorSetDetails([creatorIdInt], creatorDetails, setCreatorDetails, abortController);
    return () => abortController.abort();
  }, [creatorId]);

  if (!creatorDetails[creatorIdInt]) {
    return null;
  }

  return (
    <Drawer
      offset={8}
      size="xl"
      position="right"
      radius="md"
      opened={opened}
      onClose={close}
      withCloseButton={false}
      closeOnClickOutside>
      <Flex justify="center" p="lg">
        <UnifiedCreatorRep
          creator={creatorDetails[creatorIdInt]}
          defaultExpandedPlatforms={["youtube", "tiktok", "instagram"]}
          useScrollOverflow={false}
          refetchThumbnails
        />
      </Flex>
    </Drawer>
  );
};

export default CreatorDrawer;
