import React, { useEffect, useState } from "react";

import { Button, Group, LoadingOverlay, Paper, Stack, Text, Title } from "@mantine/core";

import { IconRefresh } from "@tabler/icons-react";

import {
  getTotalForecastedRevenueAndGmv,
  refreshGMVAndRevenueMetrics,
} from "components/metrics/Api";

import { timeAgo } from "utils/DateUtils";

import SpendForecast, {
  CurrentMonthForecastTimeSeriesItem,
  NextMonthForecastTimeSeriesItem,
} from "components/metrics/SpendForecast";

export default function TotalGMVAndRevenueForecast() {
  const [loading, setLoading] = useState(false);
  const [currentMonthGMVForecastData, setCurrentMonthGMVForecastData] = useState<
    CurrentMonthForecastTimeSeriesItem[]
  >([]);
  const [nextMonthGMVForecastData, setNextMonthGMVForecastData] = useState<
    NextMonthForecastTimeSeriesItem[]
  >([]);
  const [currentMonthRevenueForecastData, setCurrentMonthRevenueForecastData] = useState<
    CurrentMonthForecastTimeSeriesItem[]
  >([]);
  const [nextMonthRevenueForecastData, setNextMonthRevenueForecastData] = useState<
    NextMonthForecastTimeSeriesItem[]
  >([]);

  const [metricsLastUpdated, setMetricsLastUpdated] = useState<Date>(null);
  const [, setUpdateTrigger] = useState(0);

  const fetchMetrics = (abortController?: AbortController) => {
    setLoading(true);
    getTotalForecastedRevenueAndGmv(abortController)
      .then((response) => {
        if (!response) {
          return;
        }
        const {
          success,
          currentMonthGmvForecast,
          nextMonthGmvForecast,
          currentMonthRevenueForecast,
          nextMonthRevenueForecast,
          lastUpdated,
        } = response;

        if (success) {
          setCurrentMonthGMVForecastData(JSON.parse(currentMonthGmvForecast));
          setNextMonthGMVForecastData(JSON.parse(nextMonthGmvForecast));
          setCurrentMonthRevenueForecastData(JSON.parse(currentMonthRevenueForecast));
          setNextMonthRevenueForecastData(JSON.parse(nextMonthRevenueForecast));
          setMetricsLastUpdated(new Date(lastUpdated));
        }
      })
      .finally(() => setLoading(false));
  };

  const refreshMetrics = (abortController?: AbortController) => {
    setLoading(true);
    refreshGMVAndRevenueMetrics(abortController).finally(() => fetchMetrics(abortController));
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchMetrics(abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setUpdateTrigger((prev) => prev + 1);
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Paper p="xl" radius="md">
      <LoadingOverlay visible={loading} />
      <Stack gap="xs">
        <Group justify="space-between">
          <Stack gap={2} px="xl">
            <Title order={2} fw="500">
              Forecasted Spend
            </Title>
            {metricsLastUpdated && (
              <Text size="sm" c="dimmed">
                Last updated {timeAgo(metricsLastUpdated)}
              </Text>
            )}
          </Stack>
          <Button
            leftSection={<IconRefresh size="1rem" />}
            size="xs"
            onClick={() => refreshMetrics()}>
            Refresh
          </Button>
        </Group>
        <SpendForecast
          title="GMV Forecast"
          currentMonthForecast={currentMonthGMVForecastData}
          nextMonthForecast={nextMonthGMVForecastData}
          showTotal
        />
        <SpendForecast
          title="Revenue Forecast"
          currentMonthForecast={currentMonthRevenueForecastData}
          nextMonthForecast={nextMonthRevenueForecastData}
          showTotal
        />
      </Stack>
    </Paper>
  );
}
